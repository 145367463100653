import { Button, Form, Input, notification, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SingleColumnFormLayout } from 'lib/antFormLayouts';
import { useErrorHandler } from 'lib/ErrorHandling';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { useTable } from 'lib/hooks/useTable';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { DeletePopconfirm } from 'shared/AntDesignUtils/DeletePopconfirm';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
import { ApiToken } from 'types/models';

const { Paragraph, Text, Title } = Typography;
const { saveButtonLayout } = SingleColumnFormLayout;

type CreatedToken = {
    accessToken: ApiToken;
    plainTextToken: string;
};

export const ApiTokens = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const { tableParams, handleTableChange, setTotal } = useTable();

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isDataCreating, setIsDataCreating] = useState(false);
    const [isDataDeleting, setIsDataDeleting] = useState(false);
    const [apiTokens, setApiTokens] = useState([]);
    const [createdToken, setCreatedToken] = useState<CreatedToken>();
    const [form] = Form.useForm();

    const loadApiTokens = useCallback(async () => {
        try {
            setIsDataLoading(true);
            const response = await Backend.get(`/account/tokens`);
            if (response.status === Backend.responseStatus.HTTP_OK) {
                setApiTokens(response.data.tokens);
                setIsDataLoading(false);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataLoading(false);
        }
    }, [handleError]);

    useEffect(() => {
        loadApiTokens();
    }, [loadApiTokens]);

    /**
     * Create a new token.
     *
     * @param values
     */
    const createTokenHandler = async (values: { token_name: string; }) => {
        try {
            setIsDataCreating(true);
            const response = await Backend.post(`/account/tokens/`, values);
            if (response.status === Backend.responseStatus.HTTP_CREATED) {
                setCreatedToken(response.data.token);
                notification.success({
                    message: t('Token successfully created'),
                });
                form.setFieldsValue({ token_name: '' });
                loadApiTokens();
            }
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataCreating(false);
        }
    };

    /**
     * Delete a token by id.
     *
     * @param id {int}
     * @return {Promise<void>}
     */
    const deleteTokenHandler = async (id: number) => {
        try {
            setIsDataDeleting(true);
            const response = await Backend.delete(`/account/tokens/${ id }`);
            if (response.status === Backend.responseStatus.HTTP_NO_CONTENT) {
                notification.success({
                    message: t('Token successfully deleted'),
                });
                loadApiTokens();
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataDeleting(false);
        }
    };

    /**
     * Render a cell with action buttons.
     *
     * @param apiToken {object}
     * @return {*}
     */
    const renderActionCell = (apiToken: ApiToken) => (
        <DeletePopconfirm
            title={ t('Are you sure you want to delete this token?') }
            id={ apiToken.id }
            isDataDeleting={ isDataDeleting }
            deleteHandler={ deleteTokenHandler }
        />
    );

    useEffect(() => {
        setTotal(apiTokens.length);
    }, [apiTokens]);

    const columns: ColumnsType<ApiToken> = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => sortColumn(a, b, 'name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('name'),
        },
        {
            title: t('Created at'),
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => sortColumn(a, b, 'created_at'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('created_at'),
            render: (value) => moment(value).format('DD.MM.YYYY'),
        },
        {
            title: t('Last used at'),
            dataIndex: 'last_used_at',
            key: 'last_used_at',
            sorter: (a, b) => sortColumn(a, b, 'last_used_at'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('last_used_at'),
            render: (value) => (value ? moment(value).format('DD.MM.YYYY') : null),
        },
        {
            title: t('Actions'),
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => renderActionCell(record),
        },
    ];

    return (
        <Space direction="vertical" size="large">
            <Table
                rowKey="id"
                columns={ columns }
                dataSource={ apiTokens }
                pagination={ tableParams.pagination }
                loading={ isDataLoading }
                onChange={ handleTableChange }
                size="small"
            />
            <div>
                <Title level={ 5 }>
                    <Text strong>{ t('New API token') }</Text>
                </Title>
                <Form layout="horizontal" form={ form } onFinish={ createTokenHandler }>
                    <Form.Item
                        name="token_name"
                        rules={ [
                            {
                                required: true,
                                message: t('Please insert the token name')
                            },
                            {
                                max: 150,
                                message: t('The token name may not be greater than 150 characters')
                            }
                        ] }
                    >
                        <FloatingLabel label={ t('Token name') } required>
                            <Input placeholder={ t('Insert token name') } />
                        </FloatingLabel>
                    </Form.Item>
                    <Form.Item { ...saveButtonLayout }>
                        <Button type="primary" htmlType="submit" loading={ isDataCreating }>
                            { t('Create token') }
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            { createdToken ? (
                <div>
                    <Paragraph>
                        { t('Here is the token') +
                            ` "${ createdToken.accessToken.name }". ` +
                            t('Please copy it to a safe place as this is the last time it will be shown.') }
                    </Paragraph>
                    <Paragraph copyable>{ createdToken.plainTextToken }</Paragraph>
                </div>
            ) : null }
        </Space>
    );
};
