import { useLocalization } from 'lib/Localization';
import { Modal } from 'antd';
import { Order, OrderFormRequest } from 'pages/Client/Requests/Create/RequestCreation';
import { RequestCreationInfoSummary } from 'pages/Client/Requests/Create/RequestCreationInfoSummary';
import { Profession } from 'types/models';
import './styles.scss';

type Props = {
    activateRequest: (index: number) => void;
    closeHandler: () => void;
    disabled?: boolean;
    open: boolean;
    order: Order;
    professions: Profession[];
};

export const OverviewModal = ({ activateRequest, closeHandler, disabled, open = false, order, professions }: Props) => {
    const { t } = useLocalization();

    const getDateTimes = ({ dates, differentTimes }: OrderFormRequest, index = 0) => {
        if (!(dates || []).length) {
            return [];
        }

        let key = 0;

        if (differentTimes) {
            key = dates?.[index] ? index : 0;
        }

        return dates?.[key].times;
    };

    const calculateTotalWorkersForAllProfessions = () => {
        let totalWorkersForAllProfessions = 0;

        order.requests.forEach((request) => {
            (request.datePicker ?? []).forEach((datePicker: Date, index: number) => {
                const dateTimes = getDateTimes(request, index) || [];

                dateTimes.forEach(({ workersNumber }: any) => {
                    totalWorkersForAllProfessions += parseInt(workersNumber);
                });
            });
        });
        return (
            <span>
                <span style={{ padding: '0px 2px' }}>|</span> {totalWorkersForAllProfessions}{' '}
                {totalWorkersForAllProfessions > 1 ? t('workers') : t('worker')}
            </span>
        );
    };

    return (
        <Modal
            footer={null}
            className="request-overview-modal"
            onCancel={closeHandler}
            open={open}
            width="60vw"
            centered
        >
            <div id="header">
                <span>{t('Order')}</span>:{' '}
                <span id="order-name">
                    {order.name} {calculateTotalWorkersForAllProfessions()}
                </span>
            </div>

            <RequestCreationInfoSummary
                activateRequest={activateRequest}
                disabled={disabled}
                formRequests={order.requests}
                professions={professions}
            />
        </Modal>
    );
};
