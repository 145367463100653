import { useLocalization } from 'lib/Localization';
import 'shared/OneOrMore/OneOrMore.scss';

type Props = {
    blockMore?: boolean;
    items: string[];
    joiner?: string;
    max?: number;
    onMoreClick?: () => void;
};
export const OneOrMore = ({ blockMore, items, joiner = ', ', max = 1, onMoreClick }: Props) => {
    const { t } = useLocalization();
    const itemsString = items.filter((_, index) => index < max).join(joiner);

    if (items.length <= max) {
        return <>{ itemsString }</>;
    }

    return (
        <>
            { itemsString }
            &nbsp;
            <span
                className={ `more ${ blockMore ? 'block' : '' } ${ onMoreClick ? 'cursor-pointer' : '' }` }
                onClick={ onMoreClick }
            >
                { t('+%{count} more', { count: items.length - max }) }
            </span>
        </>
    );
};
