import { useErrorHandler } from 'lib/ErrorHandling';
import { localizeProfessions } from 'lib/Helpers/ProfessionHelper';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { useCallback, useMemo, useState } from 'react';
import { Profession } from 'types/models';

export const useProfessions = () => {
    const handleError = useErrorHandler();
    const { locale } = useLocalization();
    const [professions, setProfessions] = useState<Profession[]>([]);
    const [loadingProfessions, setLoadingProfessions] = useState(false);

    const loadProfessions = useCallback(async () => {
        setLoadingProfessions(true);

        try {
            const response = await Backend.get('/data-management/professions?all');

            setProfessions(response.data.professions);

            return response.data.professions;
        } catch (error) {
            handleError(error);
        } finally {
            setLoadingProfessions(false);
        }

        return [];
    }, [handleError]);

    const translatedProfessions = useMemo(() => localizeProfessions(professions, locale), [professions]);

    return {
        loadingProfessions,
        loadProfessions,
        professions,
        setProfessions,
        translatedProfessions
    };
};
