import 'pages/Provider/Workers/Workers.scss';

import { CloseCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Drawer, Spin } from 'antd';
import { useAccount } from 'lib/Account';
import { downloadExcel } from 'lib/Helpers/ExcelHelper';
import { localizeProfessions } from 'lib/Helpers/ProfessionHelper';
import { useProfessions } from 'lib/hooks/useProfessions';
import { useWorkers } from 'lib/hooks/useWorkers';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { WorkerDetails } from 'pages/Provider/Workers/WorkerDetails';

import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Profession, Worker } from 'types/models';
import { ActionNeededModal } from './Modals/ActionNeededModal';
import { ImportExcelModal, ValidationErrorCallbackProps } from './Modals/ImportExcelModal';
import { WorkerForm } from './WorkerForm';
import { WorkersTable } from './WorkersTable';

const uploadExcelTemplateUrl = '/data-management/workers/uploadExcelTemplate';
const workersUrl = '/data-management/workers';
const workersUpdateUrl = (id: number) => `/data-management/workers/${ id }`;

export const Workers = () => {
    const { locale, t } = useLocalization();
    const { accountUser: user } = useAccount();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [importingInProgress, setImportingInProgress] = useState(false);
    const [openActionModal, setOpenActionModal] = useState(true);
    const [actionModalProps, setActionModalProps] = useState<ValidationErrorCallbackProps | {}>({});
    const providerId = user.profiles.agent?.provider_id as number;
    const [worker, setWorker] = useState<Worker>({} as Worker);
    const [isEditing, setIsEditing] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    const { loadingWorkers, loadWorkers, setWorkers, workers } = useWorkers();
    const { loadingProfessions, loadProfessions, translatedProfessions } = useProfessions();

    const workersWithTranslatedProfessions = useMemo(
        () =>
            workers.map((worker) => ({
                ...worker,
                professions: localizeProfessions((worker as any).professions, locale),
            })),
        [workers]
    );

    const drawerTitle = useMemo(() => {
        if (isEditing) {
            return worker.id ? t('Edit worker') : t('Add Worker');
        }

        return t('Worker details');
    }, [isEditing, worker]);

    const openWorkerHandler = (worker?: Worker) => {
        setWorker(worker ?? ({ provider_id: providerId } as Worker));
        setIsEditing(true);
        setOpenDrawer(true);
    };

    const verifyDataButton = (
        <a
            className="underline has-validation-error"
            href="#"
            onClick={ (e) => {
                e.preventDefault();
                setOpen(false);
                setOpenActionModal(true);
            } }
        >
            { t('Verify data') }
        </a>
    );

    const importingIndicator = (importingInProgress || hasValidationError) && (
        <p className={ `excel-spinner ${ hasValidationError ? 'has-validation-error' : '' }` }>
            { hasValidationError ? (
                <CloseCircleOutlined
                    onClick={ () => {
                        setActionModalProps({});
                        setHasValidationError(false);
                    } }
                />
            ) : (
                <Spin indicator={ <LoadingOutlined spin /> } />
            ) }
            { hasValidationError ? t('Some data imported unsuccessfully.') : t('Excel importing in progress') }
            { hasValidationError && verifyDataButton }
        </p>
    );

    const headerButtons = [
        importingIndicator,
        <Button key="1" onClick={ () => setOpen(true) }>
            { t('Import Excel') }
        </Button>,
        <Button key="2" type="primary" icon={ <PlusOutlined /> } onClick={ () => openWorkerHandler() }>
            { t('Add Worker') }
        </Button>,
    ];

    const workerSavedCallback = (worker: Worker, reset: boolean = false) => {
        if (reset) {
            setWorker({ provider_id: worker.provider_id } as Worker);
        } else {
            setWorker(worker);
        }

        setWorkers((prevState) => {
            const state = [...prevState];

            const index = state.findIndex(({ id }) => id === worker.id);

            if (index > -1) {
                state.splice(index, 1, worker);
            } else {
                state.unshift(worker);
            }

            return state;
        });
    };

    const removeWorker = (worker: Worker) =>
        setWorkers((prevState) => {
            const state = [...prevState];
            const index = state.findIndex(({ id }) => id === worker.id);

            if (index > -1) {
                state.splice(index, 1);
            }

            return state;
        });

    const handleProfessionChange = async (worker: Worker, selectedProfessions: Profession[]) => {
        if (!worker?.id) {
            return;
        }

        await Backend.post(workersUpdateUrl(worker.id), {
            ...worker,
            professions: selectedProfessions.map((profession) => profession.id),
        });
    };

    const onTemplateValidationError = ({ parsedWorkers, templateErrors }: ValidationErrorCallbackProps) => {
        setOpenActionModal(true);
        setHasValidationError(true);
        setActionModalProps({
            parsedWorkers,
            templateErrors,
        });
        setOpen(false);
    };

    const handleDownloadExcelTemplate = () =>
        downloadExcel(
            `${ t('Workers') }_Tempcloud.xlsx`,
            [
                {
                    [t('First name')]: '',
                    [t('Last name')]: '',
                    [t('Email address')]: '',
                    [t('Phone number')]: '',
                    [t('External ID')]: '',
                    [t('Shirt size')]: '',
                    [t('Trousers size')]: '',
                },
            ],
            [
                { wch: 20 }, // first column width
                { wch: 20 }, // so on...
                { wch: 20 },
                { wch: 20 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
            ]
        );

    const onImportSuccess = () => {
        setActionModalProps({});
        setHasValidationError(false);
        setOpen(false);

        loadWorkers();
    };

    const showWorkerDetails = (worker: Worker) => {
        setWorker(worker);
        setIsEditing(false);
        setOpenDrawer(true);
    };

    useEffect(() => {
        loadWorkers();
        loadProfessions();
    }, []);

    return (
        <>
            <PageHeader
                backIcon={ false }
                className="provider-workers"
                extra={ headerButtons }
                ghost={ false }
                onBack={ () => navigate(-1) }
                title={ t('Workers') }
            >
                <WorkersTable
                    handleProfessionChange={ handleProfessionChange }
                    workersUrl={ workersUrl }
                    workers={ workersWithTranslatedProfessions }
                    openHandler={ openWorkerHandler }
                    isDataLoading={ loadingWorkers || loadingProfessions }
                    removeWorker={ removeWorker }
                    showDetails={ showWorkerDetails }
                    translatedProfessions={ translatedProfessions }
                />

                <Drawer onClose={ () => setOpenDrawer(false) } open={ openDrawer } title={ drawerTitle } width={ 500 }>
                    { isEditing && (
                        <WorkerForm
                            open={ openDrawer }
                            worker={ worker }
                            workerSavedCallback={ workerSavedCallback }
                            setIsFormOpen={ setOpenDrawer }
                            translatedProfessions={ translatedProfessions }
                        />
                    ) }

                    { !isEditing && <WorkerDetails
                        onEdit={ () => setIsEditing(true) }
                        worker={ worker }
                    /> }
                </Drawer>

                <ActionNeededModal
                    className="provider-workers"
                    uploadUrl={ uploadExcelTemplateUrl }
                    open={ openActionModal }
                    isLoading={ false }
                    onClose={ () => {
                        setOpen(false);
                        setOpenActionModal(false);
                    } }
                    onSuccess={ onImportSuccess }
                    onUploadAgain={ () => {
                        setOpenActionModal(false);
                        setHasValidationError(false);
                        setOpen(true);
                    } }
                    { ...actionModalProps }
                />

                <ImportExcelModal
                    open={ open }
                    isLoading={ importingInProgress }
                    okButtonText={ t('Import') }
                    onStart={ () => {
                        setActionModalProps({});
                        setImportingInProgress(true);
                    } }
                    onCancel={ () => {
                        setOpen(false);
                        setOpenActionModal(false);
                    } }
                    onSuccess={ onImportSuccess }
                    onEnd={ () => setImportingInProgress(false) }
                    downloadExcelTemplate={ handleDownloadExcelTemplate }
                    onValidationError={ onTemplateValidationError }
                    uploadUrl={ uploadExcelTemplateUrl }
                />
            </PageHeader>
        </>
    );
};
