import { useEffect, useRef, useState } from 'react';
import { TimePicker } from 'shared/AntDesignUtils/TimePicker';
import { useLocalization } from 'lib/Localization';
import { getTimeDuration, getTimeOptionsForTimePicker } from 'lib/Helpers/DateTimeHelper';
import { GenericObject } from 'shared/Contracts';

type Times = (string | undefined)[];

export type TimeRangePickerInputObject = { times: Times; touched: boolean };

type Props = GenericObject & {
    disabled?: boolean;
    handleShiftDurationChange?: (duration: string) => void;
    hideDuration?: boolean;
    minutesStep?: number;
    value?: TimeRangePickerInputObject;
    onChange?: (obj: TimeRangePickerInputObject) => void;
    width?: number;
};

export const TimeRangePicker = ({
    disabled,
    handleShiftDurationChange,
    hideDuration = false,
    minutesStep = 15,
    onChange,
    width = 160,
    value,
    ...props
}: Props) => {
    const { t } = useLocalization();
    const { className, ...otherProps } = props;
    const endTimeRef = useRef<any>();
    const [timesValue, setTimesValue] = useState<Times>([undefined, undefined]);
    const [startTime, endTime] = timesValue;
    const [endTimeDropdownOpen, setEndTimeDropdownOpen] = useState(false);
    const [shiftDuration, setShiftDuration] = useState('');
    const [touched, setTouched] = useState(false);
    const [startTimeTouched, setStartTimeTouched] = useState(false);
    const [endTimeTouched, setEndTimeTouched] = useState(false);

    useEffect(() => {
        if (value?.times) {
            setTimesValue(value.times);
            setTouched(value?.touched);
        }
    }, [value?.times]);

    useEffect(() => {
        const newDuration = getTimeDuration(startTime, endTime);

        setShiftDuration(newDuration);
        handleShiftDurationChange?.(newDuration);
    }, [timesValue]);

    const handleStartTimeChange = (newStartTime: string) => {
        if (disabled) {
            return;
        }

        const newTimes = [newStartTime, endTime];
        const regexTime = new RegExp('^(([0-9]|(0[0-9]|1[0-9]|2[0-3]))|)(:(([0-5]|)([0-9]|))|)$');

        if (regexTime.test(newStartTime)) {
            setTimesValue(newTimes);
            onChange?.({ times: newTimes, touched: touched || endTimeTouched });
        }
    };

    const handleEndTimeChange = (newEndTime: string) => {
        if (disabled) {
            return;
        }

        const newTimes = [startTime, newEndTime];
        const regexTime = new RegExp('^(([0-9]|(0[0-9]|1[0-9]|2[0-3]))|)(:(([0-5]|)([0-9]|))|)$');

        if (regexTime.test(newEndTime)) {
            setTimesValue(newTimes);
            onChange?.({ times: newTimes, touched: touched || startTimeTouched });
        }
    };

    return (
        <div className={`${className} no-wrap`}>
            <TimePicker
                {...otherProps}
                disabled={disabled}
                placeholder={t('Start time')}
                value={startTime}
                onFocus={() => {
                    setStartTimeTouched(true);
                    setTouched(touched || endTimeTouched);
                }}
                onChange={handleStartTimeChange}
                onSelect={() => endTimeRef.current?.focus()}
                style={{ width }}
                showIcon={false}
            />
            {' – '}
            <TimePicker
                {...otherProps}
                disabled={disabled}
                placeholder={t('End time')}
                value={endTime}
                onChange={handleEndTimeChange}
                ref={endTimeRef}
                onFocus={() => {
                    setEndTimeDropdownOpen(true);
                    setEndTimeTouched(true);
                    setTouched(touched || startTimeTouched);
                }}
                onBlur={() => setEndTimeDropdownOpen(false)}
                onClick={() => {
                    if (!endTimeDropdownOpen) setEndTimeDropdownOpen(true);
                }}
                onSelect={() => setEndTimeDropdownOpen(false)}
                open={endTimeDropdownOpen}
                dropdownOptions={getTimeOptionsForTimePicker(minutesStep, startTime)}
                style={{ width }}
                showIcon={false}
            />
            {!hideDuration && <span className="subsection-subtitle shift-duration-span">{shiftDuration}</span>}
        </div>
    );
};
