import { TablePaginationConfig } from 'antd';
import { useState } from 'react';

export const usePagination = (defaultPageSize: number = 20) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);

    const pageSizeOptions = ['20', '50', '100'];

    if (!pageSizeOptions.includes(String(defaultPageSize))) {
        const index = pageSizeOptions.findIndex((size) => Number(size) > defaultPageSize);

        pageSizeOptions.splice(index, 0, String(defaultPageSize));
    }

    return (total: number): TablePaginationConfig => ({
        current: currentPage,
        defaultPageSize,
        hideOnSinglePage: true,
        pageSize,
        pageSizeOptions,
        onChange: (page: number, size: number) => {
            setCurrentPage(page);
            setPageSize(size);
        },
        showSizeChanger: true,
        total
    });
};
