import { Button, FormInstance, Upload, UploadProps } from 'antd';
import { useLocalization } from 'lib/Localization';
import { useState } from 'react';
import { GenericObject } from 'shared/Contracts';

type Props = GenericObject & {
    accept?: string;
    disabled?: boolean;
    form?: FormInstance;
    label?: string;
    name?: string;
    onChange?: (file?: File) => void;
    value?: any;
};

export const FileUploaderButton = ({ accept, disabled, form, label, name, onChange, value, ...otherProps }: Props) => {
    const { t } = useLocalization();

    const [hasFile, setHasFile] = useState(false);

    const handleChange: UploadProps['onChange'] = ({ file }) => {
        if (file.status === 'removed') {
            onChange?.();
            form?.setFieldValue(name || 'avatar', undefined);
            setHasFile(false);

            return;
        }

        onChange?.(file as unknown as File);
        form?.setFieldValue(name || 'avatar', file);
        setHasFile(true);
    };

    return (
        <Upload
            accept={ accept }
            beforeUpload={ () => false }
            disabled={ disabled }
            onChange={ handleChange }
            { ...otherProps }
        >
            { !hasFile && (<Button>
                { label || t('Upload file') }
            </Button>
            ) }
        </Upload>
    );
};
