import { notification } from 'antd';
import { useIsProfileSet } from 'lib/hooks';
import { useLocalization } from 'lib/Localization';
import { JSX } from 'react';
import { Navigate, useLocation } from 'react-router-dom-v5-compat';
import { Authentication, useAccount } from '.';

type Props = {
    element: JSX.Element;
    requirePermissions: string[];
}

export const PrivatePage = ({ element, requirePermissions: requiredPermissions }: Props): JSX.Element => {
    const { accountUser: user } = useAccount();
    const isProfileSet = useIsProfileSet();
    const { t } = useLocalization();
    const location = useLocation();

    // Check Authentication
    if (!user.isAuthenticated) {
        notification.warning({
            key: String(user.id),
            message: t('Please log in to access the requested page.'),
        });

        return (
            <Navigate
                to={{
                    pathname: '/login',
                    state: { referer: location.pathname },
                } as any}
            />
        );
    }

    const onboardingPath = '/onboarding';
    const welcomePath = Authentication.determineUserWelcomePath(user);

    if (!isProfileSet && ![onboardingPath, welcomePath].includes(location.pathname)) {
        return <Navigate to={welcomePath} />;
    }

    // Check Authorization
    if (requiredPermissions) {
        // check if route is restricted by role
        const validPermissions = user.security.permissions.filter((accountPermission) =>
            requiredPermissions.includes(accountPermission)
        );

        if (validPermissions.length === 0) {
            // user not authorized
            notification.warning({
                message: t('You do not have the necessary permissions to access this section.'),
            });

            return <Navigate to={Authentication.determineUserHomePath(user)} />;
        }
    }

    return element;
}
