import {
    Button,
    Form,
    Input,
    notification,
    Popconfirm,
    Select,
    Space,
    Tabs,
    TabsProps,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useErrorHandler } from 'lib/ErrorHandling';
import { antFormToFormData } from 'lib/Helpers/General';
import { Backend } from 'lib/Http/Backend';
import { t, useLocalization } from 'lib/Localization';
import { ProfessionMultiSelect } from 'pages/Provider/Workers/ProfessionMultiSelect';
import { useEffect, useMemo, useState } from 'react';
import { Avatar } from 'shared/AntDesignUtils/Avatar/Avatar';
import { Documents } from 'shared/Documents';
import { FileUploaderButton } from 'shared/FileUploader/Button';
import { Profession, Worker } from 'types/models';
import { Document } from 'types/models/Document';

const DEFAULT_TAB = 'bio';

export enum ShirtSizes {
    WOMAN_XS = 'Woman - XS',
    WOMAN_S = 'Woman - S',
    WOMAN_M = 'Woman - M',
    WOMAN_L = 'Woman - L',
    WOMAN_XL = 'Woman - XL',
    WOMAN_XXL = 'Woman - XXL',
    MAN_S = 'Man - S',
    MAN_M = 'Man - M',
    MAN_L = 'Man - L',
    MAN_XL = 'Man - XL',
    MAN_XXL = 'Man - XXL',
}

const shirtSizeOptions = Object.entries(ShirtSizes).map((value) => {
    return {
        value: value[0],
        label: value[1],
    };
});

export enum TrousersSize {
    WOMAN_34 = 'Woman - 34',
    WOMAN_36 = 'Woman - 36',
    WOMAN_38 = 'Woman - 38',
    WOMAN_40 = 'Woman - 40',
    WOMAN_42 = 'Woman - 42',
    WOMAN_44 = 'Woman - 44',
    WOMAN_46 = 'Woman - 46',
    WOMAN_48 = 'Woman - 48',
    MAN_40 = 'Man - 40',
    MAN_42 = 'Man - 42',
    MAN_44 = 'Man - 44',
    MAN_46 = 'Man - 46',
    MAN_48 = 'Man - 48',
    MAN_50 = 'Man - 50',
    MAN_52 = 'Man - 52',
    MAN_54 = 'Man - 54',
    MAN_56 = 'Man - 56',
    MAN_58 = 'Man - 58',
}

const trousersSizeOptions = Object.entries(TrousersSize).map((value) => {
    return {
        value: value[0],
        label: t(value[1]),
    };
});

type Props = {
    open?: boolean;
    setIsFormOpen: (isFormOpen: boolean) => void;
    translatedProfessions?: Profession[];
    worker: Worker;
    workerSavedCallback: (worker: Worker, reset?: boolean) => void;
};

const workersUrl = '/data-management/workers';

export const WorkerForm = ({
    open,
    setIsFormOpen,
    translatedProfessions,
    worker,
    workerSavedCallback,
}: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [form] = Form.useForm();

    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isDataSaving, setIsDataSaving] = useState(false);
    const [closeDrawerAfterSubmit, setCloseDrawerAfterSubmit] = useState(true);
    const [documents, setDocuments] = useState(worker.documents || []);
    const [activeTabKey, setActiveTabKey] = useState<string>(DEFAULT_TAB);
    const [localWorker, setLocalWorker] = useState<Worker>({ ...worker });
    const [canDownloadCV, setCanDownloadCV] = useState(true);

    const initialWorker = useMemo(() => ({
        ...localWorker,
        professions: localWorker.professions?.map(({ id }) => id) || [],
    }), [localWorker]);

    useEffect(() => {
        form.resetFields();

        if (open) {
            form.setFieldsValue(initialWorker);
        } else {
            setDocuments([]);
            setCanDownloadCV(true);
            setActiveTabKey(DEFAULT_TAB);
        }
    }, [initialWorker, form, open]);

    useEffect(() => {
        setLocalWorker({ ...worker });
    }, [worker]);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(initialWorker);
    }, []);

    const saveWorker = async (worker: Worker, isFormSubmission: boolean = true) => {
        try {
            await form.validateFields();
        } catch {
            setActiveTabKey(DEFAULT_TAB);

            return;
        }

        try {
            setIsDataSaving(true);

            const url = worker.id ? `${ workersUrl }/${ worker.id }` : workersUrl;

            const { data, status } = await Backend.post(url, antFormToFormData(form), {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const savedWorker = { ...worker, documents, ...data };

            setLocalWorker(savedWorker);

            if (!isFormSubmission) {
                return data;
            }

            const message = status === Backend.responseStatus.HTTP_CREATED
                ? t('Worker successfully created')
                : t('Worker successfully saved');

            notification.success({ message });

            setIsFormOpen(!closeDrawerAfterSubmit);
            setActiveTabKey(DEFAULT_TAB);
            workerSavedCallback(savedWorker, !closeDrawerAfterSubmit);

            if (!closeDrawerAfterSubmit) {
                setLocalWorker({ provider_id: localWorker.provider_id } as Worker);
            }

            form.resetFields();
            form.setFieldsValue(initialWorker);
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataSaving(false);
        }
    };

    /**
     * Programmatically manage the Popup confirm visibility
     * to show a warning message in case the worker has an external_id
     */
    const openChangeHandler = (open: boolean) => {
        if (!open) {
            setIsConfirmOpen(open);
            return;
        }

        if (worker.external_id) {
            setIsConfirmOpen(open);
        } else {
            form.submit();
        }
    };

    /**
     * Save worker handler
     */
    const confirmSaveHandler = () => {
        setIsConfirmOpen(false);
        form.submit();
    };

    const getWorkerId = async (_: Document[]): Promise<number | undefined> => {
        if (localWorker.id) {
            return localWorker.id;
        }

        return (await saveWorker(localWorker, false))?.id;
    };

    const removedDocument = async (_: Document, documents: Document[]) => setDocuments(documents);

    const savedDocuments = async (_: Document[], documents: Document[]) => setDocuments(documents);

    const items: TabsProps['items'] = [
        {
            key: DEFAULT_TAB,
            label: t('Bio'),
            children: (
                <>
                    <table className="label-value form">
                        <tbody>
                            <tr>
                                <td>
                                </td>
                                <td>
                                    <Form.Item name="avatar_file">
                                        <Avatar
                                            form={ form }
                                            name="avatar_file"
                                            size={ 100 }
                                            src={ localWorker.avatar }
                                        />
                                    </Form.Item>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    { t('First name') }
                                </td>
                                <td>
                                    <Form.Item
                                        name="first_name"
                                        rules={ [
                                            {
                                                required: true,
                                                message: t('Please enter the first name')
                                            },
                                            {
                                                max: 150,
                                                message: t('The first name may not be greater than 150 characters')
                                            }
                                        ] }
                                    >
                                        <Input />
                                    </Form.Item>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    { t('Last name') }
                                </td>
                                <td>
                                    <Form.Item
                                        name="last_name"
                                        rules={ [
                                            {
                                                required: true,
                                                message: t('Please enter the last name')
                                            },
                                            {
                                                max: 150,
                                                message: t('The last name may not be greater than 150 characters')
                                            }
                                        ] }
                                    >
                                        <Input />
                                    </Form.Item>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    { t('Email') }
                                </td>
                                <td>
                                    <Form.Item
                                        name="email"
                                        rules={ [
                                            {
                                                type: 'email',
                                                message: t('Please enter a valid email')
                                            }
                                        ] }
                                    >
                                        <Input />
                                    </Form.Item>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    { t('Phone number') }
                                </td>
                                <td>
                                    <Form.Item
                                        name="phone_number"
                                        rules={ [
                                            {
                                                min: 10,
                                                message: t('The phone number may not be less than 10 characters')
                                            },
                                            {
                                                max: 15,
                                                message: t('The phone number may not be more than 15 characters')
                                            }
                                        ] }>
                                        <Input />
                                    </Form.Item>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    { t('Gender') }
                                </td>
                                <td>
                                    <Form.Item name="gender">
                                        <Select
                                            options={ [
                                                {
                                                    label: t('Male'),
                                                    value: 'male'
                                                },
                                                {
                                                    label: t('Female'),
                                                    value: 'female'
                                                }
                                            ] }
                                            style={ { marginBottom: 12 } }
                                            showSearch
                                        />
                                    </Form.Item>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    { t('CV') }
                                </td>
                                <td>
                                    <Form.Item name="cv_file">
                                        <FileUploaderButton
                                            accept="pdf,doc,docx,xls,xlsx"
                                            form={ form }
                                            label={ t(localWorker.cv ? 'Change' : 'Add') }
                                            maxCount={ 1 }
                                            name="cv_file"
                                            onChange={ (file) => setCanDownloadCV(!file) }
                                            style={ { display: 'inline-block' } }
                                        />

                                        <a
                                            href={ localWorker.cv }
                                            style={ {
                                                display: localWorker.cv && canDownloadCV ? 'inline-block' : 'none',
                                                marginLeft: 10
                                            } }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            { t('Download') }
                                        </a>
                                    </Form.Item>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    { t('External id') }
                                </td>
                                <td>
                                    <Form.Item name="external_id">
                                        <Input />
                                    </Form.Item>
                                </td>
                            </tr>

                            { translatedProfessions && (
                                <tr>
                                    <td>
                                        { t('Professions') }
                                    </td>
                                    <td>
                                        <Form.Item name="professions">
                                            <ProfessionMultiSelect
                                                defaultSelections={ localWorker.professions }
                                                handleProfessionChange={ (worker: Worker, selectedProfessions: Profession[]) => {
                                                    form.setFieldsValue({
                                                        professions: selectedProfessions.map((profession) => profession.id)
                                                    });
                                                } }
                                                translatedProfessions={ translatedProfessions }
                                                worker={ localWorker }
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                            ) }

                            <tr>
                                <td>
                                    { t('Shirt size') }
                                </td>
                                <td>
                                    <Form.Item name="shirt_size">
                                        <Select options={ shirtSizeOptions } showSearch />
                                    </Form.Item>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    { t('Trousers size') }
                                </td>
                                <td>
                                    <Form.Item name="trousers_size">
                                        <Select options={ trousersSizeOptions } showSearch />
                                    </Form.Item>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div style={ { marginTop: 20, marginBottom: 10 } } className="opacity-half">
                        { t('Additional information for clients') }
                    </div>

                    <Form.Item name="additional_information">
                        <TextArea rows={ 4 } defaultValue={ worker.additional_information } />
                    </Form.Item>
                </>
            )
        },
        {
            key: 'docs',
            label: t('Other documents'),
            children: (
                <Documents
                    key={ localWorker.id }
                    btnLabel={ t('Upload') }
                    documents={ localWorker.documents }
                    getRelationId={ getWorkerId }
                    onRemoved={ removedDocument }
                    onSaved={ savedDocuments }
                    relation="workers"
                />
            )
        }
    ];

    return (
        <Form form={ form } layout="horizontal" onFinish={ (worker) => saveWorker({ ...localWorker, ...worker }) }>
            <Form.Item name="id" style={ { display: 'none' } }>
                <Input />
            </Form.Item>

            <Form.Item name="provider_id" style={ { display: 'none' } }>
                <Input value={ form.getFieldValue('provider_id') } />
            </Form.Item>

            <Tabs activeKey={ activeTabKey } items={ items } onTabClick={ async (key: string) => {
                try {
                    if (key !== DEFAULT_TAB) {
                        await form.validateFields();
                    }

                    setActiveTabKey(key);
                } catch {
                    // do nothing
                }
            } } animated />

            <hr style={ { borderTop: 'none', marginBottom: '20px' } } />

            <Form.Item>
                <Popconfirm
                    open={ isConfirmOpen }
                    onOpenChange={ openChangeHandler }
                    placement="top"
                    title={
                        <>
                            { t('This worker has an External ID, therefore it may be managed from an external service.') }
                            <br />
                            { t('Your modifications might be overwritten from the external service.') }
                            <br />
                            { t('Are you sure you want to edit it?') }
                        </>
                    }
                    onConfirm={ confirmSaveHandler }
                    onCancel={ () => setIsConfirmOpen(false) }
                    okText={ t('Yes') }
                    cancelText={ t('No') }
                >
                    <Space>
                        <Button
                            type="primary"
                            disabled={ isDataSaving }
                            onClick={ () => setCloseDrawerAfterSubmit(true) }
                        >
                            { t('Save') }
                        </Button>

                        { !worker.id && (
                            <Button
                                type="primary"
                                disabled={ isDataSaving }
                                onClick={ () => setCloseDrawerAfterSubmit(false) }
                            >
                                { t('Save and Add') }
                            </Button>
                        ) }
                    </Space>
                </Popconfirm>
            </Form.Item>
        </Form>
    );
};
