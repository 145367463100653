import setupImage1 from 'assets/img/setup-guide/step_1.png';
import setupImage2 from 'assets/img/setup-guide/step_2.png';
import setupImage3 from 'assets/img/setup-guide/step_3.png';
import setupImage4 from 'assets/img/setup-guide/step_4.png';
import setupImage5 from 'assets/img/setup-guide/step_5.png';
import { userIsClient, userIsProvider } from 'lib/Helpers/UserHelper';

export const getSteps = (user: any, t: (key: string) => string) => {

    const steps_client = [
        {
            image: setupImage1,
            text_1: t('You’ve made it!'),
            text_2: (
                <>
                    {t('Let’s get you ready for your first request!')}
                    <br />
                    <p>{t('~ 4 minutes')}</p>
                </>
            ),
            text_3: t('We’ll have you up and running in no time!'),
            route: '',
        },
        {
            image: setupImage2,
            text_1: t('Step 1'),
            text_2: t('Go to My Organization and fill out your company information'),
            text_3: t('This is an important step in order for company to be easily recognised by your partners.'),
            route: '/client/configuration/organization',
        },
        {
            image: setupImage3,
            text_1: t('Step 2'),
            text_2: t('Select your professions'),
            text_3: t(
                'Look through the list and select just the professions you need from your providers. These will be the professions you can choose from when creating a new request.'
            ),
            route: '/client/configuration/professions',
        },
        {
            image: setupImage4,
            text_1: t('Step 3'),
            text_2: t('Invite your providers'),
            text_3: t('Let your providers know you are using Tempcloud and invite them to join you.'),
            route: '/client/providers',
        },
        {
            image: '',
            text_1: t('Step 4'),
            text_2: t('You are ready! Go create your first request and get your needs filled easier than ever!'),
            text_3: (
                <span>
                    {t('If you still need help, you can always go to our')}{' '}
                    <a
                        href="https://support.tempcloud.com/de/support/home"
                        target="_blank"
                        className="knowledge-base-link"
                    >
                        {t('knowledge base')}
                    </a>{' '}
                    {t('or contact our support team.')}
                </span>
            ),
            route: '/client/requests/create',
        },
    ];

    const steps_provider = [
        {
            image: setupImage1,
            text_1: t('You’ve made it!'),
            text_2: (
                <>
                    {t('Let’s get you ready for your first request!')}
                    <br />
                    <p>{t('~ 4 minutes')}</p>
                </>
            ),
            text_3: t('We’ll have you up and running in no time!'),
            route: '',
        },
        {
            image: setupImage2,
            text_1: t('Step 1'),
            text_2: t('Go to My Organization and fill out your company information'),
            text_3: t('This is an important step in order for company to be easily recognised by your partners.'),
            route: '/provider/configuration/organization',
        },
        {
            image: setupImage3,
            text_1: t('Step 2'),
            text_2: t('Select your professions'),
            text_3: t(
                'Look through the list and select just the professions you offer for your clients. In this way, you won’t receive unwanted requests that you will have to reject.'
            ),
            route: '/provider/configuration/professions',
        },
        {
            image: setupImage4,
            text_1: t('Step 3'),
            text_2: t('Add your workers'),
            text_3: t(
                'In order to be able to assign your workers in the clients’ requests, you need to add their name and details in the platform.'
            ),
            route: '/provider/workers',
        },
        {
            image: setupImage5,
            text_1: t('Step 4'),
            text_2: t('Invite your clients'),
            text_3: t('Let your clients know you are using Tempcloud and invite them to join you as collaborators.'),
            route: '/provider/clients',
        },
        {
            image: '',
            text_1: 'Step 5',
            text_2: 'You are ready! Go to the Requests page and wait for your first project in Tempcloud!',
            text_3: (
                <span>
                    {t('If you still need help, you can always go to our')}{' '}
                    <a
                        href="https://support.tempcloud.com/de/support/home"
                        target="_blank"
                        className="knowledge-base-link"
                    >
                        {t('knowledge base')}
                    </a>{' '}
                    {t('or contact our support team.')}
                </span>
            ),
            route: '/provider/requests',
        },
    ];

    return userIsClient(user) ? steps_client : userIsProvider(user) ? steps_provider : steps_client;
};
