import { Drawer, FormInstance, notification } from 'antd';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { AddressForm } from 'pages/Client/Configuration/Addresses/AddressForm';
import { useState } from 'react';
import { Address, Department } from 'types/models';

type Props = {
    departments: Department[];
    isAddressFormOpen: boolean;
    onFormClose: () => void;
    onSaveAddressHandler: (address?: Address) => void;
};

export const RequestCreationAddressFormDrawer = ({
    departments,
    isAddressFormOpen,
    onFormClose,
    onSaveAddressHandler
}: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataSaving, setIsDataSaving] = useState(false);

    const addressesURL = '/data-management/addresses';

    const saveAddressHandler = async (values: Address, form: FormInstance) => {
        try {
            setIsDataSaving(true);
            const response = await Backend.post(addressesURL, values);
            if (response.status === Backend.responseStatus.HTTP_CREATED) {
                notification.success({
                    message: t('Data successfully created'),
                });
                onSaveAddressHandler({ ...values, id: response.data.id });
            } else {
                onSaveAddressHandler();
            }
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataSaving(false);
        }
    };

    return (
        <>
            <Drawer
                title={t('Add Location')}
                width={500}
                onClose={() => onFormClose()}
                open={isAddressFormOpen}
            >
                <AddressForm
                    departments={departments}
                    saveAddressHandler={saveAddressHandler}
                    isDataSaving={isDataSaving}
                />
            </Drawer>
        </>
    );
};
