import 'shared/Requests/Request/Request.scss';
import { PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';

import { Button, Card, Modal, notification, Typography } from 'antd';
import { useAccount } from 'lib/Account';
import { useErrorHandler } from 'lib/ErrorHandling';
import { localizeProfession } from 'lib/Helpers/ProfessionHelper';
import { userIsClient, userIsProvider } from 'lib/Helpers/UserHelper';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { requestToFormRequest } from 'lib/Request/utils';
import { RequestActionButtons } from 'pages/Client/Requests/Request/RequestActionButtons';
import { RequestHeaderTag } from 'pages/Client/Requests/Request/RequestHeaderTag';
import {
    JobToSaveType,
    ProviderRequestActionButtons
} from 'pages/Provider/Requests/Request/ProviderRequestActionButtons';
import { ProviderRequestHeaderTag } from 'pages/Provider/Requests/Request/ProviderRequestHeaderTag';
import { useCallback, useEffect, useState } from 'react';
import { useURLParams } from 'lib/Helpers/SearchParams';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { LoaderSkeleton } from 'shared/AntDesignUtils/Loaders';
import { formatJobs } from 'shared/Requests/helpers';
import { RequestHeader } from 'shared/Requests/Request/RequestHeader';
import { RequestJobsTable } from 'shared/Requests/Request/RequestJobsTable';
import { ShiftFormModal } from 'shared/Requests/Request/ShiftFormModal';
import { Address, Job, Profession, Provider, ProviderRequest, Request as RequestModel } from 'types/models';
import { FormattedJob, FormRequest, ProviderRequestTransition, RequestState, RequestTransition } from 'types/staffing';
import { JobApplicantState } from 'types/staffing/JobApplicantStateMachine';

const { Text } = Typography;

export const Request = () => {
    const { t, locale } = useLocalization();
    const handleError = useErrorHandler();
    const navigate = useNavigate();
    const { accountUser: user } = useAccount();
    const isProvider = userIsProvider(user);
    const isClient = userIsClient(user);
    const { id } = useParams<{ id: string }>();

    const requestsURL = `/staffing/requests/${id}`;

    const [originalRequest, setOriginalRequest] = useState<RequestModel>({} as RequestModel);
    const [request, setRequest] = useState<RequestModel>({} as RequestModel);
    const [providerRequest, setProviderRequest] = useState<ProviderRequest>({} as ProviderRequest);
    const [loadingRequest, setLoadingRequest] = useState(true);
    const [jobsToUpdate, setJobsToUpdate] = useState<FormattedJob[]>([]);
    const [jobs, setJobs] = useState<FormattedJob[]>([]);
    const [editRequest, setEditRequest] = useState(false);
    const [jobsToCancel, setJobsToCancel] = useState<number[]>([]);
    const [editProposal, setEditProposal] = useState(false);
    const [formRequest, setFormRequest] = useState<FormRequest>();
    const [jobEditing, setJobEditing] = useState<FormattedJob>();
    const [openShiftFormModal, setOpenShiftFormModal] = useState(false);
    const [historical, setHistorical] = useState(false);
    const [addresses, setAddresses] = useState<any[]>([]);
    const [loadingLocations, setLoadingLocations] = useState(true);
    const [requestAddressId, setRequestAddressId] = useState<number>(0);
    const [jobsToSave, setJobsToSave] = useState<JobToSaveType[]>([]);
    const [savedJobs, setSavedJobs] = useState<JobToSaveType[]>([]);
    const [loadingProviders, setLoadingProviders] = useState<boolean>(false);
    const [providers, setProviders] = useState<Provider[]>([]);

    useURLParams<any>({
        edit: () => startEditing(),
        history: () => setHistorical(true),
    });

    const formatAndSetJobs = (jobs: Job[]) => setJobs(formatJobs(jobs, isClient));

    useEffect(() => {
        setJobsToSave(
            jobs
                .map((job) => {
                    if (
                        job.workerId &&
                        !job.applicants?.find(
                            ({ worker_id, state }) =>
                                worker_id === job.workerId &&
                                [
                                    JobApplicantState.OPEN,
                                    JobApplicantState.PROPOSED,
                                    JobApplicantState.ACCEPTED,
                                ].includes(state)
                        )
                    ) {
                        return {
                            worker_id: job.workerId,
                            job_id: job.id,
                        };
                    }
                })
                .filter(Boolean) as JobToSaveType[]
        );
        setSavedJobs(
            jobs
                .map((job) => {
                    if (
                        job.workerId &&
                        job.applicants?.find(
                            ({ worker_id, state }) =>
                                worker_id === job.workerId &&
                                [
                                    JobApplicantState.OPEN,
                                    JobApplicantState.PROPOSED,
                                    JobApplicantState.ACCEPTED,
                                ].includes(state)
                        )
                    ) {
                        return {
                            worker_id: job.workerId,
                            job_id: job.id,
                        };
                    }
                })
                .filter(Boolean) as JobToSaveType[]
        );
    }, [jobs]);

    useEffect(() => {
        const loadProviders = async (request: FormRequest) => {
            try {
                setLoadingProviders(true);

                const response = await Backend.get('/data-management/providers?relations=professions');
                const providers = (response.data.providers || [])
                    .filter((provider: Provider) =>
                        provider.professions.some(({ id }: Profession) => id === request.profession_id)
                    )
                    .sort((p1: Provider, p2: Provider) => p1.company_name.localeCompare(p2.company_name));

                setProviders(providers);
            } catch (error) {
                handleError(error);
            } finally {
                setLoadingProviders(false);
            }
        };

        const loadRequestData = async () => {
            try {
                setLoadingRequest(true);

                const response = await Backend.get(requestsURL);
                const { request } = response.data;

                setOriginalRequest({ ...request })
                setRequest({ ...request });

                if (isProvider && request.provider_requests.length) {
                    setProviderRequest(request.provider_requests[0]);
                }

                return request;
            } catch (error) {
                setOriginalRequest({} as RequestModel);
                setRequest({} as RequestModel);
            } finally {
                setLoadingRequest(false);
            }
        };

        const load = async () => {
            const request = await loadRequestData();

            if (isProvider || !request) {
                return;
            }

            await loadProviders(request);
        };

        load();
    }, [requestsURL, handleError]);

    useEffect(() => {
        if (!request.jobs) {
            return;
        }

        const jobsToFormat: Job[] = [];

        if (historical) {
            if ([RequestState.CANCELED, RequestState.EXPIRED, RequestState.REJECTED].includes(request.state as any)) {
                jobsToFormat.push(...request.jobs);
            } else {
                jobsToFormat.push(
                    ...request.jobs.filter(
                        (job) => (isClient && job.is_client_archived) || (isProvider && job.is_provider_archived)
                    )
                );
            }
        } else {
            jobsToFormat.push(
                ...request.jobs.filter(
                    (job) => (isClient && !job.is_client_archived) || (isProvider && !job.is_provider_archived)
                )
            );
        }

        formatAndSetJobs(jobsToFormat);
    }, [request]);

    const loadLocations = useCallback(async () => {
        if (addresses.length) {
            return;
        }

        try {
            setLoadingLocations(true);

            const { data } = await Backend.get('/data-management/addresses?collect=departmentIds&relations=country');

            setAddresses(
                (data.addresses || [])
                    .sort((a1: Address, a2: Address) => a1.address.localeCompare(a2.address))
                    .map((address: Address) => {
                        address.department_ids = data.collections?.departmentIds?.[address.id] || [];

                        return address;
                    })
            );
        } catch (error) {
            handleError(error);
        } finally {
            setLoadingLocations(false);
        }
    }, [handleError]);

    const startEditing = () => {
        loadLocations();

        if (request.jobs) {
            setFormRequest(requestToFormRequest(request));
        }

        setRequestAddressId(requestAddressId ?? request.address_id);
        setEditRequest(true);
    };

    const canceledDate = new Date(request.updated_at ?? '').toLocaleDateString(locale.shortCode, {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });

    const renderCanceledByInfoText = request.canceled_by && (
        <span className="pull-right">
            {t('Canceled on')} {canceledDate} {t('by')}
            &nbsp;
            <Text strong>
                {request.canceled_by?.first_name} {request.canceled_by?.last_name}
            </Text>
            &nbsp;
        </span>
    );

    const renderRequestHeader = isProvider ? (
        <ProviderRequestHeaderTag providerRequestState={providerRequest.state} requestState={request.state} />
    ) : (
        <RequestHeaderTag requestState={request.state} />
    );

    const cancelJobEditing = () => {
        setJobEditing(undefined);
        setOpenShiftFormModal(false);
    };

    const handleUpdateRequest = async () => {
        try {
            const { data } = await Backend.put(`staffing/requests/${request.id}/details`, {
                address_id: requestAddressId,
                department_id: request.department_id,
                is_draft: request.state === RequestState.DRAFT,
                notes: request.notes,
                providers: request.providers,
            });

            data.address = addresses.find((address) => address.id === requestAddressId);

            setOriginalRequest({ ...request, ...data });

            return true;
        } catch (error) {
            handleError(error);

            return false;
        }
    };

    const handleUpdateJobs = async (_jobsToUpdate: FormattedJob[] = []) => {
        try {
            if (!jobsToUpdate.length && !_jobsToUpdate.length) {
                return request;
            }

            const requestJobs = [...jobsToUpdate, ..._jobsToUpdate].map((job) => {
                const originalJob = jobs.find((j) => j.id === job.id) as FormattedJob;

                if (
                    originalJob.time !== job.time ||
                    originalJob.date !== job.date ||
                    originalJob.start_time !== job.start_time ||
                    originalJob.shiftDuration !== job.shiftDuration ||
                    originalJob.breakStartTime !== job.breakStartTime ||
                    originalJob.breakDuration !== job.breakDuration
                ) {
                    return {
                        ...job,
                        prevTime: originalJob!.time,
                        prevDate: originalJob!.date,
                        prevStartTime: originalJob!.start_time,
                        prevShiftDuration: originalJob!.shiftDuration,
                        prevBreakStartTime: originalJob!.breakStartTime,
                        prevBreakDuration: originalJob!.breakDuration,
                        prevComment: originalJob!.comment,
                    };
                }

                return job;
            });

            const response = await Backend.put(`staffing/requests/${request.id}/jobs`, {
                jobs: requestJobs,
            });

            const localRequest = {...request};

            if (response.status === Backend.responseStatus.HTTP_OK) {
                notification.success({
                    message: t(`Job successfully updated`),
                });

                const jobs = [...localRequest.jobs];

                response.data.forEach((job: Job) => {
                    const index = jobs.findIndex(({ id }) => id === job.id);

                    if (index === -1) {
                        return;
                    }

                    jobs.splice(index, 1, {
                        ...jobs[index],
                        ...job,
                    });
                });

                localRequest.jobs = jobs;

                setRequest((prevState) => ({
                    ...prevState,
                    ...localRequest,
                }));

                setJobsToUpdate([]);
                setEditRequest(false);
                setJobEditing(undefined);
                setOpenShiftFormModal(false);
            }

            return localRequest;
        } catch (error) {
            handleError(error);

            return false;
        }
    };

    const updateJobsToSave = (jobsToSave: JobToSaveType[]) => {
        setJobsToSave(jobsToSave);
        setRequest(request);
        formatAndSetJobs(request.jobs);
    };

    const getActionButtons = () => {
        if (!isProvider) {
            return (
                <RequestActionButtons
                    editRequest={editRequest}
                    jobs={jobs}
                    jobsToCancel={jobsToCancel}
                    handleUpdateJobs={handleUpdateJobs}
                    handleUpdateRequest={handleUpdateRequest}
                    historical={historical}
                    possibleTransitions={request.possibleTransitions as RequestTransition[]}
                    request={request}
                    setJobsToCancel={setJobsToCancel}
                    setJobsToUpdate={setJobsToUpdate}
                    setRequest={setRequest}
                    startEditing={startEditing}
                    stopEditing={(reset: boolean = true) => {
                        if (reset) {
                            setRequest({ ...originalRequest, documents: request.documents });
                        }

                        setEditRequest(false);
                    }}
                />
            );
        }

        if (isProvider && [RequestState.PENDING, RequestState.POSTED, RequestState.FULFILLED].includes(request.state)) {
            return (
                <ProviderRequestActionButtons
                    editProposal={editProposal}
                    jobsToSave={jobsToSave}
                    historical={historical}
                    possibleTransitions={providerRequest.possibleTransitions as ProviderRequestTransition[]}
                    providerRequest={providerRequest}
                    request={request}
                    savedJobs={savedJobs}
                    setEditProposal={setEditProposal}
                    updateJobsToSave={updateJobsToSave}
                    isIconButton={false}
                    setRequest={setRequest}
                    updateRequest={(request: RequestModel) => {
                        setRequest(request);

                        if (request.provider_requests.length) {
                            setProviderRequest(request.provider_requests[0]);
                        }
                    }}
                />
            );
        }
    };

    const actionConfirm = (title: string, onOk: () => void) =>
        Modal.confirm({
            title: t(title),
            icon: null,
            content: (
                <>
                    <p>{t('The progress you made will be lost and shifts might be filled by other providers.')}</p>
                </>
            ),
            okText: t('Yes'),
            cancelText: t('Cancel'),
            centered: true,
            onOk,
        });

    const handleBackButtonClick = () => {
        if (isProvider && editProposal) {
            actionConfirm('Are you sure you want to leave the page?', () => navigate(-1));
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        setRequest({
            ...request,
            address_id: requestAddressId as number,
        });
    }, [requestAddressId]);

    return loadingRequest ? (
        <LoaderSkeleton size={2} />
    ) : (
        <>
            {!request.id && (
                <Card className="text-center">
                    {t('This request is no longer available')}

                    <div style={{ marginTop: 20 }}>
                        <Button type="primary" onClick={() => navigate(`/${isClient ? 'client' : 'provider'}/requests`)}>
                            {t('Go to requests')}
                        </Button>
                    </div>
                </Card>
            )}

            {!!request.id && (
                <>
                    <PageHeader
                        title={localizeProfession(request.profession, locale).name}
                        onBack={handleBackButtonClick}
                        ghost={false}
                        className="request-page-header"
                        tags={[renderRequestHeader, renderCanceledByInfoText]}
                        extra={getActionButtons()}
                    >
                        <RequestHeader
                            addresses={addresses}
                            editRequest={editRequest}
                            handleUpdateJobs={handleUpdateJobs}
                            loadLocations={loadLocations}
                            loadingLocations={loadingLocations}
                            providers={providers}
                            request={request}
                            requestAddressId={requestAddressId}
                            setRequestAddressId={setRequestAddressId}
                            updateRequest={(partialRequest: Partial<RequestModel>) => setRequest({
                                ...request,
                                ...partialRequest,
                            })}
                        />
                    </PageHeader>

                    {isClient && editRequest && (
                        <div className="text-right">
                            <Button icon={<PlusOutlined />} type="link" onClick={() => setOpenShiftFormModal(true)}>
                                {t('Add shifts')}
                            </Button>
                        </div>
                    )}

                    <RequestJobsTable
                        editProposal={editProposal}
                        editRequest={editRequest}
                        jobs={jobs}
                        jobsToCancel={jobsToCancel}
                        jobsToUpdate={jobsToUpdate}
                        historical={historical}
                        providerRequest={providerRequest}
                        request={request}
                        setJobs={setJobs}
                        setJobsToCancel={setJobsToCancel}
                        setJobToEdit={(job) => {
                            setJobEditing(structuredClone(job));
                            setOpenShiftFormModal(true);
                        }}
                        setRequest={setRequest}
                    />

                    {openShiftFormModal && (
                        <ShiftFormModal
                            departmentId={request.department_id}
                            job={jobEditing}
                            jobsToUpdate={jobsToUpdate}
                            onCancel={cancelJobEditing}
                            open={true}
                            providers={providers}
                            request={request}
                            setJobsToUpdate={setJobsToUpdate}
                            setOpenModal={setOpenShiftFormModal}
                            setRequest={setRequest}
                        />
                    )}
                </>
            )}
        </>
    );
};
