import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, notification, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { useAccount } from 'lib/Account';
import { useErrorHandler } from 'lib/ErrorHandling';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { useTable } from 'lib/hooks/useTable';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { useEffect, useState } from 'react';
import { DeletePopconfirm } from 'shared/AntDesignUtils/DeletePopconfirm';
import { OrganizationType } from 'types';
import { Member, Role } from 'types/models';

type Props = {
    membersUrl: string;
    members: Member[];
    roleOptions: DefaultOptionType[];
    roleFilterOptions: ColumnFilterItem[];
    saveHandler: (member: Member) => void;
    openMemberFormHandler: (member: Member) => void;
    loadOrganizationData: () => void;
    isDataLoading: boolean;
    organizationType: OrganizationType;
};

/**
 * Table to display members of an organization.
 */
export const MembersTable = ({
    membersUrl,
    members,
    roleOptions,
    roleFilterOptions,
    saveHandler,
    openMemberFormHandler,
    loadOrganizationData,
    isDataLoading,
    organizationType,
}: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const { accountUser: user } = useAccount();
    const { tableParams, handleTableChange, setTotal } = useTable();

    const [isDataDeleting, setIsDataDeleting] = useState(false);

    const membersWithFullName = members.map((member) => ({
        ...member,
        fullName: `${member.first_name.trim()} ${member.last_name.trim()}`,
    }));

    const canManageMember = (member: Member): boolean => {
        if (user.security.permissions.includes('manage-owner-roles')) {
            return true;
        }
        return (
            user.security.permissions.includes(`manage-${organizationType}-roles`) &&
            !member.roles.map((role) => role.name).includes(`${organizationType}.owner`)
        );
    };

    /**
     * Render cell for member name.
     *
     * @param record
     */
    const renderNameCell = (record: Member) => (
        <Button
            type="link"
            onClick={() => openMemberFormHandler(record)}
        >{`${record.first_name} ${record.last_name}`}</Button>
    );

    /**
     * Render cell for email of member.
     *
     * @param email {string}
     * @return {*}
     */
    const renderEmailCell = (email: string) => (
        <a href={`mailto:${email}`} target="blank">
            {email}
        </a>
    );

    /**
     * Render an in-line edit for member roles.
     *
     * @param roles {array}
     * @param member {Member}
     * @return {*}
     */
    const renderRolesCell = (roles: Role[], member: Member) => (
        <Select
            mode="multiple"
            placeholder={t('Assign roles')}
            className="multi-select"
            value={roles.map((role) => ({ value: role.id, label: role.name }))}
            onChange={(value: { value: number; label: string }[]) =>
                value.length > 0 && saveHandler({ id: member.id, roles: value } as unknown as Member)
            }
            options={roleOptions}
            disabled={!canManageMember(member)}
            removeIcon={roles.length > 1 ? <CloseOutlined /> : false}
        />
    );

    /**
     * Render a cell with action buttons.
     *
     * @return {*}
     * @param member
     */
    const renderActionCell = (member: Member) => {
        const lastMember = members.length === 1;
        const memberIsLoggedInUser = member.user_id === user.id;

        return (
            <Space>
                <EditOutlined onClick={() => openMemberFormHandler(member)} />
                {!memberIsLoggedInUser && !lastMember ? (
                    <DeletePopconfirm
                        title={t('Are you sure you want to delete this member?')}
                        id={member.id}
                        isDataDeleting={isDataDeleting}
                        deleteHandler={deleteMemberHandler}
                    />
                ) : null}
            </Space>
        );
    };

    /**
     * Delete a member by id.
     *
     * @param id {int}
     * @return {Promise<void>}
     */
    const deleteMemberHandler = async (id: number) => {
        try {
            setIsDataDeleting(true);
            const response = await Backend.delete(`${membersUrl}/${id}`);
            if (response.status === Backend.responseStatus.HTTP_NO_CONTENT) {
                notification.success({
                    message: t('Member successfully removed'),
                });
                loadOrganizationData();
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataDeleting(false);
        }
    };

    useEffect(() => {
        setTotal(membersWithFullName.length);
    }, [membersWithFullName]);

    const columns: ColumnsType<Member> = [
        {
            title: t('Name'),
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: (a, b) => sortColumn(a, b, 'fullName'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('fullName'),
            render: (first_name, record) => renderNameCell(record),
        },
        {
            title: t('Email'),
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => sortColumn(a, b, 'email'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('email'),
            render: (email) => renderEmailCell(email),
        },
        {
            title: t('Job'),
            dataIndex: 'job_description',
            key: 'job_description',
            sorter: (a, b) => sortColumn(a, b, 'job_description'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('job_description'),
        },
        // TC-1089
        // Commented out because its hidden temporarily
        // {
        //     title: t('Roles'),
        //     dataIndex: 'roles',
        //     key: 'roles',
        //     filters: roleFilterOptions,
        //     filterMultiple: true,
        //     filterSearch: true,
        //     onFilter: (value, record) => filterRoles(value as number, record),
        //     sorter: (a, b) => sortColumn(a.roles[0], b.roles[0], 'name'),
        //     sortDirections: ['ascend', 'descend'],
        //     render: (roles, record) => renderRolesCell(roles, record),
        // },
        {
            title: t('Action'),
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => renderActionCell(record),
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={columns}
            dataSource={membersWithFullName}
            pagination={ tableParams.pagination }
            loading={isDataLoading}
            onChange={handleTableChange}
            scroll={{ scrollToFirstRowOnChange: true, y: 'calc(100vh - 380px)' }}
            size="small"
        />
    );
};
