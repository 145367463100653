import moment, { Moment } from 'moment';
import dayjs from 'dayjs';
import { addDays } from 'date-fns';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { t } from 'lib/Localization/Translation';
import { Locale } from 'types';

export const dateFormat = 'YYYY-MM-DD';
export const timeFormat = 'HH:mm';

export const dateTimeOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
};

export const getDateString = (date: Date, locale: Locale, withTime = false) => {
    let options = { ...dateTimeOptions };

    if (withTime) {
        options = {
            ...options,
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };
    }

    return new Date(date).toLocaleDateString(locale.shortCode, options);
};

export const getDayTime = (day: Date) => new Date(day.getFullYear(), day.getMonth(), day.getDate()).getTime();

/**
 * Returns a string in the format HH[h]:mm[m] (eg: 15h30m) as the duration of a time interval
 */
export const getTimeDuration = (startTime?: string, endTime?: string, inputTimeFormat: string = timeFormat) => {
    if (!startTime || !endTime) {
        return '';
    }

    const momentStartTime = moment(startTime, inputTimeFormat);
    const momentEndTime = moment(endTime, inputTimeFormat);

    if (momentStartTime >= momentEndTime) {
        momentEndTime.add(1, 'days');
    }

    const duration = moment.duration(momentEndTime.diff(momentStartTime));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;
    let formattedDuration = '';

    if (hours !== 0) {
        formattedDuration = `${hours}h`;
    }

    if (minutes !== 0) {
        if (formattedDuration) {
            formattedDuration += ' ';
        }

        formattedDuration += `${minutes}m`;
    }

    return formattedDuration;
};

export const getTime = (time: string) => (time ? moment(time, timeFormat).format(timeFormat) : '');

/**
 * Return a string in the format HH:mm - HH:mm (eg: 08:00 - 16:00)
 */
export const getTimeRange = (startTime: string, endTime: string) => {
    if (!startTime || !endTime) {
        return '';
    }

    const momentStartTime = moment(startTime, timeFormat);
    const momentEndTime = moment(endTime, timeFormat);

    let time = `${startTime} - ${endTime}`;

    if (momentStartTime >= momentEndTime) {
        momentEndTime.add(1, 'days');
        time += ' +1d';
    }

    return time;
};

export const minDateOfDates = (dates: Date[]) => dates.reduce((a, b) => (a < b ? a : b));

export const maxDateOfDates = (dates: Date[]) => dates.reduce((a, b) => (a > b ? a : b));

export const uniqueDates = (dates: Date[]) =>
    dates.filter((date, i, self) => self.findIndex((d) => getDayTime(d) === getDayTime(date)) === i);

export const sortDates = (dates: Date[]) => dates.sort((a, b) => getDayTime(a) - getDayTime(b));

/**
 * Return an array of dates removing elements in datesB from datesA
 */
export const diffDates = (datesA: Date[], datesB: Date[]) => {
    return datesA.filter((a) => datesB.filter((b) => getDayTime(b) === getDayTime(a)).length === 0);
};

/**
 * Return a boolean indicating whether the date is included in the array of dates
 */
export const datesIncludeDate = (dates: Date[], date: Date) => dates.some((d) => getDayTime(d) === getDayTime(date));

/**
 * Return an array of Dates including all dates between start and end (extremes included)
 */
export const getDatesBetween = (start: Date, end: Date) => {
    const datesBetween = [];
    let currentDate = start;
    if (currentDate <= end) {
        while (currentDate <= end) {
            datesBetween.push(new Date(currentDate));
            currentDate = addDays(currentDate, 1);
        }
    }

    return datesBetween;
};

/**
 * Return an array of AntDesign DefaultOptionType containing all the times for a TimePicker
 *
 * @param minutesStep
 * @param startTime
 * @param endTime
 * @param showDuration
 * @param showNextDay
 */
export const getTimeOptionsForTimePicker = (
    minutesStep = 15,
    startTime?: string | null,
    endTime?: string | null,
    showDuration = true,
    showNextDay = false
) => {
    const options: DefaultOptionType[] = [];
    const momentStartTime = moment(startTime || '00:00', timeFormat);

    let momentEndTime = endTime
        ? moment(endTime || '23:45', timeFormat).add(1, 'minutes')
        : moment(momentStartTime).add(1, 'days');

    if (momentEndTime < momentStartTime) {
        momentEndTime = momentEndTime.add(1, 'days');
    }

    const createOption = (time: Moment) => {
        const formattedTime = time.format(timeFormat);
        let label = formattedTime;

        if (startTime) {
            if (showDuration) {
                label += ` (${getTimeDuration(startTime, formattedTime)})`;
            } else if (showNextDay && time.date() === momentEndTime.date()) {
                label += ` (${t('next day')})`;
            }
        }

        return { value: formattedTime, label };
    };

    while (momentStartTime < momentEndTime) {
        options.push(createOption(momentStartTime));
        momentStartTime.add(minutesStep, 'minutes');
    }

    options.shift();

    // use startTime as the last option but in the next day, if needed
    if (startTime && !endTime) {
        let newMomentStartTime = moment(startTime || '00:00', timeFormat);

        if (momentEndTime > newMomentStartTime) {
            newMomentStartTime = newMomentStartTime.add(1, 'days');
        }

        options.push(createOption(newMomentStartTime));
    }

    return options;
};

/**
 * Get start and end times in the middle of a time interval
 */
export const getStartEndTimesInTheMiddleOfInterval = (
    startTime: string,
    endTime: string,
    durationInMinutes: number
) => {
    const momentStartTime = moment(startTime, timeFormat);
    let momentEndTime = moment(endTime, timeFormat);

    if (momentEndTime < momentStartTime) {
        momentEndTime = momentEndTime.add(1, 'days');
    }

    if (!momentStartTime.isValid()) {
        throw new Error(`startTime is not a valid time in the format '${timeFormat}'`);
    }

    if (!momentEndTime.isValid()) {
        throw new Error(`endTime is not a valid time in the format '${timeFormat}'`);
    }

    const positiveDurationInMinutes = durationInMinutes > 0 ? durationInMinutes : 0;
    const intervalDurationInMinutes = momentEndTime.diff(momentStartTime) / 60_000;
    const middleStartTime = moment(momentStartTime).add(
        (intervalDurationInMinutes - positiveDurationInMinutes) / 2,
        'minutes'
    );
    const middleEndTime = moment(middleStartTime).add(positiveDurationInMinutes, 'minutes');

    return [middleStartTime.format(timeFormat), middleEndTime.format(timeFormat)];
};

export const hasOneDayPassed = (time: Date | string) => {
    const updatedDate = dayjs(time);

    const diffDays = dayjs().diff(updatedDate, 'day');

    return diffDays >= 1;
};

export const convertDurationToMinutes = (duration: string) => {
    const parts = duration.split(' ');

    let totalMinutes = 0;

    parts.forEach((part) => {
        if (part.includes('d')) {
            totalMinutes += parseInt(part) * 24 * 60;
        } else if (part.includes('h')) {
            totalMinutes += parseInt(part) * 60;
        } else if (part.includes('m')) {
            totalMinutes += parseInt(part);
        }
    });

    return totalMinutes;
};

export const removeTimeSeconds = (time: string) => time.substring(0, 5);
