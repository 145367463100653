import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { useTable } from 'lib/hooks/useTable';
import { useLocalization } from 'lib/Localization';
import { useEffect } from 'react';
import { Member } from 'types/models';

type Props = {
    profiles: Member[];
};

/**
 * Table to display profile data of organization.
 *
 * @param profiles
 * @returns {JSX.Element}
 * @constructor
 */
export const ConnectedOrganizationsMembersTable = ({ profiles }: Props) => {
    const { t } = useLocalization();
    const { tableParams, handleTableChange, setTotal } = useTable();

    /**
     * Render cell for email of agent.
     *
     * @param record {string}
     * @return {*}
     */
    const renderEmailCell = (record: Member) => (
        <a href={`mailto:${record.user?.email}`} target="blank">
            {record.user?.email}
        </a>
    );

    useEffect(() => {
        setTotal(profiles.length);
    }, [profiles]);

    const columns: ColumnsType<Member> = [
        {
            title: t('Name'),
            dataIndex: 'first_name',
            key: 'first_name',
            sorter: (a, b) => sortColumn(a, b, 'first_name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('first_name'),
            render: (first_name, record) => `${record.user.first_name} ${record.user.last_name}`,
        },
        {
            title: t('Email'),
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => sortColumn(a, b, 'email'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('email'),
            render: (email, record) => renderEmailCell(record),
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={columns}
            dataSource={profiles}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            scroll={{ scrollToFirstRowOnChange: true, y: 'calc(100vh - 270px)' }}
            size="small"
        />
    );
};
