import 'pages/Provider/Requests/Request/ProviderRequestActionButtons.scss';
import {
    CheckCircleFilled,
    CheckOutlined,
    CloseCircleFilled,
    CloseOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import { Button, notification, Popconfirm, Space, Tooltip, Typography } from 'antd';
import { useErrorHandler } from 'lib/ErrorHandling';
import { useRequestActions } from 'lib/hooks/useRequestActions';
import { useRequestTab } from 'lib/hooks/useRequestTab';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { downloadExcel } from 'lib/Request';
import { requestHasFutureJob } from 'lib/Request/utils';
import { useEffect, useState } from 'react';
import { FileArchive } from 'shared/icons/FileArchive';
import { ActionProgress } from 'shared/Requests/ActionProgress';
import { ProviderRequest, Request } from 'types/models';
import { ProviderRequestState, ProviderRequestTransition, RequestState } from 'types/staffing';
import { RequestAcceptanceType } from 'types/staffing/RequestAcceptanceType';

const { Text } = Typography;

export type JobToSaveType = {
    worker_id: number;
    job_id: number;
};

type Props = {
    editProposal?: boolean;
    hideProgress?: boolean;
    onAccept?: () => void;
    jobsToSave?: JobToSaveType[];
    historical?: boolean;
    possibleTransitions: ProviderRequestTransition[];
    providerRequest: ProviderRequest;
    request: Request;
    savedJobs?: JobToSaveType[];
    setRequest?: (request: Request) => void;
    setActionIsLoading?: (isLoading: boolean) => void;
    setEditProposal?: (state: boolean) => void;
    updateJobsToSave?: (jobsToSave: any) => void;
    isIconButton?: boolean;
    updateRequest: (request: Request) => void;
};

export const ProviderRequestActionButtons = ({
    editProposal,
    hideProgress = false,
    historical,
    jobsToSave,
    onAccept,
    possibleTransitions,
    providerRequest,
    request,
    savedJobs,
    setRequest,
    setEditProposal,
    updateJobsToSave,
    isIconButton,
    updateRequest,
}: Props) => {
    const localeContext = useLocalization();
    const { t } = localeContext;
    const handleError = useErrorHandler();
    const { getTabInfo } = useRequestTab();

    const providerRequestsURL = `/staffing/providerRequests/${providerRequest.id}`;
    const [acceptProviderRequestLoading, setAcceptProviderRequestLoading] = useState(false);
    const [rejectProviderRequestLoading, setRejectProviderRequestLoading] = useState(false);
    const [concludeProviderRequestLoading, setConcludeProviderRequestLoading] = useState(false);
    const [canSaveProgress, setCanSaveProgress] = useState(false);
    const [savingProgress, setSavingProgress] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [initialJobsToSave, setInitialJobsToSave] = useState<any[]>([]);
    const [initialRequest, setInitialRequest] = useState<any>();
    const { movablePastJobs, moveJobsToHistory } = useRequestActions(request, updateRequest);

    const tabInfo = getTabInfo(request);

    useEffect(() => {
        setCanSaveProgress(!!(jobsToSave || []).length)
    }, [jobsToSave]);

    const acceptProviderRequestHandler = async (e?: React.MouseEvent) => {
        try {
            e?.stopPropagation();

            setAcceptProviderRequestLoading(true);

            const response = await Backend.put(
                `${providerRequestsURL}/applyTransition/${ProviderRequestTransition.ACCEPT}`
            );

            if (response.status === Backend.responseStatus.HTTP_OK) {
                updateRequest(response.data.request);
                notification.success({
                    message: t('Request successfully accepted'),
                });

                onAccept?.();
            }
        } catch (error) {
            handleError(error);
        } finally {
            setAcceptProviderRequestLoading(false);
        }
    };

    const rejectProviderRequestHandler = async (e?: React.MouseEvent) => {
        try {
            e?.stopPropagation();

            setRejectProviderRequestLoading(true);

            const response = await Backend.put(
                `${providerRequestsURL}/applyTransition/${ProviderRequestTransition.REJECT}`
            );

            if (response.status === Backend.responseStatus.HTTP_OK) {
                updateRequest(response.data.request);
                notification.success({
                    message: t('Request successfully rejected'),
                });
            }
        } catch (error) {
            handleError(error);
        } finally {
            setRejectProviderRequestLoading(false);
        }
    };

    const assignWorkersToJobs = async (workers: JobToSaveType[]) => {
        setSavingProgress(true);

        try {
            const response = await Backend.post(`${providerRequestsURL}/proposeWorker`, workers);

            if (response.status === Backend.responseStatus.HTTP_OK) {
                updateRequest(response.data.request);

                notification.success({
                    message: t('Worker successfully assigned |||| Workers successfully assigned', {
                        smart_count: jobsToSave?.length,
                    }),
                });
            }

            setSavingProgress(false);
            setCanSaveProgress(false);

            return true;
        } catch (error) {
            handleError(error);
            setSavingProgress(false);

            return false;
        }
    };

    const applyConcludeTransition = async () => {
        const transition = ProviderRequestTransition.CONCLUDE;
        const response = await Backend.put(`${providerRequestsURL}/applyTransition/${transition}`);

        if (response.status === Backend.responseStatus.HTTP_OK) {
            updateRequest(response.data.request);

            notification.success({
                message: t(
                    request.acceptance_type === RequestAcceptanceType.AUTOMATIC
                        ? 'Your proposal was automatically approved'
                        : 'Request successfully concluded'
                ),
            });
        }
    };

    const concludeProviderRequestHandler = async (e?: React.MouseEvent) => {
        try {
            e?.stopPropagation();
            setConcludeProviderRequestLoading(true);

            if (await assignWorkersToJobs(jobsToSave!)) {
                await applyConcludeTransition();
            }
        } catch (error) {
            handleError(error);
        } finally {
            setConcludeProviderRequestLoading(false);
        }
    };

    const acceptButtonText = isIconButton ? (
        <Tooltip placement="bottomRight" title={t('Accept Request')}>
            <CheckCircleFilled style={{ fontSize: '28px' }} />
        </Tooltip>
    ) : (
        t('Accept')
    );
    const acceptButtonIcon = isIconButton ? '' : <CheckOutlined />;
    const rejectButtonText = isIconButton ? (
        <Tooltip placement="bottomRight" title={t('Reject Request')}>
            <CloseCircleFilled style={{ fontSize: '28px' }} />
        </Tooltip>
    ) : (
        t('Reject')
    );
    const rejectButtonIcon = isIconButton ? '' : <CloseOutlined />;
    const buttonType = isIconButton ? 'link' : 'primary';
    const buttonCss = isIconButton ? { padding: '4px 1px' } : {};

    const actionButtons = {
        [ProviderRequestTransition.ACCEPT]: !historical && (
            <Button
                key={ProviderRequestTransition.ACCEPT}
                type={buttonType}
                style={buttonCss}
                icon={acceptButtonIcon}
                className="action-buttons"
                onClick={acceptProviderRequestHandler}
                loading={acceptProviderRequestLoading}
            >
                {acceptButtonText}
            </Button>
        ),
        [ProviderRequestTransition.REJECT]: !historical && (
            <Popconfirm
                key={ProviderRequestTransition.REJECT}
                placement="bottomRight"
                title={t('Are you sure you want to reject this request?')}
                onConfirm={rejectProviderRequestHandler}
                onCancel={(e) => e?.stopPropagation()}
                okText={t('Yes')}
                cancelText={t('No')}
            >
                <Button
                    type={buttonType}
                    style={buttonCss}
                    icon={rejectButtonIcon}
                    className="action-buttons"
                    loading={rejectProviderRequestLoading}
                    onClick={(e) => e.stopPropagation()}
                    danger
                >
                    {rejectButtonText}
                </Button>
            </Popconfirm>
        ),
        [ProviderRequestTransition.CONCLUDE]: (
            <>
                {canSaveProgress && (
                    <Button type="default" className="action-buttons" loading={savingProgress} onClick={() => assignWorkersToJobs(jobsToSave!)}>
                        {t('Save progress')}
                    </Button>
                )}

                <Popconfirm
                    key={ProviderRequestTransition.CONCLUDE}
                    placement="bottom"
                    title={<Text strong>{t('Are you sure you want to conclude this request?')}</Text>}
                    onConfirm={concludeProviderRequestHandler}
                    onCancel={(e) => e?.stopPropagation()}
                    okText={t('Send proposal')}
                    cancelText={t('Cancel')}
                >
                    {(isIconButton ? '' :
                        <Button type="primary" className="action-buttons" loading={concludeProviderRequestLoading}>
                            {t('Send proposal')}
                        </Button>
                    )}
                </Popconfirm></>
        ),
        [ProviderRequestTransition.OPEN]: null,
    };

    const allowedRequestStatesForActions = [RequestState.PENDING, RequestState.POSTED];
    const isAllowedState = allowedRequestStatesForActions.includes(request.state as RequestState);
    const actionsAndProgress = isAllowedState && requestHasFutureJob(request, false)
        ? possibleTransitions?.map((transition) => actionButtons[transition]) || []
        : [];

    if (providerRequest.state === ProviderRequestState.ACCEPTED && !hideProgress) {
        let percentage = Number(request.filledPositionsPercent);

        if (isNaN(percentage) || percentage === 0) {
            const appliedJobIds = request.jobs.filter(
                ({ applicants }) => (applicants || []).length > 0
            ).map(({ id }) => id);
            const newJobsToSaveCount = (jobsToSave || []).filter(
                ({ job_id }) => !appliedJobIds.includes(job_id)
            ).length;

            percentage = Math.round(((appliedJobIds.length + newJobsToSaveCount) / request.jobs.length) * 100);
        }

        actionsAndProgress.push(<ActionProgress filledPositionsPercent={Number(percentage)} showText />);
    }

    const cancelEdit = (resetEdit = false) => {
        if (resetEdit) {
            jobsToSave = initialJobsToSave;
            request = initialRequest;
            if (updateJobsToSave && setRequest) {
                updateJobsToSave(jobsToSave);
                setRequest(request);
            }
        }

        setEditProposal?.(false);
        setUpdating(false);
    };

    const updateProposal = async () => {
        try {
            setUpdating(true);
            await assignWorkersToJobs(
                [
                    ...(savedJobs || []),
                    ...(jobsToSave || []),
                ]
            );
            cancelEdit();
        } catch (e) {
            handleError(e);
            setUpdating(false);
        }
    };

    const editProposalInitAction = () => {
        setInitialJobsToSave(jobsToSave ?? []);
        setInitialRequest(request);
        setEditProposal?.(true);
    };

    const renderEditProposalButtons = () => {
        if (editProposal === undefined) {
            return;
        }

        if (editProposal) {
            return (
                <Space>
                    <Button
                        disabled={updating}
                        onClick={() => {
                            cancelEdit(true);
                        }}
                    >
                        {t('Cancel editing')}
                    </Button>

                    <Button
                        disabled={updating || JSON.stringify(jobsToSave) === JSON.stringify(initialJobsToSave)}
                        loading={updating}
                        type="primary"
                        onClick={updateProposal}
                    >
                        {t('Update proposal')}
                    </Button>
                </Space>
            );
        }

        return (
            <Button type="primary" onClick={() => editProposalInitAction()}>
                {t('Edit proposal')}
            </Button>
        );
    };

    const movableJobs = request.state === RequestState.REJECTED ?
        request.jobs.filter(({ is_provider_archived }) => !is_provider_archived) :
        movablePastJobs;

    return (
        <Space className="provider-request-action-buttons">
            {isIconButton ? '' : (
                <Button
                    type="default"
                    icon={<DownloadOutlined />}
                    onClick={() =>
                        downloadExcel(
                            {
                                ...request.order,
                                created_by: request.created_by,
                                client: request.client,
                            },
                            localeContext,
                            request
                        )
                    }
                >
                    {t('Download excel')}
                </Button>
            )}

            {!historical && !!movableJobs.length && (!!tabInfo.active.length || !!tabInfo.pending.length) && (
                <Popconfirm
                    icon={false}
                    cancelButtonProps={{ size: 'middle' }}
                    cancelText={t('No')}
                    okButtonProps={{ size: 'middle' }}
                    okText={t('Yes')}
                    onConfirm={() => moveJobsToHistory()}
                    placement="bottomRight"
                    title={
                        <>
                            <p>{t('Are you sure you want to move this request to History?')}</p>
                            <p style={{ color: 'darkgrey' }}>{t('Only finished shifts will be affected.')}</p>
                        </>
                    }
                >
                    <Button icon={<FileArchive style={{ marginRight: '10px' }} />}>{t('Move to History')}</Button>
                </Popconfirm>
            )}

            {movableJobs.length < request.jobs.length && (
                <>
                    {actionsAndProgress}

                    {providerRequest.state === ProviderRequestState.CONCLUDED && renderEditProposalButtons()}
                </>
            )}
        </Space>
    );
};
