import './styles.scss';

import { Button, Space } from 'antd';
import type { RcFile } from 'antd/lib/upload/interface';
import { AxiosResponse } from 'axios';
import { useErrorHandler } from 'lib/ErrorHandling';
import { useLocalStorageState } from 'lib/Helpers/LocalStorageHelper';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { useEffect, useState } from 'react';
import { GenericObject } from 'shared/Contracts';
import { DocumentFile } from 'shared/Documents/DocumentFile';
import { FileUploaderModal } from 'shared/FileUploader/Modal';
import { ArchiveIcon } from 'shared/icons/ArchiveIcon';
import { Document } from 'types/models';

type Props = {
    btnClass?: string;
    btnLabel?: string;
    btnStyle?: GenericObject;
    disabled?: boolean;
    documents?: Document[];
    getRelationId?: (documents: Document[]) => Promise<number | undefined>;
    onRemoved?: (document: Document, updatedDocuments: Document[]) => void;
    onSaved?: (documents: Document[], updatedDocuments: Document[]) => void;
    readonly?: boolean;
    relation: string;
};

export const Documents = (
    {
        btnClass,
        btnLabel,
        btnStyle,
        disabled,
        documents: initialDocuments,
        getRelationId,
        onRemoved,
        onSaved,
        readonly,
        relation
    }: Props
) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();

    const [isLoading, setIsLoading] = useState(false);
    const [showUploader, setShowUploader] = useState(false);
    const [documents, setDocuments] = useState<Document[]>(initialDocuments || []);
    const [meta, setMeta] = useLocalStorageState(`${ relation }-document-meta`, {});
    const [relationId, setRelationId] = useState<number>();

    type Meta = {
        accept: string;
        max_file_size: string;
    };

    const removedDocument = (document: Document) => {
        const localDocs = [...documents];
        const index = localDocs.findIndex(({ id }) => document.id === id);

        if (index > -1) {
            localDocs.splice(index, 1);
        }

        onRemoved?.(document, localDocs);
        setDocuments(localDocs);
    };

    const upload = async (fileList: RcFile[]) => {
        if (disabled || !fileList.length) {
            return false;
        }

        setIsLoading(true);

        const fd = new FormData();

        fd.set('file', fileList[0] as Blob);
        fd.set('relation', relation);

        if (relationId) {
            fd.set('relation_id', String(relationId));
        } else {
            const id = await getRelationId?.(documents);

            if (!id) {
                setIsLoading(false);

                return false;
            }

            setRelationId(id);
            fd.set('relation_id', String(id));
        }

        try {
            const response: AxiosResponse<Document> = await Backend.post(
                `/data-management/documents`,
                fd,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            const newDocs = [...documents, response.data];

            onSaved?.([response.data], newDocs);
            setDocuments(newDocs);
            setIsLoading(false);
            setShowUploader(false);

            return true;
        } catch (e) {
            setIsLoading(false);
            handleError(e);

            return false;
        }
    };

    useEffect(() => {
        const getMeta = async () => {
            const { data } = await Backend.get<Meta>('/data-management/documents/meta');

            setMeta({
                ...data,
                accept: data.accept
                    .split(',')
                    .map((val: string) => `.${ val }`)
                    .join(',')
            });
        };

        if (!Object.keys(meta).length) {
            getMeta();
        }
    }, []);

    return (
        <>
            { !readonly && (
                <>
                    <FileUploaderModal
                        accept={ meta.accept }
                        disabled={ disabled }
                        isLoading={ isLoading }
                        maxFileSize={ meta.max_file_size }
                        okButtonText={ t('Upload') }
                        open={ showUploader }
                        onCancel={ () => setShowUploader(false) }
                        onOk={ upload }
                        title={ t('Upload document') }
                    />

                    <Button className={ btnClass } disabled={ disabled } onClick={ () => !disabled && setShowUploader(true) } style={ btnStyle }>
                        <Space>
                            <ArchiveIcon /> { btnLabel || t('Upload document') }
                        </Space>
                    </Button>
                </>
            ) }

            <ul className="documents-list">
                { documents.map((document, index) => (
                    <li key={ `file-${ index }` }>
                        <DocumentFile
                            disabled={ disabled }
                            document={ document }
                            onRemoved={ () => removedDocument(document) }
                            readonly={ readonly }
                        />
                    </li>
                )) }
            </ul>
        </>
    );
};
