import { TableParams } from 'types/Table';
import { GenericObject } from 'shared/Contracts';
import { SorterResult } from 'antd/es/table/interface';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { usePagination } from './usePagination';

const getCleanFilters = (filters: GenericObject) => {
    const customFilter: GenericObject = {};

    for (const key in filters) {
        if (!filters[key]) {
            continue;
        }

        if (key === 'startEnd') {
            customFilter['startDate'] = filters[key][0][0];
            customFilter['endDate'] = filters[key][0][1];

            continue;
        }

        customFilter[key] = filters[key].join(',');
    }

    return customFilter;
};

export const useTable = <T = any> (tableParams: TableParams = {}, setTableParams?: Dispatch<SetStateAction<TableParams>>) => {
    const getPagination = usePagination();

    const [localTableParams, setLocalTableParams] = useState<TableParams>(tableParams);
    const [total, setTotal] = useState<number>(0);

    const handleTableChange = (
        pagination: TableParams['pagination'],
        filters: GenericObject,
        sorter: SorterResult<T> | SorterResult<T>[],
        { currentDataSource }: any
    ) => {
        (setTableParams || setLocalTableParams)({
            pagination: {
                ...pagination,
                total: currentDataSource.length,
            },
            filters: getCleanFilters(filters),
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        } as any);
    };

    useEffect(() => {
        (setTableParams || setLocalTableParams)({
            ...localTableParams,
            pagination: getPagination(total),
        });
    }, [total]);

    return {
        handleTableChange,
        setTotal,
        tableParams: localTableParams,
    };
};
