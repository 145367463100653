import './RequestJobsTable.scss';
import {
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    EditOutlined,
    InfoCircleOutlined,
    InfoCircleTwoTone,
    PlusOutlined,
} from '@ant-design/icons';
import { Button, ButtonProps, Modal, notification, Popconfirm, Popover, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useAccount } from 'lib/Account';
import { useErrorHandler } from 'lib/ErrorHandling';
import { useLocalStorageState } from 'lib/Helpers/LocalStorageHelper';
import { userIsClient } from 'lib/Helpers/UserHelper';
import { useProfessions } from 'lib/hooks/useProfessions';
import { useRequestActions } from 'lib/hooks/useRequestActions';
import { useWorkers } from 'lib/hooks/useWorkers';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { useRequestTab } from 'lib/Request';
import { isJobDateValid } from 'lib/Request/utils';
import moment from 'moment';
import { Dispatch, Key, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { DashedButton } from 'shared/AntDesignUtils/DashedButton';
import { Select } from 'shared/AntDesignUtils/Select/Select';
import { GenericObject } from 'shared/Contracts';
import { FileArchive } from 'shared/icons/FileArchive';
import { InfoCommentIcon } from 'shared/icons/InfoCommentIcon';
import { formatJobs } from 'shared/Requests/helpers';
import { WorkedTimeModal } from 'shared/Requests/Request/WorkedTimeModal';
import { Filters } from 'types/enums/RequestJobsTable';
import { Job, ProviderRequest, Request, Worker } from 'types/models';
import { FormattedJob, ProviderRequestState, RequestState } from 'types/staffing';
import { JobApplicantState, JobApplicantTransition } from 'types/staffing/JobApplicantStateMachine';
import { JobState } from 'types/staffing/JobStateMachine';
import { RequestAcceptanceType } from 'types/staffing/RequestAcceptanceType';
import { RequestJobTableFilters } from './RequestJobTableFilters';
import { WorkerSelectPopup } from './WorkerSelectPopup';

const { confirm } = Modal;

type Props = {
    editProposal: boolean;
    editRequest: boolean;
    jobs: FormattedJob[];
    jobsToCancel: number[];
    jobsToUpdate: FormattedJob[];
    historical: boolean;
    providerRequest: ProviderRequest;
    request: Request;
    setJobs: Dispatch<SetStateAction<FormattedJob[]>>;
    setJobsToCancel: (jobs: number[]) => void;
    setJobToEdit: (job: FormattedJob) => void;
    setRequest: (request: Request) => void;
};

let proposedJobApplicants: GenericObject = {};

enum JOB_TIME_COLORS {
    grey = '#999',
    blue = '#1677ff',
    orange = '#faad13',
}

export const RequestJobsTable = ({
    editProposal,
    editRequest,
    jobs,
    jobsToCancel,
    jobsToUpdate,
    historical,
    setJobs,
    providerRequest,
    request,
    setJobsToCancel,
    setJobToEdit,
    setRequest,
}: Props) => {
    const { locale, t } = useLocalization();
    const handleError = useErrorHandler();
    const { canMoveJobToHistory, jobIsPassed, moveJobsToHistory } = useRequestActions(request, setRequest);
    const { accountUser: user } = useAccount();
    const { getTabInfo } = useRequestTab();
    const { loadWorkers, loadingWorkers, setWorkers, workers } = useWorkers();
    const { loadProfessions, loadingProfessions, professions, setProfessions } = useProfessions();

    const [selectedRows, setSelectedRows] = useState<Key[]>([]);
    const [selectedJobs, setSelectedJobs] = useState<FormattedJob[]>([]);
    const [selectedWorker, setSelectedWorker] = useState<Worker>();
    const [assignableJobs, setAssignableJobs] = useState<FormattedJob[]>([]);
    const [inTransit, setInTransit] = useState<GenericObject>({});
    const [approvingAll, setApprovingAll] = useState(false);
    const [isEditable, setIsEditable] = useState(
        providerRequest.state === ProviderRequestState.ACCEPTED || editProposal
    );
    const [tabInfo, setTabInfo] = useState(getTabInfo(request));
    const [jobToConfirmTime, setJobToConfirmTime] = useState<FormattedJob>();
    const [filteredRequestJobs, setFilteredRequestJobs] = useState<FormattedJob[]>(jobs);
    const [tableFilters, setTableFilters] = useState<string[]>([]);
    const [showWorkedTimes, setShowWorkedTimes] = useLocalStorageState('workedTime', true);
    const [confirmingAll, setConfirmingAll] = useState(false);
    const [showingConfirmAllModal, setShowingConfirmAllModal] = useState(false);
    const [requestHasConfirmedJob, setRequestHasConfirmedJob] = useState(false);

    const isClient = userIsClient(user);

    const reset = () => {
        setSelectedJobs([]);
        setSelectedWorker(undefined);
    };

    const filterRequestJobs = (requestJobTableFilters: string[]) => {
        setTableFilters(requestJobTableFilters);

        let _filteredRequestJobs = jobs;

        if (
            requestJobTableFilters.includes(Filters.I_ASSIGNED) &&
            !requestJobTableFilters.includes(Filters.OPEN_SHIFTS)
        ) {
            const providerId = user.profiles.agent?.provider_id as number;

            _filteredRequestJobs = _filteredRequestJobs.filter(({ applicants }) => {
                return applicants?.some((applicant) => {
                    return applicant.worker.provider.id === providerId;
                });
            });
        }

        if (requestJobTableFilters.includes(Filters.FILLED) && !requestJobTableFilters.includes(Filters.OPEN_SHIFTS)) {
            _filteredRequestJobs = _filteredRequestJobs.filter(({ applicants }) => {
                return (
                    applicants &&
                    applicants?.filter((applicant) => {
                        return applicant.state !== JobApplicantState.REJECTED;
                    }).length > 0
                );
            });
        }

        if (
            requestJobTableFilters.includes(Filters.OPEN_SHIFTS) &&
            !requestJobTableFilters.includes(Filters.I_ASSIGNED) &&
            !requestJobTableFilters.includes(Filters.FILLED)
        ) {
            _filteredRequestJobs = _filteredRequestJobs.filter(({ applicants }) => {
                return (
                    applicants &&
                    applicants?.filter((applicant) => {
                        return applicant.state !== JobApplicantState.REJECTED;
                    }).length === 0
                );
            });
        }

        if (requestJobTableFilters.includes(Filters.WORK_TIME_OPEN)) {
            _filteredRequestJobs = _filteredRequestJobs.filter(
                (requestJob) => jobIsPassed(requestJob) && checkCanSetWorkTime(requestJob) && !!requestJob.workerId && !requestJob.confirmedTime
            );
        }

        if (requestJobTableFilters.includes(Filters.WORK_TIME_PENDING)) {
            _filteredRequestJobs = _filteredRequestJobs.filter(
                (requestJob) => jobIsPassed(requestJob) && !checkCanSetWorkTime(requestJob) && !!requestJob.workerId && !requestJob.confirmedTime
            );
        }

        if (requestJobTableFilters.includes(Filters.WORK_TIME_CONFIRMED)) {
            _filteredRequestJobs = _filteredRequestJobs.filter(({ state }) => state === JobState.TIME_CONFIRMED);
        }

        if (requestJobTableFilters.includes(Filters.WORK_TIME_PENDING_CONFIRMATION)) {
            _filteredRequestJobs = _filteredRequestJobs.filter(({ confirmedTime, state, workerId }) => {
                return isClient
                    ? state === JobState.FILLED && !!confirmedTime
                    : !!confirmedTime && !!workerId && state !== JobState.TIME_CONFIRMED;
            });
        }

        setFilteredRequestJobs(_filteredRequestJobs);
    };

    const renderWorkerSelectPopup = (button: ReactNode, workerJobs: FormattedJob[]) => (
        <WorkerSelectPopup
            jobs={jobs}
            loadingProfessions={loadingProfessions}
            loadingWorkers={loadingWorkers}
            onReset={reset}
            professions={professions}
            selectedJobs={selectedJobs}
            setJobs={setJobs}
            setSelectedWorker={setSelectedWorker}
            workers={workers}
            workerJobs={workerJobs}
            request={request}
        >
            {button}
        </WorkerSelectPopup>
    );

    const renderWorkerNameOrButton = (value: string, job: FormattedJob) => {
        const applicants = job.applicants || [];
        const rejectedApplicants = applicants.filter(({ state }) => state === JobApplicantState.REJECTED);
        const withdrawnApplicants = applicants.filter(({ state }) => state === JobApplicantState.WITHDRAWN);
        const replacedApplicants = applicants.filter(({ state }) => state === JobApplicantState.REPLACED);
        const proposedApplicants = applicants.filter(({ state }) => state === JobApplicantState.PROPOSED);
        const canShowInfoIcon =
            !tabInfo.history.length &&
            !!(rejectedApplicants.length + withdrawnApplicants.length + replacedApplicants.length);

        let workerName = value;

        if (selectedWorker) {
            workerName = `${selectedWorker.first_name} ${selectedWorker.last_name}`;
        }

        const getPopupButton = () => {
            const commonProps: ButtonProps = {
                size: 'small',
                type: 'primary',
                onClick: () => setSelectedJobs([job]),
            };

            if (value) {
                return (
                    <a onClick={commonProps.onClick}>
                        <Tag color="blue">{workerName}</Tag>
                    </a>
                );
            }

            return (
                <DashedButton icon={!workerName ? <PlusOutlined /> : null} {...commonProps}>
                    {workerName || t('Add worker')}
                </DashedButton>
            );
        };

        const renderWorkerTag = () => {
            if (workerName) {
                return (
                    <Tag color={job.applicantState === JobApplicantState.ACCEPTED ? 'green' : 'blue'}>{workerName}</Tag>
                );
            }

            if (isJobDateValid(job) && proposedApplicants.length) {
                const updatingJob = !!jobsToUpdate.find(({ id }) => id === job.id);

                if (proposedApplicants.length === 1) {
                    const applicant = proposedApplicants[0];

                    job.providerName = applicant.worker.provider.company_name;
                    job.applicantId = applicant.id;

                    return (
                        <Tooltip
                            title={
                                <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '8px' }}>
                                    <span>{t('Assigned at')}:</span>
                                    <span>{moment(applicant.created_at).format('DD.MM.YYYY HH:mm')}</span>

                                    {applicant.worker.shirt_size && (
                                        <>
                                            <span>{t('Shirt size')}:</span>
                                            <span>{applicant.worker.shirt_size}</span>
                                        </>
                                    )}
                                    {applicant.worker.trousers_size && (
                                        <>
                                            <span>{t('Trousers size')}:</span>
                                            <span>{applicant.worker.trousers_size}</span>
                                        </>
                                    )}
                                    {applicant.worker.additional_information && (
                                        <>
                                            <span>{t('Additional info')}:</span>
                                            <span>{applicant.worker.additional_information}</span>
                                        </>
                                    )}
                                </div>
                            }
                        >
                            <div>
                                <span>{`${applicant.worker.first_name} ${applicant.worker.last_name}`}</span>
                            </div>
                        </Tooltip>
                    );
                } else if (!editRequest && !updatingJob) {
                    return (
                        <Select
                            className="proposed-applicants-select"
                            disabled={job.state === JobState.CANCELED}
                            dropdownStyle={{ width: '400px' }}
                            onChange={(id: number) => {
                                const applicant = applicants.find((applicant) => applicant.id === id)!;
                                job.providerName = applicant.worker.provider.company_name;
                                job.applicantId = applicant.id;

                                proposedJobApplicants[job.id] = {
                                    applicantId: applicant.id,
                                    providerName: applicant.worker.provider.company_name,
                                };
                            }}
                            options={proposedApplicants.map(({ id, worker }) => ({
                                label: `${worker.first_name} ${worker.last_name}`,
                                value: id,
                                provider: worker.provider.company_name,
                            }))}
                            optionRender={({ data }: any) => (
                                <div style={{ marginTop: '5px' }}>
                                    <div className="ellipsis" style={{ display: 'inline-block', maxWidth: '55%' }}>
                                        {data.label}
                                    </div>

                                    <Tag
                                        className="pull-right ellipsis"
                                        style={{ maxWidth: '40%' }}
                                        title={data.provider}
                                    >
                                        {data.provider}
                                    </Tag>
                                </div>
                            )}
                            placeholder={t('%{smart_count} candidates available', {
                                smart_count: proposedApplicants.length,
                            })}
                            popupMatchSelectWidth={false}
                        />
                    );
                }
            }

            if (job.state === JobState.CANCELED && !!proposedApplicants.length) {
                return (
                    <DashedButton
                        icon={<CloseOutlined />}
                        size="small"
                        style={{ borderStyle: 'dashed', fontSize: '12px' }}
                        disabled
                    >
                        {t('Not assigned')}
                    </DashedButton>
                );
            }

            return (
                <DashedButton
                    icon={job.canAssignWorker || !job.applicants?.length ? <CloseOutlined /> : <CheckOutlined />}
                    size="small"
                    style={{ borderStyle: 'dashed', fontSize: '12px' }}
                    disabled
                >
                    {t(job.canAssignWorker ? 'Not assigned' : 'Assigned')}
                </DashedButton>
            );
        };

        const getPopupContent = () => {
            return (
                <>
                    {!!rejectedApplicants.length && (
                        <>
                            <div className="section-label">{t('Rejected')}:</div>

                            {rejectedApplicants.map(({ worker }) => (
                                <>
                                    <Tag className="red">
                                        {worker.first_name} {worker.last_name}
                                        {isClient && <> | {worker.provider.company_name} </>}
                                    </Tag>
                                    <br />
                                </>
                            ))}
                        </>
                    )}

                    {!!withdrawnApplicants.length && (
                        <>
                            <div className="section-label">{t('Withdrawn')}:</div>

                            {withdrawnApplicants.map(({ previous_state, worker }) => (
                                <>
                                    <Tag className={previous_state === JobApplicantState.ACCEPTED ? 'green' : 'blue'}>
                                        {worker.first_name} {worker.last_name}
                                        {isClient && <> | {worker.provider.company_name} </>}
                                    </Tag>
                                    <br />
                                </>
                            ))}
                        </>
                    )}

                    {!!replacedApplicants.length && (
                        <>
                            <div className="section-label">{t('Replaced')}:</div>

                            {replacedApplicants.map(({ worker, previous_state }) => (
                                <>
                                    <Tag className={previous_state === JobApplicantState.ACCEPTED ? 'green' : 'blue'}>
                                        {worker.first_name} {worker.last_name}
                                        {isClient && <> | {worker.provider.company_name} </>}
                                    </Tag>
                                    <br />
                                </>
                            ))}
                        </>
                    )}
                </>
            );
        };

        const assignWorker =
            (providerRequest.state === ProviderRequestState.ACCEPTED &&
                request.state === RequestState.PENDING &&
                job.canAssignWorker &&
                isJobDateValid(job)) ||
            (editProposal && job.canAssignWorker && isJobDateValid(job));

        const getSpaceContent = () => (
            <>
                {assignWorker ? renderWorkerSelectPopup(getPopupButton(), [job]) : renderWorkerTag()}

                {canShowInfoIcon && (
                    <Popover
                        color="#000"
                        content={getPopupContent()}
                        overlayClassName="requests-previous-job-states-popup"
                        placement="topLeft"
                        style={{ color: '#fff' }}
                        trigger="hover"
                    >
                        <InfoCircleTwoTone twoToneColor="#f3452e" style={{ marginLeft: '10px' }} />
                    </Popover>
                )}
            </>
        );

        return (
            <>
                {getSpaceContent()}

                {!!job.comment && (
                    <Popover
                        color="#000"
                        content={
                            <>
                                <strong>{t('Comment')}:</strong>
                                <p>{job.comment}</p>
                            </>
                        }
                        overlayClassName="request-comment-popup"
                        placement={isClient ? 'bottom' : 'left'}
                    >
                        <div style={{ display: 'inline-block', float: 'right', marginTop: '7px' }}>
                            <InfoCommentIcon />
                        </div>
                    </Popover>
                )}
            </>
        );
    };

    const renderWorkerNameTitle = () => {
        const canAssignMultiple = isEditable && assignableJobs.length >= 2 && selectedRows.length >= 2;

        return (
            <Space size="large">
                {t('Assignee')}

                {canAssignMultiple &&
                    renderWorkerSelectPopup(
                        <Button icon={<PlusOutlined />} type="primary">
                            {t('Assign multiple')}
                        </Button>,
                        selectedJobs
                    )}
            </Space>
        );
    };

    const renderProviderName = (value: string, job: FormattedJob) =>
        (!!value || proposedJobApplicants[job.id]) && (
            <div className="justify-between">
                <Tag className="provider-name">{value || proposedJobApplicants[job.id].providerName}</Tag>
            </div>
        );

    const transitionJobs = async (jobIds: number[], applicantIds: number[], transition: JobApplicantTransition) => {
        try {
            const response = await Backend.put(`/staffing/requests/${request.id}/apply-applicants-transition`, {
                applicant_ids: applicantIds,
                job_ids: jobIds,
                transition,
            });

            if (response.status === Backend.responseStatus.HTTP_OK) {
                setRequest(response.data.request);

                jobIds.forEach((id) => delete proposedJobApplicants[id]);

                notification.success({
                    message: t(
                        transition === JobApplicantTransition.REJECT
                            ? 'Worker successfully rejected'
                            : 'Worker successfully approved'
                    ),
                });
            }
        } catch (e) {
            handleError(e);
        }
    };

    const applyTransitions = async (transition: JobApplicantTransition, job: FormattedJob) => {
        if (inTransit.hasOwnProperty(job.id)) {
            return;
        }

        setInTransit((prevState) => ({
            ...prevState,
            [job.id]: transition,
        }));

        await transitionJobs(
            [job.id],
            [job.applicantId || proposedJobApplicants[job.id].applicantId] as number[],
            transition
        );

        setInTransit((prevState) => {
            const localState = { ...prevState };

            delete localState[job.id];

            return localState;
        });
    };

    const approveSelected = async () => {
        const jobIds = selectedJobs.map(({ id }) => id);
        const applicantIds = selectedJobs
            .map(({ applicants }) => {
                return (applicants || []).find(({ state }) => state === JobApplicantState.PROPOSED)?.id;
            })
            .filter((id) => !!id);

        setApprovingAll(true);
        await transitionJobs(jobIds, applicantIds as number[], JobApplicantTransition.ACCEPT);
        setApprovingAll(false);
        setSelectedRows([]);
    };

    const showConfirmAllWorkedTimesModal = () => {
        setShowingConfirmAllModal(true);

        confirm({
            centered: true,
            content: t('Your providers will be notified and the action can’t be reversed.'),
            icon: null,
            okText: t('Confirm all'),
            open: showingConfirmAllModal,
            onCancel: () => setShowingConfirmAllModal(false),
            onOk: () => confirmSelectedJobWorkedTimes(),
            title: t('Are you sure you want to confirm all worked times?'),
            width: 435,
        });
    };

    const confirmSelectedJobWorkedTimes = async () => {
        setShowingConfirmAllModal(false);
        setConfirmingAll(true);

        try {
            const { data } = await Backend.post('/staffing/jobs/confirm-worked-times', {
                ids: selectedRows,
            });

            setSelectedRows(selectedRows.filter((id) => !data.find((job: Job) => job.id === id)));

            formatJobs(data, true).forEach((job) => setJob(job));

            notification.success({
                message: t('Work times confirmed successfully'),
            });
        } catch (e) {
            handleError(e);
        } finally {
            setConfirmingAll(false);
        }
    };

    const renderCanceledTooltip = (job: FormattedJob) => {
        return (
            <table className="canceled-tooltip-content">
                {job.canceled_by ? (
                    <>
                        <tr>
                            <td className="canceled-tooltip-content-title">{t('Canceled by:')}</td>
                            <td className="canceled-tooltip-content-name">
                                <b>{job.canceled_by.first_name + ' ' + job.canceled_by.last_name}</b>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="canceled-tooltip-content-name">
                                <span>{job.updated_at ? moment(job.updated_at).format('DD.MM.YYYY HH:mm') : ''}</span>
                            </td>
                        </tr>
                    </>
                ) : (
                    <tr>
                        <td className="canceled-tooltip-content-title">
                            <span>{t('Canceled at:')}</span>
                        </td>
                        <td>
                            <span className="canceled-tooltip-content-name">
                                <span>{job.updated_at ? moment(job.updated_at).format('DD.MM.YYYY HH:mm') : ''}</span>
                            </span>
                        </td>
                    </tr>
                )}
            </table>
        );
    };

    const createMoveToHistoryButton = (job: FormattedJob) =>
        !historical &&
        canMoveJobToHistory(job) && (
            <Popconfirm
                icon={false}
                cancelButtonProps={{ size: 'middle' }}
                cancelText={t('No')}
                okButtonProps={{ size: 'middle' }}
                okText={t('Yes')}
                onConfirm={() => moveJobsToHistory([job.id])}
                placement="bottomRight"
                title={t('Are you sure you want to move this shift to History?')}
            >
                <Tooltip placement="bottomRight" title={t('Move to History')}>
                    <Button className="pull-right" style={{ marginLeft: '4px' }} icon={<FileArchive />} />
                </Tooltip>
            </Popconfirm>
        );

    const renderClientAction = (value: any, job: FormattedJob) => {
        const actions = [];

        const cancelButton = () => {
            const addToJobsToCancel = () => {
                jobsToCancel.push(job.id);

                setJobsToCancel(jobsToCancel);
            };

            const getConfirmTitle = () => {
                if ((job.applicants || []).find(({ state }) => state === JobApplicantState.ACCEPTED)) {
                    return 'This candidate is already approved. Canceling this shift might imply consequences based on your agreement with the client. Do you want to continue?';
                }

                return 'Are you sure you want to cancel the shift? This action can not be reversed once done.';
            };

            return (
                <Popconfirm
                    icon={false}
                    okButtonProps={{ size: 'middle' }}
                    okText={t('Cancel')}
                    onConfirm={addToJobsToCancel}
                    overlayClassName="request-jobs-cancel-shift-popup"
                    placement="bottomRight"
                    showCancel={false}
                    title={t(getConfirmTitle())}
                >
                    <Button type="primary" danger>
                        {t('Cancel')}
                    </Button>
                </Popconfirm>
            );
        };

        if (job.state === JobState.CANCELED) {
            actions.push(
                <Tag className="client-shift-action">
                    <Space>
                        {t('Canceled')}
                        <Tooltip
                            placement="bottomRight"
                            overlayStyle={{ maxWidth: '350px' }}
                            title={renderCanceledTooltip(job)}
                        >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Space>
                </Tag>
            );
        } else if (jobsToCancel.includes(job.id)) {
            actions.push(<Tag className="client-shift-action">{t('To be canceled')}</Tag>);
        } else if (jobIsPassed(job) && job.workerId) {
            actions.push(renderWorkedTimeAction(job));
        } else if (editRequest) {
            actions.push(
                <Space>
                    <Button icon={<EditOutlined />} onClick={() => setJobToEdit(job)}>
                        {t('Edit')}
                    </Button>

                    {cancelButton()}
                </Space>
            );
        } else if (request.acceptance_type === RequestAcceptanceType.MANUAL) {
            const proposedApplicants = (job.applicants || []).filter(
                ({ state }) => state === JobApplicantState.PROPOSED
            );
            const acceptedApplicant = (job.applicants || []).find(({ state }) => state === JobApplicantState.ACCEPTED);

            if (isJobDateValid(job) && !acceptedApplicant && proposedApplicants.length) {
                actions.push(
                    <Button
                        disabled={
                            (!job.providerName && !proposedJobApplicants[job.id]) ||
                            inTransit.hasOwnProperty(job.id) ||
                            approvingAll
                        }
                        loading={inTransit[job.id] === JobApplicantTransition.ACCEPT}
                        onClick={() => applyTransitions(JobApplicantTransition.ACCEPT, job)}
                        type="primary"
                    >
                        {t('Approve')}
                    </Button>
                );
            }
        }

        return actions;
    };

    const renderProviderAction = (value: any, job: FormattedJob) => {
        if (jobIsPassed(job) && job.workerId) {
            return renderWorkedTimeAction(job, { marginTop: '7px' });
        }

        if (
            (!job.confirmedTime || !job.workerId) &&
            job.canAssignWorker &&
            job.applicantState !== JobApplicantState.ACCEPTED
        ) {
            return (
                <small style={{ color: '#c8c8c8' }}>
                    {t('%{providers_count} applied', { providers_count: job.other_provider_applicants })}
                </small>
            );
        }

        return (
            <>
                {job.state === JobState.CANCELED && (
                    <Tag className="client-shift-action">
                        <Space>
                            {t('Canceled')}
                            <Tooltip placement="bottomRight" title={renderCanceledTooltip(job)}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Space>
                    </Tag>
                )}
            </>
        );
    };

    const setJob = (job: FormattedJob) =>
        setJobs((prevState) => {
            const index = prevState.findIndex(({ id }) => job.id === id);

            if (index === -1) {
                return prevState;
            }

            const state = [...prevState];

            state.splice(index, 1, {
                ...state[index],
                ...job,
            });

            return state;
        });

    const jobHasProposedState = ({ applicants }: FormattedJob) =>
        !!(applicants || []).find(({ state }) => state === JobApplicantState.PROPOSED);

    const getClientActionTitle = () => {
        return (
            <Space>
                {t('Action')}

                {selectedJobs.length >= 2 && selectedJobs.every(jobHasProposedState) && (
                    <Button loading={approvingAll} type="primary" onClick={approveSelected}>
                        {t('Approve all')}
                    </Button>
                )}

                {selectedJobs.length >= 2 && selectedJobs.every(({ state }) => state === JobState.FILLED) && (
                    <Button
                        icon={<ClockCircleOutlined />}
                        loading={confirmingAll}
                        type="primary"
                        onClick={showConfirmAllWorkedTimesModal}
                    >
                        {t('Confirm all')}
                    </Button>
                )}
            </Space>
        );
    };

    const disableRow = (job: FormattedJob) =>
        editRequest ||
        (isClient &&
            (![JobState.FILLED, JobState.OPEN].includes(job.state as any) ||
                (job.state === JobState.FILLED && !job.confirmedTime) ||
                (job.state === JobState.OPEN && !jobHasProposedState(job)))) ||
        (!isClient && (!job.canAssignWorker || assignableJobs.findIndex(({ id }) => job.id === id) < 0));

    const renderPrevTimes = (job: FormattedJob) => {
        return (
            <Tooltip
                overlayClassName="prev-time-tooltip-overlay"
                title={
                    <div className="prev-time-container">
                        <div className="header">{t('Time changed. Previous time:')}</div>
                        <div className="content">
                            <div className="row">
                                <span className="label">{t('Date')}:</span>
                                <span className="value">{moment(job.data.prevDate).format('DD MMM YYYY')}</span>
                            </div>
                            <div className="row">
                                <span className="label">{t('Time')}:</span>
                                <span className="value">{job.data.prevTime}</span>
                            </div>
                            <div className="row">
                                <span className="label">{t('Break')}:</span>
                                <span className="value">{job.data.prevBreakDuration}</span>
                            </div>
                            <div className="row">
                                <span className="label">{t('Break start time')}:</span>
                                <span className="value">{job.data.prevBreakStartTime}</span>
                            </div>
                        </div>
                    </div>
                }
            >
                <InfoCircleOutlined className="prev-time-icon" />
            </Tooltip>
        );
    };

    const checkCanSetWorkTime = (job: FormattedJob) => {
        const workTimeByProvider = user.connectedOrganizationSettings.find(
            ({ provider_id, client_id }) => provider_id === job.providerId && client_id === request.client_id
        )?.work_time_by_provider;

        return (isClient && !workTimeByProvider) || (!isClient && workTimeByProvider);
    };

    const renderWorkedTimeAction = (job: FormattedJob, style: GenericObject = {}) => {
        if (job.state === JobState.TIME_CONFIRMED) {
            return (
                <Space style={style}>
                    <CheckOutlined style={{ color: JOB_TIME_COLORS.blue }} />

                    {t('Worked hours confirmed')}
                </Space>
            );
        }

        // Can set work time
        if (checkCanSetWorkTime(job)) {
            if (job.confirmedTime && job.state !== JobState.TIME_REJECTED) {
                return (
                    <Space style={style}>
                        <ClockCircleOutlined style={{ color: JOB_TIME_COLORS.orange }} />

                        {t('Pending confirmation')}
                    </Space>
                );
            }

            return (
                <Space>
                    <Button icon={<ClockCircleOutlined />} type="primary" onClick={() => setJobToConfirmTime(job)}>
                        {t(job.state === JobState.TIME_REJECTED ? 'Edit worked time' : 'Add worked time')}
                    </Button>

                    {!!job.worked_time_rejection_comment && job.state === JobState.TIME_REJECTED && (
                        <Popover
                            color="#000"
                            content={
                                <>
                                    <strong>{t('Rejection reason')}:</strong>
                                    <p>{job.worked_time_rejection_comment}</p>
                                </>
                            }
                            overlayClassName="request-comment-popup"
                            placement={'top'}
                        >
                            <div style={{ display: 'inline-block', marginTop: '7px' }}>
                                <InfoCommentIcon />
                            </div>
                        </Popover>
                    )}
                </Space>
            );
        }

        if (!job.confirmedTime || job.state === JobState.TIME_REJECTED) {
            return (
                <Space style={style}>
                    <ClockCircleOutlined style={{ color: JOB_TIME_COLORS.grey }} />

                    <span style={{ color: JOB_TIME_COLORS.grey }}>{t('Pending worked time')}</span>
                </Space>
            );
        }

        return (
            <Button icon={<ClockCircleOutlined />} type="primary" onClick={() => setJobToConfirmTime(job)}>
                {t('Confirm worked time')}
            </Button>
        );
    };

    const groupDates = (value: string, { id }: Job) => {
        const filteredJobs = filteredRequestJobs.filter((i) => i.formattedDate === value);
        const count = filteredJobs.length;
        const index = filteredJobs.findIndex((x) => x.id === id);
        let rowSpan = 1;

        switch (true) {
            case index === 0 && count > 1:
                rowSpan = count;
                break;
            case count > 1:
                rowSpan = 0;
                break;
            default:
                rowSpan = 1;
                break;
        }

        return {
            children: value,
            props: {
                rowSpan: rowSpan,
            },
        };
    };

    const groupWorkerCounts = (value: string, { id }: Job) => {
        const filteredJobs = filteredRequestJobs.filter((i) => i.formattedDate === value);
        const count = filteredJobs.length;
        const index = filteredJobs.findIndex((x) => x.id === id);
        let rowSpan = 1;

        switch (true) {
            case index === 0 && count > 1:
                rowSpan = count;
                break;
            case count > 1:
                rowSpan = 0;
                break;
            default:
                rowSpan = 1;
                break;
        }

        const workerCountsByDate: { [key: string]: number } = {};

        filteredJobs.forEach((job) => {
            const dateKey = job.date;
            workerCountsByDate[dateKey] = (workerCountsByDate[dateKey] || 0) + 1;
        });

        const totalWorkerCount = Object.values(workerCountsByDate).reduce((acc, count) => acc + count, 0);

        const workerText =
            totalWorkerCount > 0 ? `${totalWorkerCount} ${totalWorkerCount === 1 ? t('worker') : t('workers')}` : '';

        return {
            children: workerText,
            props: {
                rowSpan: rowSpan,
            },
        };
    };

    const renderConfirmedValue = (content: any, job: FormattedJob, confirmed: any) => {
        let color = JOB_TIME_COLORS.orange;

        if (job.state === JobState.TIME_CONFIRMED) {
            color = JOB_TIME_COLORS.blue;
        } else if (job.state === JobState.TIME_REJECTED) {
            color = JOB_TIME_COLORS.grey;
        }

        return (
            <>
                {content}
                {job.workerId && !!confirmed && showWorkedTimes && <div style={{ color }}>{confirmed}</div>}
            </>
        );
    };

    const columns: ColumnsType<FormattedJob> = [
        {
            title: t('Date'),
            dataIndex: 'formattedDate',
            key: 'date',
            render: groupDates,
            onCell: () => ({ style: { paddingTop: '20px', verticalAlign: 'top' } }),
        },
        {
            title: t('Total ') + request.jobs.length + ' ' + t(request.jobs.length === 1 ? 'worker' : 'workers'),
            dataIndex: 'formattedDate',
            key: 'workerCount',
            render: groupWorkerCounts,
            onHeaderCell: () => ({ style: { color: '#808080', fontWeight: 400 } }),
            onCell: () => ({ style: { color: '#808080', paddingTop: '20px', verticalAlign: 'top' } }),
        },
        {
            title: t('Time'),
            dataIndex: 'time',
            key: 'time',
            render: (content: any, job: FormattedJob) =>
                renderConfirmedValue(
                    !job.data?.isUpdated ? (
                        content
                    ) : (
                        <>
                            {content} {renderPrevTimes(job)}
                        </>
                    ),
                    job,
                    job.confirmedTime
                ),
        },
        {
            title: t('Duration'),
            dataIndex: 'shiftDuration',
            key: 'shiftDuration',
            render: (content: any, job: FormattedJob) => renderConfirmedValue(content, job, job.confirmedShiftDuration),
        },
        {
            title: t('Break'),
            dataIndex: 'breakDuration',
            key: 'breakDuration',
            render: (content: any, job: FormattedJob) => renderConfirmedValue(content, job, job.confirmedBreakDuration),
        },
        {
            title: t('Break start time'),
            dataIndex: 'breakStartTime',
            key: 'breakStartTime',
            render: (content: any, job: FormattedJob) =>
                renderConfirmedValue(content, job, job.confirmedBreakStartTime),
        },
        {
            title: renderWorkerNameTitle(),
            dataIndex: 'workerName',
            key: 'workerName',
            render: renderWorkerNameOrButton,
            width: 280,
        },
    ];

    if (isClient) {
        columns.push(
            {
                title: t('Provider'),
                dataIndex: 'providerName',
                key: 'providerName',
                render: renderProviderName,
            },
            {
                title: getClientActionTitle,
                dataIndex: '-',
                key: 'action',
                render: (value, job) => (
                    <>
                        <Space>{renderClientAction(value, job)}</Space>

                        {createMoveToHistoryButton(job)}
                    </>
                ),
                width: locale.shortCode.toLowerCase() === 'en' ? 270 : 300,
            }
        );
    } else {
        columns.push({
            dataIndex: '-',
            key: 'action',
            render: (value, job) => (
                <>
                    {renderProviderAction(value, job)}

                    {createMoveToHistoryButton(job)}
                </>
            ),
            width: 300,
        });
    }

    const otherTableProps = useMemo(() => {
        const props: GenericObject = {};

        if (
            (isClient && request.state !== RequestState.EXPIRED) ||
            (!isClient && request.state === RequestState.PENDING && isEditable)
        ) {
            props.rowSelection = {
                getCheckboxProps: (job: FormattedJob) => ({
                    // Column configuration not to be checked
                    disabled: disableRow(job),
                }),
                onChange: setSelectedRows,
                selectedRowKeys: selectedRows,
            };
        }

        return props;
    }, [request.state, selectedRows]);

    useEffect(() => {
        if (isClient) {
            return;
        }

        const _loadProfessions = async () => {
            if (loadingProfessions || professions.length) {
                return;
            }

            setProfessions(await loadProfessions());
        };

        const _loadWorkers = async () => {
            if (loadingWorkers || workers.length) {
                return;
            }

            setWorkers(await loadWorkers());
        };

        _loadProfessions();
        _loadWorkers();
    }, []);

    useEffect(() => {
        setAssignableJobs(jobs.filter((job) => job.canAssignWorker && isJobDateValid(job)));

        setFilteredRequestJobs(jobs);

        setRequestHasConfirmedJob(jobs.filter((job) => !!job.confirmedTime).length > 0);
    }, [jobs]);

    useEffect(() => {
        if (tableFilters.length) {
            filterRequestJobs(tableFilters);

            return;
        }

        if (!jobsToUpdate.length) {
            setFilteredRequestJobs(jobs);

            return;
        }

        const filteredJobs = [...filteredRequestJobs];

        jobsToUpdate.forEach((job) => {
            const index = filteredJobs.findIndex(({ id }) => id === job.id);

            if (index < 0) {
                return;
            }

            filteredJobs.splice(index, 1, job);
        });

        setFilteredRequestJobs(filteredJobs.sort((a: FormattedJob, b: FormattedJob) => a.date.localeCompare(b.date)));
    }, [jobsToUpdate]);

    useEffect(() => {
        setSelectedJobs(jobs.filter((job) => selectedRows.includes(job.key)));
    }, [jobs, selectedRows]);

    useEffect(() => {
        setIsEditable(providerRequest.state === ProviderRequestState.ACCEPTED || editProposal);
    }, [providerRequest.state, editProposal]);

    useEffect(() => {
        setTabInfo(getTabInfo(request));
    }, [request]);

    return (
        <>
            {!editRequest && (
                <RequestJobTableFilters
                    canToggleWorkedTimes={requestHasConfirmedJob}
                    filterRequestJobs={filterRequestJobs}
                    isClient={isClient}
                    showWorkedTimes={showWorkedTimes}
                    setShowWorkedTimes={setShowWorkedTimes}
                />
            )}

            <Table<FormattedJob>
                className="request-jobs-table no-hover"
                rowClassName={(job: FormattedJob) =>
                    `state-${job.state} ${!isClient && disableRow(job) ? 'disabled-row' : ''}`
                }
                rowKey="id"
                columns={columns}
                dataSource={filteredRequestJobs}
                pagination={false}
                scroll={{ scrollToFirstRowOnChange: true, y: 'calc(100vh - 490px)' }}
                size="small"
                {...otherTableProps}
            />

            {!!jobToConfirmTime && (
                <WorkedTimeModal
                    job={jobToConfirmTime}
                    onClose={() => setJobToConfirmTime(undefined)}
                    setJob={setJob}
                    confirm={!checkCanSetWorkTime(jobToConfirmTime)}
                />
            )}
        </>
    );
};
