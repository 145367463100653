import { Button, notification } from 'antd';
import type { AxiosError, AxiosResponse } from 'axios';
import type { CsvWorker, CsvWorkerProps, ParsedCsvWorkersWithTempId, Worker } from 'types/models/Worker';
import type { RcFile } from 'antd/lib/upload/interface';
import 'pages/Provider/Workers/Modals/ImportExcelModal.scss';
import ImportingIcon from 'assets/img/importing.png';
import { locales, useLocalization } from 'lib/Localization';
import { Backend } from 'lib/Http/Backend';
import { extractExcelFileData } from 'lib/Helpers/ExcelHelper';
import { convertPropsToSnakeCase } from 'lib/Helpers/General';
import { LocalizationFunction } from 'lib/Localization/LocalizationContext';
import { FileUploaderModal } from 'shared/FileUploader/Modal';

export type ErrorKey = `${number}.${CsvWorkerProps}`;

export type TemplateErrors = {
    length: number;
    [key: ErrorKey]: string[];
};

type ResponseErrors = {
    errors: TemplateErrors;
};

export type ValidationErrorCallbackProps = {
    parsedWorkers: ParsedCsvWorkersWithTempId;
    templateErrors: TemplateErrors;
};

type ImportExcelModalProps = {
    open: boolean;
    isLoading: boolean;
    okButtonText: string;
    uploadUrl: string;
    onCancel: () => void;
    onStart: () => void;
    onEnd: () => void;
    onSuccess: (response: AxiosResponse) => void;
    onValidationError: (props: ValidationErrorCallbackProps) => void;
    downloadExcelTemplate: () => void;
};

export const ImportExcelModal = ({
    open = false,
    isLoading = false,
    uploadUrl,
    onCancel,
    onSuccess,
    onStart,
    onEnd,
    onValidationError,
    okButtonText,
    downloadExcelTemplate,
}: ImportExcelModalProps) => {
    const { t } = useLocalization();

    const uploadExcelFile = async (file: RcFile) => {
        let parsedWorkers: ParsedCsvWorkersWithTempId = {};

        try {
            let csvWorkers = await extractExcelFileData<CsvWorker>(file);

            onStart();

            csvWorkers = setOriginalKeysForCsv(csvWorkers);

            parsedWorkers = reFormatCsvWorkers(csvWorkers);

            const response = await Backend.post<Worker>(uploadUrl, parsedWorkers);

            onSuccess(response);

            return notification.success({
                message: t('All workers are imported successfully.'),
            });
        } catch (error: unknown) {
            return handleImportError(error, parsedWorkers, onValidationError, t);
        }
    };

    const handleUpload = async (fileList: RcFile[]) => {
        try {
            await uploadExcelFile(fileList[0]);
            onEnd();

            return true;
        } catch {
            onEnd();

            return false;
        }
    };

    const modalFooterImporting = (
        <Button key="submit" type="primary" onClick={onCancel}>
            {t('OK')}
        </Button>
    );

    const renderImporting = (
        <div className="text-center importing-content">
            <img width={140} src={ImportingIcon} />
            <p>{t('Processing...')}</p>
            <p>{t('Completing the import might take a while.')}</p>
            <p>{t('We will notify you as soon as it is ready.')}</p>
            <br />
        </div>
    );

    const renderBeforeDragBox = (
        <div className="text-center download-button-content">
            <p>1. {t('Download the template from below and fill it with your list of workers')}</p>
            <Button key="submit" type="primary" onClick={downloadExcelTemplate}>
                {t('Download Excel template')}
            </Button>
            <p>2. {t('Upload the filled Excel file')}</p>
        </div>
    );

    return (
        <FileUploaderModal
            className="import-excel-modal"
            isLoading={isLoading}
            okButtonText={okButtonText}
            open={open}
            onCancel={onCancel}
            onOk={handleUpload}
            renderBeforeDragBox={() => renderBeforeDragBox}
            renderContent={() => isLoading ? renderImporting : null}
            renderFooter={() => isLoading ? modalFooterImporting : null}
            title={isLoading ? t('Importing') : t('Import Excel')}
        />
    );
};

function handleGenericErrors(error: unknown, t: LocalizationFunction) {
    if (error instanceof Error) {
        return notification.error({
            message: t(error.message),
        });
    }

    return notification.error({
        message: t('Something went wrong.'),
    });
}

function handleImportError(
    error: unknown,
    parsedWorkers: ParsedCsvWorkersWithTempId,
    errorCallback: (props: ValidationErrorCallbackProps) => void,
    t: LocalizationFunction
) {
    if ((error as AxiosError).response?.data) {
        const templateErrors = (error as AxiosError<ResponseErrors>).response?.data?.errors;

        if (templateErrors && Object.keys(templateErrors).length > 0) {
            return errorCallback({
                templateErrors,
                parsedWorkers,
            });
        }
    }

    return handleGenericErrors(error, t);
}

function reFormatCsvWorkers(csvWorkers: CsvWorker[]): ParsedCsvWorkersWithTempId {
    return csvWorkers.reduce((acc, val, index) => {
        const tempId = index;

        return { ...acc, [tempId]: { ...convertPropsToSnakeCase(val), tempId } };
    }, {});
}

function setOriginalKeysForCsv(csvWorkers: any[]) {
    let fixedCsvWorkers: any[] = [];

    csvWorkers.forEach((csvWorker) => {
        Object.keys(csvWorker).forEach((value) => {
            let translatedProperty = findOriginalPropertyByTranslation(value);
            let tempValue = csvWorker[value];
            delete csvWorker[value];
            csvWorker[translatedProperty] = tempValue;
        });
        fixedCsvWorkers.push(csvWorker);
    });

    return fixedCsvWorkers;
}

function findOriginalPropertyByTranslation(translation: string) {
    let original = translation;

    locales.forEach((locale) => {
        if (locale.libraries.polyglot?.phrases) {
            let phrases = locale.libraries.polyglot?.phrases;
            let foundPhrase = Object.keys(phrases).find((key: any) => phrases[key] === translation);
            original = foundPhrase ? foundPhrase : original;
        }
    });

    return original;
}
