import { ConfigProvider } from 'antd';
import { App } from 'App';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'shared/ErrorFallback';
import * as serviceWorker from './serviceWorker';

createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#1677FF',
                    },
                }}
            >
                <App/>
            </ConfigProvider>
        </ErrorBoundary>
    </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
