import { Layout } from 'antd';
import { Request } from 'pages/Provider/Requests/Request';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { LoaderSpin } from 'shared/AntDesignUtils/Loaders';
import { Professions } from 'shared/Configuration/Professions';
import { Organization } from 'shared/Organizations/Organization';
import { Settings } from 'shared/Settings';
import { OrganizationType } from 'types';
import { Addresses } from './Configuration/Addresses';
import { Departments } from './Configuration/Departments';
import { WorkShifts } from './Configuration/WorkShifts';
import { Providers } from './Providers';
import { RequestCreation, Requests } from './Requests';

type Props = {
    setSidebarRequestNumber: Function;
};

/**
 * Render content of the client sub application.
 */
export const Client = ({ setSidebarRequestNumber }: Props) => {
    return (
        <Layout.Content className="content-body mdc-elevation--z2">
            <Suspense fallback={<LoaderSpin />}>
                <Routes>
                    <Route path="requests" element={<Requests setSidebarRequestNumber={setSidebarRequestNumber} />} />
                    <Route path="requests/create" element={<RequestCreation />} />
                    <Route path="requests/:id" element={<Request />} />
                    <Route path="providers" element={<Providers />} />
                    <Route path="configuration/organization" element={<Organization organizationType={OrganizationType.CLIENT} />} />
                    <Route path="configuration/departments" element={<Departments />} />
                    <Route path="configuration/work-shifts" element={<WorkShifts/>} />
                    <Route path="configuration/professions" element={<Professions/>} />
                    <Route path="configuration/locations" element={<Addresses/>} />
                    <Route path="settings" element={<Settings/>} />

                    {/*Fallback empty page - has to be last in routes list*/ }
                    <Route path="/" element={<Requests setSidebarRequestNumber={setSidebarRequestNumber} />} />
                </Routes>
            </Suspense>
        </Layout.Content>
    );
};
