import { useLocalization } from 'lib/Localization';
import './styles.scss';
import { FormattedJob } from 'types/staffing';
import { CustomModal } from 'shared/AntDesignUtils/CustomModal/CustomModal';
import { useEffect, useState } from 'react';
import { Form, notification, Tooltip } from 'antd';
import { TimeRangePicker } from 'shared/AntDesignUtils/TimeRangePicker';
import { Duration } from 'shared/AntDesignUtils/Duration';
import { TimePicker } from 'shared/AntDesignUtils/TimePicker';
import {
    getStartEndTimesInTheMiddleOfInterval,
    getTimeOptionsForTimePicker,
    removeTimeSeconds,
    timeFormat,
} from 'lib/Helpers/DateTimeHelper';
import moment from 'moment/moment';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { formatJobs } from 'shared/Requests/helpers';
import { WorkedInfo } from 'shared/Requests/Request/WorkedTimeModal/WorkedInfo';
import { PromptModal } from 'shared/PromptModal';

type Props = {
    confirm: boolean;
    job?: FormattedJob;
    onClose: () => void;
    setJob: (job: FormattedJob) => void;
};

export const WorkedTimeModal = ({ confirm, job, onClose, setJob }: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();

    const startTime = removeTimeSeconds(job?.start_time || '');
    const endTime = removeTimeSeconds(job?.end_time || '');
    const confirmedStartTime = removeTimeSeconds(job?.confirmed_start_time || '');
    const confirmedEndTime = removeTimeSeconds(job?.confirmed_end_time || '');

    const [timeRangeValue, setTimeRangeValue] = useState({
        times: [confirmedStartTime || startTime, confirmedEndTime || endTime],
        touched: false,
    });
    const [shiftStartTime, shiftEndTime] = timeRangeValue.times;
    const [breakDuration, setBreakDuration] = useState(
        parseInt(job?.confirmedBreakDuration || job?.breakDuration || '0') || undefined
    );
    const [breakStartTime, setBreakStartTime] = useState(job?.confirmedBreakStartTime || job?.breakStartTime);
    const [confirmDecline, setConfirmDecline] = useState(false);
    const [declining, setDeclining] = useState(false);
    const [saving, setSaving] = useState(false);

    const breakStartOptions = getTimeOptionsForTimePicker(
        15,
        shiftStartTime,
        moment(shiftEndTime, timeFormat).subtract(breakDuration, 'minutes').format(timeFormat)
    );

    /*
     * Set the default break start time in the middle of the shift
     */
    const breakStartTimeAdvise = () => {
        if (!breakStartTime && shiftStartTime && shiftEndTime && breakDuration) {
            const [defaultBreakStartTime] = getStartEndTimesInTheMiddleOfInterval(
                shiftStartTime,
                shiftEndTime,
                breakDuration
            );
            setBreakStartTime(defaultBreakStartTime);
        }
    };

    const confirmTime = async () => {
        setSaving(true);

        try {
            const { data } = await Backend.get(`/staffing/jobs/${job?.id}/confirm-worked-time`);

            setJob(formatJobs([data], true)[0]);

            notification.success({
                message: t('Work time confirmed successfully'),
            });

            onClose();
        } catch (e) {
            handleError(e);
        } finally {
            setSaving(false);
        }
    };

    const declineTime = async (comment: string) => {
        if (!comment) {
            return;
        }

        setConfirmDecline(false);
        setDeclining(true);

        try {
            const { data } = await Backend.post(`/staffing/jobs/${job?.id}/decline-worked-time`, { comment });

            setJob(formatJobs([data], true)[0]);

            notification.success({
                message: t('Work time declined successfully'),
            });

            onClose();
        } catch (e) {
            handleError(e);
        } finally {
            setDeclining(false);
        }
    };

    const onCloseHandler = ({ target }: any) => {
        const btn = target.tagName === 'BUTTON' ? target : target.closest('button');

        if (!confirm || btn.classList.contains('ant-modal-close')) {
            onClose();
        } else {
            setConfirmDecline(true);
        }
    };

    const addTime = async () => {
        setSaving(true);

        let breakEndTime = '';

        if (breakStartTime && breakStartTime !== '') {
            breakEndTime = moment(breakStartTime, timeFormat).add(breakDuration, 'minutes').format(timeFormat);
        }

        try {
            const { data } = await Backend.put(`/staffing/jobs/${job?.id}/add-time-worked`, {
                confirmed_start_time: shiftStartTime,
                confirmed_end_time: shiftEndTime,
                confirmed_start_break: breakStartTime,
                confirmed_end_break: breakEndTime,
            });

            setJob(formatJobs([data], true)[0]);

            notification.success({
                message: t('Work time added successfully'),
            });

            onClose();
        } catch (e) {
            handleError(e);
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        if (!job) {
            return;
        }

        const startTime = removeTimeSeconds(job.confirmed_start_time || job.start_time);
        const endTime = removeTimeSeconds(job.confirmed_end_time || job.end_time);

        setTimeRangeValue({
            times: [startTime, endTime],
            touched: false,
        });
        setBreakDuration(parseInt(job.confirmedBreakDuration || job.breakDuration || '0') || undefined);
        setBreakStartTime(job.confirmedBreakStartTime || job.breakStartTime);
    }, [job]);

    return (
        <>
            <CustomModal
                cancelButtonProps={
                    confirm ? { danger: true, disabled: saving, loading: declining, type: 'primary' } : {}
                }
                cancelText={t(confirm ? 'Decline' : 'Cancel')}
                className="worked-time-modal"
                closable={!declining && !saving}
                okButtonProps={{ disabled: declining, loading: saving }}
                okText={t('Confirm')}
                onCancel={onCloseHandler}
                onOk={confirm ? confirmTime : addTime}
                open={!!job}
                title={confirm ? t('Confirm worked time') : t('Add worked time')}
            >
                <table cellPadding={5} cellSpacing={0}>
                    <tbody>
                        <tr>
                            <th className="text-left" colSpan={4}>
                                {t('Shift time')}:
                            </th>
                        </tr>

                        <WorkedInfo
                            breakDuration={job?.breakDuration}
                            breakStartTime={job?.breakStartTime}
                            endTime={endTime}
                            shiftDuration={job?.shiftDuration}
                            startTime={startTime}
                        />

                        {confirm && (
                            <tr>
                                <td colSpan={4}>
                                    <hr />
                                </td>
                            </tr>
                        )}

                        <tr className={confirm ? 'actual' : ''}>
                            <th className="text-left" colSpan={4} style={confirm ? {} : { paddingTop: '20px' }}>
                                {t('Actual time worked')}:
                            </th>
                        </tr>

                        {confirm && (
                            <WorkedInfo
                                breakDuration={job?.confirmedBreakDuration}
                                breakStartTime={job?.confirmedBreakStartTime}
                                endTime={confirmedEndTime}
                                shiftDuration={job?.confirmedShiftDuration}
                                startTime={confirmedStartTime}
                                actual
                            />
                        )}
                        {!confirm && (
                            <tr className="actual">
                                <td colSpan={2}>
                                    <Form.Item
                                        label={t('Shift interval')}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        className="inline-block shift-dropdown-wrapper-input"
                                        rules={[{ required: true, message: t('Start/end times are required') }]}
                                        validateFirst={true}
                                    >
                                        <TimeRangePicker
                                            allowClear={false}
                                            className="shift-duration"
                                            onChange={({ times }) =>
                                                setTimeRangeValue({
                                                    times: times as string[],
                                                    touched: true,
                                                })
                                            }
                                            width={80}
                                            value={timeRangeValue}
                                        />
                                    </Form.Item>
                                </td>
                                <td style={{ paddingTop: '18px' }}>
                                    <Form.Item
                                        label={t('Break')}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 16 }}
                                        className="inline-block break-time"
                                    >
                                        <Duration
                                            className="break-duration"
                                            placeholder={t('Duration')}
                                            handler={(duration) => setBreakDuration(duration || undefined)}
                                            value={`${breakDuration ?? ''}`}
                                        />
                                    </Form.Item>
                                </td>
                                <td>
                                    {!!breakDuration && (
                                        <Tooltip placement="topLeft" title={t('Set break start time')}>
                                            <Form.Item
                                                label={t('Start time')}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                className="break-time"
                                            >
                                                <TimePicker
                                                    className="break-start-time"
                                                    dropdownOptions={breakStartOptions}
                                                    onChange={(time: string) => setBreakStartTime(time)}
                                                    onClear={() => setBreakStartTime('')}
                                                    onIconClick={breakStartTimeAdvise}
                                                    placeholder="hh:mm"
                                                    showIcon={false}
                                                    style={{ width: 80 }}
                                                    value={breakStartTime}
                                                    allowClear
                                                />
                                            </Form.Item>
                                        </Tooltip>
                                    )}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </CustomModal>

            <PromptModal
                label={t('Reason for declining')}
                maxLength={500}
                onCancel={() => setConfirmDecline(false)}
                onOk={declineTime}
                open={confirmDecline}
                danger
                textarea
            />
        </>
    );
};
