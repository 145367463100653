import { Drawer } from 'antd';
import { useAccount } from 'lib/Account';
import { mergeArrays, uniqueArrayItems } from 'lib/Helpers/General';
import { useProfessions } from 'lib/hooks/useProfessions';
import { useWorkers } from 'lib/hooks/useWorkers';
import { useLocalization } from 'lib/Localization';
import { WorkerForm } from 'pages/Provider/Workers/WorkerForm';
import { SelectEventHandler } from 'rc-menu/es/interface';
import { ReactNode, useEffect, useState } from 'react';
import { CustomMenuItemType, GroupedItems, SelectPopup } from 'shared/AntDesignUtils/SelectPopup/SelectPopup';
import { Profession, Request, Worker } from 'types/models';
import { FormattedJob } from 'types/staffing';
import { JobApplicantState } from 'types/staffing/JobApplicantStateMachine';

type Props = {
    children: ReactNode;
    jobs: FormattedJob[];
    loadingProfessions?: boolean;
    loadingWorkers?: boolean;
    onReset: () => void;
    professions?: Profession[];
    request: Request;
    selectedJobs: FormattedJob[];
    setJobs: (jobs: FormattedJob[]) => void;
    setSelectedWorker?: (worker: Worker) => void;
    workerJobs: FormattedJob[];
    workers?: Worker[];
};

export const WorkerSelectPopup = (
    {
        children,
        onReset,
        jobs,
        loadingProfessions,
        loadingWorkers,
        professions = [],
        setJobs,
        selectedJobs,
        setSelectedWorker,
        workerJobs,
        workers = [],
        request
    }: Props
) => {
    const { t } = useLocalization();
    const [rejectedWorkerIds, setRejectedWorkerIds] = useState<number[]>([]);
    const {
        loadWorkers,
        loadingWorkers: loadingLocalWorkers,
        workers: localWorkers,
        setWorkers: setLocalWorkers
    } = useWorkers();
    const {
        professions: localProfessions,
        loadProfessions,
        loadingProfessions: loadingLocalProfessions,
        setProfessions: setLocalProfessions,
        translatedProfessions
    } = useProfessions();

    const { accountUser: user } = useAccount();
    const [isWorkerFormOpen, setIsWorkerFormOpen] = useState(false);
    const [worker, setWorker] = useState<Worker>({} as Worker);

    const providerId = user.profiles.agent?.provider_id as number;

    const workerSavedCallback = (worker: Worker, reset?: boolean) => setLocalWorkers((prevState) => [
        worker,
        ...prevState
    ]);

    const openWorkerHandler = (worker?: Worker) => {
        setWorker({ provider_id: providerId } as Worker);
        setIsWorkerFormOpen(true);
    };

    const createMenuItem = (worker: Worker) => ({
        className: rejectedWorkerIds.includes(worker.id) ? 'rejected' : '',
        disabled: rejectedWorkerIds.includes(worker.id),
        key: worker.id,
        label: `${worker.first_name} ${worker.last_name}`,
    });

    const menuItems: CustomMenuItemType[] = localWorkers
        .filter(({ id }) => !rejectedWorkerIds.includes(id))
        .map(createMenuItem);
    const workersLinkedToProfession = localWorkers
        .filter((worker) => {
            return worker.professions.some((profession) => profession.id === request.profession?.id);
        })
        .map(createMenuItem);
    const rejectedMenuItems: CustomMenuItemType[] = localWorkers
        .filter(({ id }) => rejectedWorkerIds.includes(id))
        .map(createMenuItem);

    const professionName = request.profession?.name;

    const groupedMenuItems: GroupedItems = {
        [t(professionName)]: workersLinkedToProfession,
        [t('All workers')]: menuItems,
        [t('Rejected workers')]: rejectedMenuItems,
    };

    const onRemoveHandler = async () => {
        const resetJob = selectedJobs.map((selectedJob) => {
            return {
                ...selectedJob,
                workerId: undefined,
                workerName: undefined,
            };
        });

        const mergedJobs = mergeArrays(resetJob, jobs);
        setJobs(mergedJobs);

        onReset();
    };

    const onSelectHandler: SelectEventHandler = async ({ key }) => {
        const selectedWorker = localWorkers.find(({ id }) => id === Number(key)) as Worker;
        const assignedJobs = selectedJobs.map((job) => ({
            ...job,
            workerId: selectedWorker.id,
            workerName: `${selectedWorker.first_name} ${selectedWorker.last_name}`,
        }));
        const mergedJobs = mergeArrays(assignedJobs, jobs);

        setSelectedWorker?.(selectedWorker);
        setJobs(mergedJobs);
        onReset();
    };

    const highlightsKeys = workerJobs.length === 1 && workerJobs[0].workerId ? [workerJobs[0].workerId] : [];

    useEffect(() => {
        if (loadingWorkers) {
            return;
        }

        if (!workers.length) {
            loadWorkers();
        } else if (!localWorkers.length) {
            setLocalWorkers(workers);
        }
    }, [loadingWorkers, workers]);

    useEffect(() => {
        if (loadingProfessions) {
            return;
        }

        if (!professions.length) {
            loadProfessions();
        } else if (!localProfessions.length) {
            setLocalProfessions(professions);
        }
    }, [loadingProfessions, professions]);

    useEffect(() => {
        const idGroups = workerJobs.map((job) =>
            (job.applicants || [])
                .filter(({ state }) => state === JobApplicantState.REJECTED)
                .map(({ worker_id }) => worker_id)
        );

        setRejectedWorkerIds(uniqueArrayItems(idGroups.flatMap((a) => a)));
    }, [workerJobs]);

    return (
        <>
            <SelectPopup
                highlightKeys={highlightsKeys}
                items={groupedMenuItems}
                loadingItems={loadingLocalWorkers || loadingWorkers}
                onRemove={onRemoveHandler}
                onSelect={onSelectHandler}
                overlayClassName="worker-select-popup-overlay"
                isWorkerSelect={true}
                openWorkerHandler={openWorkerHandler}
            >
                {children}
            </SelectPopup>

            <Drawer
                title={worker.id ? t('Edit worker') : t('Add Worker')}
                width={500}
                onClose={() => setIsWorkerFormOpen(false)}
                open={isWorkerFormOpen}
            >
                <WorkerForm
                    worker={worker}
                    workerSavedCallback={workerSavedCallback}
                    setIsFormOpen={setIsWorkerFormOpen}
                    translatedProfessions={translatedProfessions}
                />
            </Drawer>
        </>
    );
};
