import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useLoadEntity } from 'lib/hooks/useLoadEntity';
import qs from 'qs';
import { useLocalStorageState } from 'lib/Helpers/LocalStorageHelper';
import { TableParams } from 'types/Table';
import { usePagination } from './usePagination';

type ReturnType<T> = {
    entities: T[];
    loadingEntities: boolean;
    setEntities: Dispatch<SetStateAction<T[]>>;
    setTableParams: Dispatch<SetStateAction<TableParams>>;
    tableParams: TableParams;
};

function useLoadPaginatedEntity<Entity> (
    entityName: string,
    entityUrl: string,
    defaultTableParams?: TableParams,
    onEachEntity?: (entity: Entity) => Entity
): ReturnType<Entity> {
    const getPagination = usePagination(defaultTableParams?.pagination?.defaultPageSize);

    const [entities, loadingEntities, setEntities, loadEntities, meta] = useLoadEntity<Entity>(entityName);
    const [lastVisitedPage, setLastVisitedPage] = useLocalStorageState(entityUrl.replace(/[^a-z0-9]/gi, ''), 1);
    const [tableParams, setTableParams] = useState<TableParams>({
        ...(defaultTableParams || {}),
        pagination: {
            ...getPagination(0),
            current: lastVisitedPage,
        },
    } as TableParams);

    const urlParams = useMemo(
        () => ({
            page: tableParams.pagination?.current || 1,
            limit: tableParams.pagination?.pageSize || 10,
            filters: tableParams.filters,
            sortOrder: tableParams.sortOrder && (tableParams.sortOrder === 'ascend' ? 'asc' : 'desc'),
            sortField: tableParams.sortOrder && tableParams.sortField,
        }),
        [
            tableParams.pagination?.current,
            tableParams.pagination?.pageSize,
            tableParams?.sortOrder,
            tableParams?.sortField,
            JSON.stringify(tableParams.filters),
        ]
    );

    const mappedEntities = useMemo(() => (onEachEntity ? entities.map(onEachEntity) : entities), [entities]);

    useEffect(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...getPagination(meta.total),
                current: tableParams.pagination?.current,
            },
        } as TableParams);
        setLastVisitedPage(tableParams?.pagination?.current);
    }, [entities]);

    useEffect(() => {
        loadEntities(entityUrl + (entityUrl.includes('?') ? '&' : '?') + qs.stringify(urlParams));
    }, [urlParams]);

    return {
        entities: mappedEntities,
        loadingEntities: loadingEntities,
        setEntities,
        setTableParams,
        tableParams,
    };
}

export { useLoadPaginatedEntity };
