import 'shared/AntDesignUtils/SelectPopup/SelectPopup.scss';
import { CloseCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Menu, Popover, Tooltip } from 'antd';
import { MenuItemType } from 'antd/es/menu/interface';
import { debounce } from 'lib/Helpers/General';
import { useLocalization } from 'lib/Localization';
import { SelectEventHandler } from 'rc-menu/es/interface';
import { ReactNode, useEffect, useState } from 'react';
import { LoaderSpin } from '../Loaders';

export interface CustomMenuItemType extends MenuItemType {
    className?: string;
}

export type GroupedItems = Record<string, CustomMenuItemType[]>;

type Props = {
    children: ReactNode;
    highlightKeys?: string[] | number[];
    items: CustomMenuItemType[] | GroupedItems;
    loadingItems?: boolean;
    onOpenChange?: (open: boolean) => void;
    onRemove?: () => void;
    onSelect: SelectEventHandler;
    overlayClassName?: string;
    isWorkerSelect?: boolean;
    openWorkerHandler?: () => void;
};

const isGroupedItem = (items: CustomMenuItemType[] | GroupedItems): items is GroupedItems => !Array.isArray(items);

export const SelectPopup = ({
    children,
    highlightKeys = [],
    loadingItems,
    items,
    onOpenChange,
    onRemove,
    onSelect,
    overlayClassName,
    isWorkerSelect,
    openWorkerHandler,
}: Props) => {
    const { t } = useLocalization();
    const [filteredItems, setFilteredItems] = useState(items);
    const [query, setQuery] = useState('');
    const [open, setOpen] = useState(false);

    const filterItems = (items: CustomMenuItemType[]) =>
        items.filter(({ label }) => (label as string)!.toLowerCase().includes(query.toLowerCase()));

    const onOpenChangeHandler = (open: boolean) => {
        setOpen(open);
        onOpenChange?.(open);
    };

    const onRemoveButtonClickHandler = () => {
        setOpen(false);
        onRemove?.();
    };

    const onSelectHandler: SelectEventHandler = (info) => {
        setOpen(false);
        onSelect?.(info);
    };

    useEffect(() => {
        if (isGroupedItem(items)) {
            setFilteredItems(() => {
                const filtered: GroupedItems = {};

                for (const key in items) {
                    filtered[key] = filterItems(items[key]);
                }

                return filtered;
            });
        } else {
            setFilteredItems(filterItems(items));
        }
    }, [items, query]);

    const renderItem = (item: CustomMenuItemType) => {
        const $item = { ...item };

        if (highlightKeys?.includes($item.key as never)) {
            $item.label = (
                <div className={item.className}>
                    {$item.label}

                    <Button
                        className="pull-right"
                        icon={<CloseCircleOutlined />}
                        onClick={onRemoveButtonClickHandler}
                        shape="circle"
                        type="link"
                        danger
                    />
                </div>
            );
        }

        return $item;
    };

    const renderMenu = (items: CustomMenuItemType[], key = 'menu') => (
        <Menu
            defaultSelectedKeys={highlightKeys.map((key) => key.toString())}
            items={items.map(renderItem)}
            key={key}
            mode="inline"
            onSelect={onSelectHandler}
            selectable
        />
    );

    const renderGroupedMenu = () => {
        const groupedMenu: ReactNode[] = [];

        for (const header in filteredItems) {
            const groupItems = (filteredItems as GroupedItems)[header];

            if (!groupItems.length) {
                continue;
            }

            groupedMenu.push(
                <div key={header} className="grouped-menu-header">
                    {header}
                </div>
            );
            groupedMenu.push(renderMenu(groupItems, `${header} menu`));
        }

        return groupedMenu;
    };

    const renderDropdown = () => {
        return (
            <>
                <div className="select-popup-search-wrapper">
                    <Input
                        disabled={loadingItems}
                        type="search"
                        placeholder={t('Search')}
                        suffix={<SearchOutlined />}
                        onChange={debounce(({ target }) => setQuery(target.value))}
                        allowClear
                        style={{
                            width: isWorkerSelect ? 'calc(100% - 49px)' : '100%',
                            verticalAlign: 'top',
                        }}
                    />
                    {isWorkerSelect && (
                        <Tooltip title={t('Add worker')}>
                            <Button
                                icon={<PlusOutlined />}
                                size={'large'}
                                style={{ marginLeft: '5px', height: '44px', width: '44px', borderRadius: '6px' }}
                                onClick={() => {
                                    setOpen(false);
                                    if (openWorkerHandler) {
                                        openWorkerHandler();
                                    }
                                }}
                            />
                        </Tooltip>
                    )}
                </div>

                <Divider />

                {loadingItems && (
                    <div className="loader-spin">
                        <LoaderSpin inline />
                    </div>
                )}

                <div className="scroll-area">
                    {isGroupedItem(items) ? renderGroupedMenu() : renderMenu(filteredItems as CustomMenuItemType[])}
                </div>
            </>
        );
    };

    return (
        <Popover
            content={renderDropdown()}
            onOpenChange={onOpenChangeHandler}
            open={open}
            overlayClassName={`custom-select-popup ${overlayClassName || ''}`}
            placement="bottomLeft"
            trigger="click"
            destroyTooltipOnHide
        >
            {children}
        </Popover>
    );
};
