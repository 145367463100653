import { Form, FormRule, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
import { PasswordConditions } from 'shared/AntDesignUtils/Password/PasswordConditions';

type Props = {
    label: string;
    name: string;
    required?: boolean;
    rules?: FormRule[];
    showConditions?: boolean;

    [index: string]: any;
}

export const PasswordFormItem = ({
    label,
    name,
    required = false,
    rules = [],
    showConditions = false,
    ...otherProps
}: Props) => {
    const [password, setPassword] = useState('');
    const [help, setHelp] = useState<JSX.Element | null>();

    const conditions = useRef<{ validate: (password: string) => boolean }>();

    const localRules = [...rules];

    if (showConditions) {
        localRules.push({
            validator(rule, value) {
                return new Promise((resolve, reject) => {
                    setPassword(value || '');

                    if (conditions.current?.validate(value)) {
                        resolve(true);
                    } else {
                        reject();
                    }
                });
            }
        });
    }

    useEffect(() => {
        setHelp((showConditions && (
            <PasswordConditions
                customRef={ conditions }
                password={ password }
            />
        )) || null);
    }, [password]);

    return (
        <>
            <Form.Item
                help={ help }
                name={ name }
                rules={ localRules }
                hasFeedback
                { ...otherProps }
            >
                <FloatingLabel label={ label } required={required}>
                    <Input.Password />
                </FloatingLabel>
            </Form.Item>
        </>
    );
};
