import './styles.scss';
import { OrderFormRequest } from 'pages/Client/Requests/Create/RequestCreation';
import { Document } from 'types/models';
import { Documents } from 'shared/Documents';
import { useErrorHandler } from 'lib/ErrorHandling';

type Props = {
    disabled?: boolean;
    formData: OrderFormRequest;
    saveAsDraft: (goBack: boolean, clearRequest: boolean) => Promise<any>;
    setFormData: (formData: OrderFormRequest) => void;
};

export const RequestDocuments = ({ disabled, formData, saveAsDraft, setFormData }: Props) => {
    const handleError = useErrorHandler();

    const getFormDataId = async (_: Document[]) => {
        if (!formData.id) {
            try {
                const newOrder = await saveAsDraft(false, false);

                formData = newOrder.requests[newOrder.current - 1];
            } catch (error) {
                handleError(error);
            }
        }

        return formData?.id;
    };
    const removedDocuments = (_: Document, documents: Document[]) => updateFormDataDocs(documents);
    const savedDocuments = (_: Document[], documents: Document[]) => setFormData({
        ...formData,
        documents,
    });
    const updateFormDataDocs = (documents: Document[]) =>
        !disabled &&
        setFormData({
            ...formData,
            documents,
        });

    return (
        <Documents
            disabled={disabled}
            documents={formData.documents}
            getRelationId={getFormDataId}
            onRemoved={removedDocuments}
            onSaved={savedDocuments}
            relation="requests"
        />
    );
};
