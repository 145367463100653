import {
    CaretDownOutlined,
    CaretRightOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    CloseCircleTwoTone,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    MoreOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { Badge, Button, Dropdown, Modal, notification, Space, Table } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import dayjs from 'dayjs';
import { useAccount } from 'lib/Account';
import { useErrorHandler } from 'lib/ErrorHandling';
import { maxDateOfDates, minDateOfDates } from 'lib/Helpers/DateTimeHelper';
import { uniqueArrayItems } from 'lib/Helpers/General';
import { localizeProfession } from 'lib/Helpers/ProfessionHelper';
import { userIsProvider } from 'lib/Helpers/UserHelper';
import { useLoadPaginatedEntity } from 'lib/hooks/useLoadPaginatedEntity';
import { useRequestActions } from 'lib/hooks/useRequestActions';
import { useRequestBackend } from 'lib/hooks/useRequestBackend';
import { useTableFilterHelper } from 'lib/hooks/useTableFilterHelper';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { downloadExcel, useRequestTab } from 'lib/Request';
import { ProviderRequestActionButtons } from 'pages/Provider/Requests/Request/ProviderRequestActionButtons';
import { forwardRef, MouseEvent, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { FileIcon, OpenNotifIcon } from 'shared/icons';
import { FileArchive } from 'shared/icons/FileArchive';
import { OneOrMore } from 'shared/OneOrMore/OneOrMore';
import { formatJobs } from 'shared/Requests/helpers';
import { ProgressModal } from 'shared/Requests/ProgressModal';
import { RequestProgress } from 'shared/Requests/RequestsTable/RequestProgress';
import { OrganizationType } from 'types';
import { Job, Order, Request } from 'types/models';
import { ProviderRequestTransition, RequestState } from 'types/staffing';
import { JobState } from 'types/staffing/JobStateMachine';
import { OrganizationNames } from '../OrganizationNames';
import { AvailableTabs } from '../Requests';
import { useTable } from 'lib/hooks/useTable';

type Props = {
    actionColumnWidth?: string | number;
    activeTab: AvailableTabs;
    addToTab: (order: Order, tab: AvailableTabs) => void;
    collection: {
        clientOptions: { value: number; label: string; }[];
        creatorOptions: { value: number; label: string; }[];
        departmentOptions: { value: number; label: string; }[];
    };
    onAccept?: () => void;
    organizationType: OrganizationType;
    showProgress?: boolean;
};

interface DataSource {
    id: number;
    client: JSX.Element | string;
    createdAt: string;
    createdBy: string;
    children?: DataSource[];
    key: string;
    location: JSX.Element | string;
    partOfOrder: boolean;
    profession: JSX.Element | string;
    progress: JSX.Element;
    request: Request | null;
    startEnd: string;
    state: string;
    externalId: string;
}

export const sumRequestValues = (order: Order, getValue: (request: Request) => number) =>
    order.requests.reduce((total, request) => total + getValue(request), 0);

export const RequestTable = forwardRef(({
    actionColumnWidth,
    activeTab,
    addToTab,
    collection,
    onAccept,
    organizationType,
    showProgress,
}: Props, ref) => {
    const { accountUser: user } = useAccount();
    const localeContext = useLocalization();
    const { t, locale } = localeContext;
    const navigate = useNavigate();
    const handleError = useErrorHandler();
    const { canDeleteRequest, canMoveToHistory, getMovablePastJobs, moveJobsToHistory } = useRequestActions();
    const { getUpdatedOrder } = useRequestTab();
    const { cancelOrder, cancelRequest, deleteOrder, deleteRequest } = useRequestBackend();
    const {
        entities: orders,
        loadingEntities: isLoadingOrders,
        setEntities: setOrders,
        setTableParams,
        tableParams,
    } = useLoadPaginatedEntity<Order>(
        'orders',
        `/staffing/orders?requestType=${ activeTab }`,
        {
            sortOrder: 'descend',
            sortField: 'createdAt',
        },
        getUpdatedOrder
    );
    const { handleTableChange } = useTable(tableParams, setTableParams);

    const isProvider = userIsProvider(user);
    const [previewOrder, setPreviewOrder] = useState<Order | undefined>(undefined);
    const [previewRequestId, setPreviewRequestId] = useState<number | undefined>(undefined);
    const [dataSource, setDataSource] = useState<DataSource[]>([]);
    const [responding, setResponding] = useState<number[]>([]);

    const { getColumnDatePickerProps, getColumnDateRangePickerProps, getColumnSearchProps, setFilters } =
        useTableFilterHelper();

    useImperativeHandle(ref, () => ({
        addOrder: (order: Order) => setOrders((prevState: Order[]) => {
            const state = [...prevState];
            const index = state.findIndex(({ id }) => id === order.id);

            if (index === -1) {
                state.unshift(order);

                return state;
            }

            const requests = state[index].requests;

            order.requests.forEach((request) => {
                const index = requests.findIndex(({ id }) => id === request.id);

                if (index === -1) {
                    requests.unshift(request);

                    return;
                }

                requests.splice(index, 1, {
                    ...requests[index],
                    ...request,
                });
            });

            return state;
        })
    }));

    const getDataSource = useCallback(
        () =>
            orders.map((order) => {
                const singleRequest = order.auto_created ? order.requests[0] : null;

                const requestHasConfirmableJobs = ({ jobs }: Request) =>
                    jobs.some((job) => {
                        const formattedJob = formatJobs([job], !isProvider)[0];

                        return (
                            !!formattedJob.confirmedTime &&
                            ((isProvider && formattedJob.state === JobState.TIME_REJECTED) ||
                                (!isProvider && formattedJob.state === JobState.FILLED))
                        );
                    });

                const getProfession = (request: Request | null, partOfOrder: boolean) => {
                    if (request && !request.profession) {
                        return '';
                    }

                    const clockIcon = <ClockCircleOutlined style={ { color: '#faad13', marginTop: '7px' } } />;

                    return request ? (
                        <Space>
                            <a href="#" onClick={ (e) => e.preventDefault() }>
                                { partOfOrder && (
                                    <FileIcon color="#0096E1" width={ 13 } height={ 13 } style={ { marginRight: '20px' } } />
                                ) }
                                <span style={ { fontWeight: singleRequest ? 600 : 400 } }>
                                    { localizeProfession(request.profession, locale).name }
                                </span>
                            </a>

                            { requestHasConfirmableJobs(request) && clockIcon }
                        </Space>
                    ) : (
                        <Space>
                            <span
                                className="request-table-name ellipsis-3-lines"
                                style={ {
                                    fontWeight: 600,
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                } }
                            >
                                { order.name }
                            </span>

                            { !isProvider && activeTab === AvailableTabs.DRAFT && (
                                <OpenNotifIcon
                                    style={ { cursor: 'pointer' } }
                                    onClick={ (e: any) => {
                                        e.stopPropagation();

                                        goToCreatePage(order.id);
                                    } }
                                />
                            ) }

                            { order.requests.some((request) => requestHasConfirmableJobs(request)) && clockIcon }
                        </Space>
                    );
                };

                const createSource = (request: Request | null, partOfOrder = false): DataSource => {
                    let key = `order-${ order.id }`;

                    if (request) {
                        key += `-request-${ request.id }`;
                    }

                    const externalId = order.external_id || '';

                    let requestState = request?.state ?? '';

                    if (isProvider) {
                        let providerRequest = request?.provider_requests.find(
                            (provider_request) => provider_request.provider_id === user.profiles.agent?.provider_id
                        );

                        if (
                            [RequestState.POSTED, RequestState.PENDING].includes(request?.state as RequestState) &&
                            providerRequest
                        ) {
                            requestState = providerRequest.state;
                        }
                    }

                    let requestStartEnd = '';

                    if (request) {
                        const minCalculatedShiftDates = calculateShiftDates(request.jobs, minDateOfDates);
                        const maxCalculatedShiftDates = calculateShiftDates(request.jobs, maxDateOfDates);

                        requestStartEnd =
                            minCalculatedShiftDates === maxCalculatedShiftDates
                                ? minCalculatedShiftDates
                                : minCalculatedShiftDates + ' - ' + maxCalculatedShiftDates;
                    }

                    return {
                        id: order.id,
                        key,
                        client: isProvider ? (
                            <OrganizationNames
                                request={ (request || order.requests[0]) as Request }
                                organizationType={ organizationType }
                            />
                        ) : (
                            ''
                        ),
                        createdAt: toDateString(new Date(order.created_at)),
                        createdBy:
                            (request || order).created_by.first_name + ' ' + (request || order).created_by.last_name,
                        location: request?.address?.address ?? getOrderAddressString(order),
                        partOfOrder,
                        profession: getProfession(request, partOfOrder),
                        progress: <RequestProgress order={ order } request={ request } />,
                        request,
                        startEnd: requestStartEnd,
                        state: requestState,
                        externalId,
                    };
                };

                const source = createSource(singleRequest);

                if (!singleRequest) {
                    let orderJobs: any[] = [];

                    order.requests.forEach((request) => {
                        orderJobs.push(...request.jobs);
                    });

                    let sourceStartEnd = '';
                    const minOrderJobsCalculatedShiftDates = calculateShiftDates(orderJobs, minDateOfDates);
                    const maxOrderJobsCalculatedShiftDates = calculateShiftDates(orderJobs, maxDateOfDates);

                    if (orderJobs.length) {
                        sourceStartEnd =
                            minOrderJobsCalculatedShiftDates === maxOrderJobsCalculatedShiftDates
                                ? minOrderJobsCalculatedShiftDates
                                : minOrderJobsCalculatedShiftDates + ' - ' + maxOrderJobsCalculatedShiftDates;
                    }

                    source.startEnd = sourceStartEnd;
                    source.children = order.requests.map((request) => createSource(request, true));
                }

                return source;
            }),
        [orders]
    );

    useEffect(() => {
        document.querySelectorAll('.request-table-name').forEach((element) => {
            let htmlElement = element as HTMLElement;

            switch (true) {
                case htmlElement.offsetHeight > 22 && htmlElement.offsetHeight <= 44:
                    htmlElement.classList.remove('request-table-name');

                    htmlElement.style.position = 'absolute';
                    htmlElement.style.top = '8px';
                    htmlElement.style.maxWidth = '90%';

                    if (htmlElement.parentElement) {
                        htmlElement.parentElement.style.width = htmlElement.offsetWidth + 'px';
                    }

                    break;
                case htmlElement.offsetHeight > 44:
                    htmlElement.classList.remove('request-table-name');

                    htmlElement.style.position = 'absolute';
                    htmlElement.style.top = '6px';
                    htmlElement.style.maxWidth = '90%';
                    htmlElement.style.lineHeight = '15px';

                    if (htmlElement.parentElement) {
                        htmlElement.parentElement.style.width = htmlElement.offsetWidth + 'px';
                    }

                    break;
                default:
                    break;
            }
        });
    });

    useEffect(() => {
        setDataSource(getDataSource());
    }, [orders]);

    const updateOrder = (order: Order) =>
        setOrders((prevState) => {
            const localState = [...prevState];
            const orderIndex = localState.findIndex(({ id }) => id === order.id);

            if (orderIndex > -1) {
                localState.splice(orderIndex, 1, order);
            }

            return localState;
        });

    const removeOrder = (order: Order) =>
        setOrders((prevState) => {
            const localState = [...prevState];
            const orderIndex = localState.findIndex(({ id }) => id === order.id);

            if (orderIndex > -1) {
                localState.splice(orderIndex, 1);
            }

            return localState;
        });

    const getOrderAddressString = (order: Order) => {
        const orderAddresses = uniqueArrayItems(
            order.requests.map((request) => {
                return request.address?.address;
            })
        );

        return <OneOrMore items={ orderAddresses } blockMore />;
    };

    const goToCreatePage = (orderId: number, addRequest: boolean = false, requestId?: number) => {
        let url = `/client/requests/create?order=${ orderId }`;

        if (requestId) {
            url += `&request=${ requestId }`;
        }

        if (addRequest) {
            url += '&addRequest';
        }

        navigate(url);
    };

    const goToRequestPage = (requestId: number, edit: boolean = false) => {
        let url = `/${ organizationType }/requests/${ requestId }?`;

        if (activeTab === AvailableTabs.HISTORY) {
            url += 'history&';
        }

        if (edit) {
            url += 'edit&';
        }

        navigate(url);
    };

    const handleDeleteOrder = async (record: DataSource) => {
        if (!(await deleteOrder(record as unknown as Order))) {
            return;
        }

        const order = orders.find((order) => order.id === record.id);

        if (!order) {
            return;
        }

        removeOrder(order);

        notification.success({
            message: t('Order successfully deleted'),
        });
    };

    const handleDeleteRequest = async (record: DataSource) => {
        if (!(await deleteRequest(record.request as Request))) {
            return;
        }

        const order = orders.find((order) => order.id === record.id);

        if (!order) {
            return;
        }

        if (order.requests.length === 1) {
            removeOrder(order);

            notification.success({
                message: t('Request successfully deleted'),
            });

            return;
        }

        const requests = [...order.requests];

        const index = requests.findIndex(({ id }) => id === record.request!.id);

        if (index > -1) {
            requests.splice(index, 1);
        }

        updateOrder({
            ...order,
            requests
        });

        notification.success({
            message: t('Request successfully deleted'),
        });
    };

    const moveOrderToHistory = async (record: DataSource) => {
        const jobIds = record.children?.map(({ request }) => request?.jobs?.map(({ id }) => id)).flat();

        if (!moveJobsToHistory(jobIds as number[])) {
            return;
        }

        const order = orders.find((order) => order.id === record.id);

        if (!order) {
            return;
        }

        removeOrder(order);
    };

    const providerFilterOptions = collection.clientOptions || [];

    const toDateString = (date: Date, format = 'DD.MM.YYYY') =>
        dayjs(date, { locale: locale.shortCode }).format(format);

    const calculateShiftDates = (
        jobs: Job[],
        DateReducer: (dates: Date[]) => Date,
        format: string = 'ddd DD.MM'
    ): string => {
        const dates = jobs.map((job) => new Date(job.date)) as Date[];

        return dates.length > 0 ? toDateString(DateReducer(dates), format) : '';
    };

    const moveRecordJobsToHistory = async (record: DataSource) => {
        const pastJobs = getMovablePastJobs(record.request as Request);
        const response = await moveJobsToHistory(pastJobs.map(({ id }) => id));

        if (!response) {
            return;
        }

        const localOrders = [...orders];

        const index = localOrders.findIndex(({ id }) => id === record.id);

        if (index === -1) {
            return;
        }

        if (localOrders[index].requests.length === 1) {
            localOrders.splice(index, 1);
        } else {
            const requestIndex = localOrders[index].requests.findIndex(({ id }) => record.request?.id === id);

            if (index === -1) {
                return;
            }

            localOrders[index].requests.splice(requestIndex, 1);
        }

        setOrders(localOrders);
    };

    const renderProviderActionButton = (record: DataSource) => {
        const hasOpenJobs = record.request?.jobs?.some(({ state }) => state === JobState.OPEN);

        const hasNoPastJobs = (request: Request) => getMovablePastJobs(request).length === 0;

        const isRequestAvailable = hasOpenJobs && hasNoPastJobs(record.request as Request);
        const isOrder = !record.request;

        if (
            activeTab === AvailableTabs.ACTIVE ||
            (activeTab === AvailableTabs.PENDING && !isRequestAvailable && !isOrder)
        ) {
            return renderActionColumn(record.key, [
                {
                    icon: <FileArchive />,
                    key: `${ record.key }-move-to-history`,
                    label: t('Move to History'),
                    onClick ({ domEvent }: any) {
                        domEvent.stopPropagation();

                        Modal.confirm({
                            title: null,
                            icon: null,
                            content: (
                                <>
                                    <p>{ t('Are you sure you want to move this request to History?') }</p>
                                    <p style={ { color: 'darkgrey' } }>{ t('Only finished shifts will be affected.') }</p>
                                </>
                            ),
                            okText: t('Yes'),
                            cancelText: t('No'),
                            centered: true,
                            onOk: () => moveRecordJobsToHistory(record),
                        });
                    },
                },
            ]);
        }

        const updateRequests = (requests: Request[]) => {
            const order = orders.find(({ id }) => id === record.id);

            if (!order) {
                return;
            }

            const orderRequests = structuredClone(order.requests);

            requests.forEach((request) => {
                const requestIndex = orderRequests.findIndex(({ id }) => id === request.id);

                if (requestIndex === -1) {
                    return;
                }

                orderRequests.splice(requestIndex, 1, request);
            });

            updateOrder({
                ...order,
                requests: orderRequests,
            });
        };

        if (!record.request) {
            const order = orders.find(({ id }) => id === record.id);

            if (!order) {
                return;
            }

            const orderHasOpenJobRequests = order.requests?.some((request) =>
                request.jobs?.some(({ state }) => state !== JobState.CANCELED)
            );
            const orderHasNonPastJobRequests = order.requests?.some(
                (request) => getMovablePastJobs(request).length !== request.jobs.length
            );

            if (!responding.includes(record.id) && orderHasOpenJobRequests && orderHasNonPastJobRequests) {
                const providerRequestsURL = `/staffing/providerRequests/applyTransitionToMany`;

                //@ts-ignore
                const providerRequestIds = order.requests.reduce((carry, request) => {
                    const requestProviderIds = request.provider_requests.map(({ id }) => id);

                    return [...carry, ...requestProviderIds];
                }, []);

                const respond = async (
                    { domEvent }: any,
                    transition: ProviderRequestTransition,
                    callback: () => void
                ) => {
                    setResponding((prevState) => [...prevState, record.id]);

                    try {
                        domEvent.stopPropagation();

                        const response = await Backend.put(`${ providerRequestsURL }/${ transition }`, {
                            ids: providerRequestIds,
                        });

                        if (response.status === Backend.responseStatus.HTTP_OK) {
                            updateRequests(response.data.requests);

                            callback();
                        }
                    } catch (error) {
                        console.error(error);
                        handleError(error);
                    } finally {
                        setResponding((prevState) => {
                            const index = prevState.findIndex((id) => id === record.id);

                            if (index === -1) {
                                return prevState;
                            }

                            const newState = [...prevState];

                            newState.splice(index, 1);

                            return newState;
                        });
                    }
                };

                const items: ItemType[] = [
                    {
                        icon: <CheckCircleOutlined />,
                        key: `${ record.key }-accept-all`,
                        label: t('Accept all'),
                        onClick: (e: any) =>
                            respond(e, ProviderRequestTransition.ACCEPT, () => {
                                notification.success({
                                    message: t('Order successfully accepted'),
                                });

                                onAccept?.();
                            }),
                    },
                    {
                        icon: <CloseCircleTwoTone twoToneColor="red" />,
                        key: `${ record.key }-reject-all`,
                        label: t('Reject all'),
                        onClick: (e: any) => {
                            e.domEvent.stopPropagation();

                            Modal.confirm({
                                title: t('Reject order'),
                                icon: null,
                                content: (
                                    <>
                                        <p>{ t('Are you sure you want to reject the entire order?') }</p>
                                        <p>{ t('The action cannot be reversed once done.') }</p>
                                    </>
                                ),
                                okText: t('Reject order'),
                                cancelText: t('Cancel'),
                                centered: true,
                                onOk: () =>
                                    respond(e, ProviderRequestTransition.REJECT, () => {
                                        notification.success({
                                            message: t('Order successfully rejected'),
                                        });
                                    }),
                            });
                        },
                    },
                ];

                return renderDropdownMenu(record.key, items);
            }

            return;
        }

        const { provider_requests } = record.request;
        const providerRequest = provider_requests[0];

        const getPossibleTransitions = () => providerRequest?.possibleTransitions as ProviderRequestTransition[];

        return (
            <ProviderRequestActionButtons
                onAccept={ onAccept }
                possibleTransitions={ getPossibleTransitions() }
                providerRequest={ providerRequest }
                request={ record.request }
                isIconButton={ true }
                updateRequest={ (request) => updateRequests([request]) }
                hideProgress
            />
        );
    };

    const renderClientActionButton = (record: DataSource) => {
        const actions = {
            addNewRequest: {
                icon: <PlusOutlined />,
                key: `${ record.key }-add-request`,
                label: t('Add new request'),
                onClick ({ domEvent }: any) {
                    domEvent.stopPropagation();

                    goToCreatePage(record.id, true);
                },
            },
            cancelOrder: {
                icon: <CloseCircleOutlined color="red" />,
                key: `${ record.key }-cancel-order`,
                label: t('Cancel order'),
                onClick ({ domEvent }: any) {
                    domEvent.stopPropagation();

                    const title = t('Cancel order');

                    Modal.confirm({
                        title,
                        icon: null,
                        content: (
                            <>
                                <p>{ t(`Are you sure you want to cancel this order?`) }</p>
                                <p>{ t('Action cannot be reversed once done.') }</p>
                            </>
                        ),
                        okText: title,
                        cancelText: t('Dismiss'),
                        centered: true,
                        onOk: async () => {
                            const order = orders.find(
                                ({ id }) => id === (record.request ? record.request.order_id : record.id)
                            );

                            if (!order || !(await cancelOrder(order))) {
                                return;
                            }

                            addToTab({
                                ...order,
                                requests: order.requests.map((request) => ({
                                    ...request,
                                    state: RequestState.CANCELED,
                                })),
                            }, AvailableTabs.HISTORY);
                            removeOrder(order);
                        },
                    });
                },
            },
            cancelRequest: {
                icon: <CloseCircleOutlined color="red" />,
                key: `${ record.key }-cancel`,
                label: t('Cancel request'),
                onClick ({ domEvent }: any) {
                    domEvent.stopPropagation();

                    const title = t('Cancel request');

                    Modal.confirm({
                        title,
                        icon: null,
                        content: (
                            <>
                                <p>{ t(`Are you sure you want to cancel this request?`) }</p>
                                <p>{ t('Action cannot be reversed once done.') }</p>
                            </>
                        ),
                        okText: title,
                        cancelText: t('Dismiss'),
                        centered: true,
                        onOk: async () => {
                            const order = orders.find(({ id }) => id === record.request?.order_id);

                            if (!order || !(await cancelRequest(record.request as Request))) {
                                return;
                            }

                            if (order.requests.length === 1) {
                                order.requests[0].state = RequestState.CANCELED;

                                addToTab(order, AvailableTabs.HISTORY);
                                removeOrder(order);

                                return;
                            }

                            const requestIndex = order.requests.findIndex(({ id }) => id === record.request!.id);

                            const requests = order.requests.splice(requestIndex, 1);

                            requests[0].state = RequestState.CANCELED;

                            addToTab({ ...order, requests }, AvailableTabs.HISTORY);
                            updateOrder(order);
                        },
                    });
                },
            },
            deleteOrder: {
                icon: <DeleteOutlined color="red" />,
                key: `${ record.key }-delete-order`,
                label: t('Delete order'),
                onClick ({ domEvent }: any) {
                    domEvent.stopPropagation();

                    Modal.confirm({
                        title: null,
                        icon: null,
                        content: (
                            <>
                                <p>{ t('Are you sure you want to delete this order?') }</p>
                                {/*<p style={{ color: 'darkgrey' }}>{t('Only finished shifts will be affected.')}</p>*/ }
                            </>
                        ),
                        okButtonProps: {
                            danger: true,
                        },
                        okText: t('Yes'),
                        cancelText: t('No'),
                        centered: true,
                        onOk: () => handleDeleteOrder(record),
                    });
                },
            },
            deleteRequest: {
                icon: <DeleteOutlined color="red" />,
                key: `${ record.key }-delete`,
                label: t('Delete request'),
                onClick ({ domEvent }: any) {
                    domEvent.stopPropagation();

                    Modal.confirm({
                        title: null,
                        icon: null,
                        content: (
                            <>
                                <p>{ t('Are you sure you want to delete this request?') }</p>
                                {/*<p style={{ color: 'darkgrey' }}>{t('Only finished shifts will be affected.')}</p>*/ }
                            </>
                        ),
                        okButtonProps: {
                            danger: true,
                        },
                        okText: t('Yes'),
                        cancelText: t('No'),
                        centered: true,
                        onOk: () => handleDeleteRequest(record),
                    });
                },
            },
            downloadRequest: {
                icon: <DownloadOutlined />,
                key: `${ record.key }-download-excel`,
                label: t('Download excel'),
                async onClick ({ domEvent }: any) {
                    domEvent.stopPropagation();

                    await downloadExcel(
                        orders.find(({ id }) => id === record.id) as Order,
                        localeContext,
                        record.request as Request
                    );
                },
            },
            editDraft: (
                <Button
                    icon={ <EditOutlined /> }
                    style={ { marginRight: '20px' } }
                    size={ 'small' }
                    onClick={ (e?: React.MouseEvent) => {
                        e?.stopPropagation();

                        goToCreatePage(record.id, false, record.request?.id);
                    } }
                >
                    { t('Edit') }
                </Button>
            ),
            editRequest: {
                icon: <EditOutlined />,
                key: `${ record.key }-edit-request`,
                label: t('Edit request'),
                onClick ({ domEvent }: any) {
                    domEvent.stopPropagation();

                    if (!record.request) {
                        return;
                    }

                    goToRequestPage(record.request.id, true);
                },
            },
            moveToHistory: {
                icon: <FileArchive />,
                key: `${ record.key }-move-to-history`,
                label: t('Move to History'),
                onClick ({ domEvent }: any) {
                    domEvent.stopPropagation();

                    Modal.confirm({
                        title: null,
                        icon: null,
                        content: (
                            <>
                                <p>{ t('Are you sure you want to move this request to history?') }</p>
                                <p style={ { color: 'darkgrey' } }>{ t('Only finished shifts will be affected.') }</p>
                            </>
                        ),
                        okText: t('Yes'),
                        cancelText: t('No'),
                        centered: true,
                        onOk: () => moveRecordJobsToHistory(record),
                    });
                },
            },
            moveOrderToHistory: {
                icon: <FileArchive />,
                key: `${ record.key }-move-order-to-history`,
                label: t('Move to History'),
                onClick ({ domEvent }: any) {
                    domEvent.stopPropagation();

                    Modal.confirm({
                        title: null,
                        icon: null,
                        content: (
                            <>
                                <p>{ t('Are you sure you want to move this order to history?') }</p>
                                <p style={ { color: 'darkgrey' } }>{ t('Only finished shifts will be affected.') }</p>
                            </>
                        ),
                        okText: t('Yes'),
                        cancelText: t('No'),
                        centered: true,
                        onOk: () => moveOrderToHistory(record),
                    });
                },
            },
        };

        const items: ItemType[] = [];
        let editDraft: any = null;

        if (activeTab === AvailableTabs.DRAFT) {
            if (record.children) {
                items.push(actions.addNewRequest);
            } else {
                editDraft = actions.editDraft;
            }

            items.push(actions.downloadRequest);

            if (record.children) {
                items.push(actions.cancelOrder, actions.deleteOrder);
            } else {
                items.push(actions.cancelRequest, actions.deleteRequest);
            }
        } else if ([AvailableTabs.PENDING, AvailableTabs.ACTIVE].includes(activeTab)) {
            if (record.children) {
                items.push(actions.addNewRequest);

                if (record.children.every(({ request }) => canMoveToHistory(request as Request))) {
                    items.push(actions.moveOrderToHistory);
                }
            }

            // has none canceled jobs
            if (record.request?.jobs?.some(({ state }) => state !== JobState.CANCELED)) {
                items.push(actions.editRequest, actions.moveToHistory);
            }

            if (record.children) {
                items.push(actions.cancelOrder);

                if (record.children.every(({ request }) => canDeleteRequest(request as Request))) {
                    items.push(actions.deleteOrder);
                }
            } else {
                items.push(actions.cancelRequest);

                if (canDeleteRequest(record.request as Request)) {
                    items.push(actions.deleteRequest);
                }
            }
        } else {
            items.push(actions.downloadRequest);

            if (activeTab !== AvailableTabs.HISTORY) {
                if (record.request) {
                    items.push(actions.moveToHistory);
                } else {
                    items.push(actions.moveOrderToHistory);
                }
            }

            if (record.children && record.children.every(({ request }) => canDeleteRequest(request as Request))) {
                items.push(actions.deleteOrder);
            } else if (!record.children && canDeleteRequest(record.request as Request)) {
                items.push(actions.deleteRequest);
            }
        }

        return items.length || editDraft ? renderActionColumn(record.key, items, editDraft) : null;
    };

    const renderActionColumn = (key: any, items: ItemType[], editDraft = null) => (
        <>
            { editDraft }
            { items.length ? renderDropdownMenu(key, items) : null }
        </>
    );

    const renderDropdownMenu = (key: any, items: ItemType[]) => (
        <Dropdown key={ key } menu={ { items } } placement="bottomRight">
            <MoreOutlined onClick={ (e) => e.stopPropagation() } />
        </Dropdown>
    );

    const onTableChange = (pagination: any, filters: any, sorter: any, others: any) => {
        setFilters(filters);

        handleTableChange(pagination, filters, sorter, others);
    };

    const setIdColumnWidth = (dataSource: DataSource[]) => {
        const maxLength = Math.max(...dataSource.map((item) => item.externalId.length), 0);
        return (maxLength + 4) * 10;
    };

    const hasExternalId = dataSource.some((item) => item.externalId);

    const columns: any[] = [
        ...(hasExternalId
            ? [
                {
                    dataIndex: 'externalId',
                    sorter: true,
                    title: t('ID'),
                    ...getColumnSearchProps('externalId', true),
                    width: setIdColumnWidth(dataSource),
                },
            ]
            : []),
        {
            dataIndex: 'profession',
            sorter: true,
            title: t('Profession'),
            ...getColumnSearchProps('profession', true),
        },
        {
            dataIndex: 'startEnd',
            sorter: true,
            title: t('Start - End'),
            width: 200,
            ...getColumnDateRangePickerProps('startEnd'),
        },
        {
            title: t('Location'),
            dataIndex: 'location',
            ...getColumnSearchProps('address', true),
            width: 200,
            ellipsis: true,
        },
    ];

    if (window.innerWidth < 1650 && columns.length > 0) {
        const professionColumnIndex = columns.findIndex((col) => col.dataIndex === 'profession');
        columns[professionColumnIndex].width = 200;
    }

    const createdAtColumn = {
        ...getColumnDatePickerProps('createdAt', 'DD.MM.YYYY', true),
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: true,
        title: t('Created on'),
        width: 145,
    };

    if (isProvider) {
        columns.push(
            {
                title: t('Client'),
                dataIndex: 'client',
                filters: providerFilterOptions,
                filterMultiple: true,
                filterSearch: true,
                ellipsis: true,
                width: 200,
            },
            createdAtColumn
        );
    } else {
        columns.push(createdAtColumn);
    }

    if (activeTab === AvailableTabs.HISTORY) {
        columns.push({
            title: '',
            dataIndex: 'state',
            width: 200,
            render: (state: string) => {
                let badgeStyle = {};
                let stateBadge;
                if (state === RequestState.CANCELED) {
                    badgeStyle = { width: '8px' };
                    stateBadge = <Badge status="default" style={ badgeStyle } />;
                } else if (state === RequestState.REJECTED) {
                    badgeStyle = { width: '8px' };
                    stateBadge = <Badge status="error" style={ badgeStyle } />;
                }

                const stateName = isProvider
                    ? state === RequestState.CANCELED
                        ? t('Canceled')
                        : state === RequestState.REJECTED
                            ? t('Rejected')
                            : null
                    : state === RequestState.CANCELED
                        ? t('Canceled')
                        : null;

                return (
                    <Space>
                        { stateBadge && stateName && (
                            <>
                                { stateBadge }
                                { stateName }
                            </>
                        ) }
                    </Space>
                );
            },
        });
    }

    if (showProgress) {
        columns.push({
            title: t('Progress'),
            onCell: (record: DataSource) => ({
                onClick: (e: any) => {
                    if (isProvider) {
                        return;
                    }

                    e.stopPropagation();

                    setPreviewOrder(orders.find(({ id }) => id === record.id));
                    setPreviewRequestId(record.request?.id ?? undefined);
                },
                style: { cursor: isProvider ? 'unset' : 'pointer' },
            }),
            dataIndex: 'progress',
            width: 200,
        });
    }

    if (isProvider && activeTab !== AvailableTabs.HISTORY) {
        let width = actionColumnWidth;

        if (!width) {
            width = 140;
        }

        columns.push({
            align: 'right',
            className: 'no-wrap',
            render: (_: any, record: DataSource) => renderProviderActionButton(record),
            dataIndex: 'actions',
            width,
        });
    }

    if (!isProvider) {
        let width = 50;

        if (activeTab === AvailableTabs.DRAFT) {
            width = 140;
        }

        columns.push({
            align: 'right',
            className: 'no-wrap',
            render: (_: any, record: DataSource) => renderClientActionButton(record),
            dataIndex: 'actions',
            width,
        });
    }

    type ExpandIconParam = {
        expanded: boolean;
        onExpand: (record: DataSource, e: MouseEvent<HTMLSpanElement>) => any;
        record: DataSource;
    };

    const expandable = {
        expandIcon: ({ expanded, onExpand, record }: ExpandIconParam) => {
            if (!record.children) {
                return !record.partOfOrder && <FileIcon color="#0096E1" width={ 13 } height={ 13 } />;
            }

            return expanded ? (
                <CaretDownOutlined style={ { color: 'rgba(0,0,0,.45)' } } onClick={ (e) => onExpand(record, e) } />
            ) : (
                <CaretRightOutlined style={ { color: 'rgba(0,0,0,.45)' } } onClick={ (e) => onExpand(record, e) } />
            );
        },
        expandRowByClick: true,
        expandedRowRender: () => null,
        rowExpandable: (record: DataSource) => !!record.children,
    };

    return (
        <>
            { previewOrder && (
                <ProgressModal
                    close={ () => {
                        setPreviewOrder(undefined);
                        setPreviewRequestId(undefined);
                    } }
                    order={ previewOrder }
                    requestId={ previewRequestId }
                    width={ 700 }
                />
            ) }

            <Table
                columns={ columns }
                dataSource={ dataSource }
                expandable={ expandable }
                loading={ isLoadingOrders }
                onChange={ onTableChange }
                onRow={ ({ request }: DataSource) => ({
                    onClick: () => {
                        if (!request) {
                            return;
                        }

                        goToRequestPage(request.id);
                    },
                }) }
                pagination={ tableParams.pagination }
                rowKey="key"
                scroll={ { scrollToFirstRowOnChange: true, y: 'calc(100vh - 330px)' } }
                size="small"
            />
        </>
    );
});
