import { ClockCircleOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Tooltip } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import { getTimeOptionsForTimePicker } from 'lib/Helpers/DateTimeHelper';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { Ref } from 'react';
import { GenericObject } from 'shared/Contracts';

type Props = GenericObject & {
    disabled?: boolean;
    dropdownOptions?: DefaultOptionType[];
    endTime?: string;
    inputSuffix?: JSX.Element;
    minutesStep?: number;
    onIconClick?: () => void;
    placeholder?: string;
    ref?: Ref<RefSelectProps>;
    showDuration?: boolean;
    showIcon: boolean;
    showNextDay?: boolean;
    startTime?: string;
    tooltip?: boolean;
};

export const TimePicker = (
    {
        disabled,
        dropdownOptions,
        endTime,
        inputSuffix,
        minutesStep = 15,
        onIconClick = () => {
        },
        placeholder,
        ref,
        showDuration = true,
        showIcon = true,
        showNextDay = false,
        startTime,
        tooltip = false,
        ...props
    }: Props
) => {
    const options: DefaultOptionType[] = Array.isArray(dropdownOptions)
        ? dropdownOptions
        : getTimeOptionsForTimePicker(minutesStep, startTime, endTime, showDuration, showNextDay);

    const field = (
        <AutoComplete
            {...props}
            disabled={disabled}
            options={options}
            placeholder={placeholder}
            ref={ref}
            popupMatchSelectWidth={false}
        >
            <Input
                bordered={props.bordered}
                disabled={disabled}
                prefix={showIcon ? <ClockCircleOutlined onClick={() => onIconClick()} /> : undefined}
                suffix={inputSuffix}
            />
        </AutoComplete>
    );

    return tooltip ? (
        <Tooltip placement="topLeft" title={placeholder}>
            {field}
        </Tooltip>
    ) : (
        field
    );
};
