import { Result } from 'antd';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { AccountContext, Authentication, PrivatePage } from 'lib/Account';
import { useErrorHandler } from 'lib/ErrorHandling';
import { getCurrentLocale, I18n, loadLocale } from 'lib/Localization';
import { AdminSection } from 'pages/Admin';
import { Login, Register, ResetPassword } from 'pages/Authentication';
import { VerifyEmail } from 'pages/Authentication/VerifyEmail';
import { ClientSection } from 'pages/Client';
import { Welcome } from 'pages/Onboarding/Welcome';
import { ProviderSection } from 'pages/Provider';
import { Suspense, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom-v5-compat';
import 'lib/antNotificationConfig';
import { Onboarding } from 'shared/Account/UserProfile/Onboarding';
import { LoaderSpin } from 'shared/AntDesignUtils/Loaders';
import { Layout } from 'shared/Layout';
import { SetupGuidePopup } from 'shared/SetupGuidePopup/SetupGuidePopup';
import { AccountUser, Locale, OrganizationType } from 'types';

import 'assets/style/index.scss';
import { User } from 'types/models/User';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export const App = () => {
    const handleError = useErrorHandler();
    const [cookies] = useCookies<string, { locale: string }>([]);
    const [user, setUser] = useState<AccountUser>(Authentication.guestUser as AccountUser);
    const [loadingInitialData, setLoadingInitialData] = useState(true);
    const [showSetupGuide, setShowSetupGuide] = useState(true);
    const [userData, setUserData] = useState<User | null>(null);
    // We get an error while building in the bitbucket CI/CD pipeline that
    // we can not reproduce locally
    // "Property 'locale' does not exist on type '{}'.  TS2339"
    // TODO: remove the ignore statement below when we find the problem.
    // @ts-ignore
    const currentLocale: Locale = getCurrentLocale(cookies.locale);

    useEffect(() => {
        const loadUserAndOrganization = async () => {
            // On first render fetch and set the initial user
            // This will be either the authenticated user or the generic guest (unauthenticated) user
            try {
                const initialUser = await Authentication.getInitialUser();
                setUser(initialUser);

                if (initialUser.isAuthenticated) {
                    setUserData({ ...initialUser.profile, email: initialUser.username });
                    setShowSetupGuide(!initialUser.tutorial_completed && initialUser.isAuthenticated);
                }
            } catch (error: any) {
                if (error.response?.status !== 404) {
                    // If we cannot get the initial user.
                    handleError(error);
                }
            }
        };
        Promise.all([loadUserAndOrganization(), loadLocale(currentLocale.shortCode)])
            .catch((error) => handleError(error))
            .finally(() => setLoadingInitialData(false));
    }, [handleError, currentLocale.shortCode]);

    if (loadingInitialData) {
        return <LoaderSpin />;
    }

    return (
        <AccountContext.Provider value={{ accountUser: user, setAccountUser: setUser }}>
            <I18n>
                <BrowserRouter>
                    <Suspense fallback={<LoaderSpin />}>
                        <Routes>
                            <Route path="/login" element={<Login />} />

                            <Route path="/register/client/welcome" element={<Welcome userType={OrganizationType.CLIENT} />} />

                            <Route path="/register/provider/welcome" element={<Welcome userType={OrganizationType.PROVIDER} />} />

                            <Route path="/register/:token" element={<Register />} />

                            <Route path="/reset-password" element={<ResetPassword />} />

                            <Route path="/verify-email" element={<VerifyEmail />} />

                            <Route
                                path="/onboarding"
                                element={
                                    <PrivatePage element={<Onboarding />} requirePermissions={['access-client', 'access-provider']} />
                                }
                            />

                            <Route
                                path="/admin/*"
                                element={
                                    <PrivatePage requirePermissions={['access-admin']} element={<Layout section={AdminSection} />} />
                                }
                            />

                            <Route
                                path="/client/*"
                                element={
                                    <PrivatePage requirePermissions={['access-client']} element={<Layout section={ClientSection} />} />
                                }
                            />

                            <Route
                                path="/provider/*"
                                element={
                                    <PrivatePage requirePermissions={['access-provider']} element={<Layout section={ProviderSection} />} />
                                }
                            />

                            {/*If we cannot match any route try to redirect to users home path*/}
                            <Route path="/" element={<Navigate to={Authentication.determineUserHomePath(user)} />} />

                            {/*Fallback empty page - has to be last in routes list*/}
                            <Route element={<Result status="404" title="Are you lost?" subTitle="This page does not exist." />} />
                        </Routes>
                    </Suspense>
                    {showSetupGuide && userData && (
                        <SetupGuidePopup open={true} onClose={() => setShowSetupGuide(false)} profile={userData} />
                    )}
                </BrowserRouter>
            </I18n>
        </AccountContext.Provider>
    );
};
