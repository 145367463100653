import { Layout } from 'antd';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { LoaderSpin } from 'shared/AntDesignUtils/Loaders';
import { Categories } from './Configuration/Categories';
import { Communication } from './Configuration/Communication';
import { Permissions } from './Configuration/Permissions';
import { Professions } from './Configuration/Professions';
import { Profession } from './Configuration/Professions/Profession';
import { Translations } from './Localization/Translations';
import { Organizations } from './Organizations';
import { Users } from './Users';

/**
 * Render content of the admin sub application.
 *
 * @return {*}
 */
export const Admin = () => {
    return (
        <Layout.Content className="content-body mdc-elevation--z2">
            <Suspense fallback={<LoaderSpin />}>
                <Routes>
                    <Route path="users" element={<Users />} />
                    <Route path="organizations" element={<Organizations />} />
                    <Route path="localization/translations" element={<Translations />} />
                    <Route path="configuration/professions" element={<Professions />} />
                    <Route path="configuration/profession/:id" element={<Profession />} />
                    <Route path="configuration/categories" element={<Categories />} />
                    <Route path="configuration/communication" element={<Communication />} />
                    <Route path="configuration/permissions" element={<Permissions />} />

                    {/*Fallback empty page - has to be last in routes list*/}
                    <Route path="/" element={<Users />} />
                </Routes>
            </Suspense>
        </Layout.Content>
    );
};
