import { useLocalization } from 'lib/Localization';
import moment from 'moment';
import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { RequestCreationTime } from './RequestCreationTime';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { FormInstance } from 'antd/lib/form';
import { FormRequest } from 'types/staffing';
import { useLoadEntity } from 'lib/hooks/useLoadEntity';
import { WorkShift } from 'types/models';
import { useEffect, useState } from 'react';

type Props = {
    day?: Date;
    dateField: FormListFieldData;
    departmentShiftIds?: number[];
    disabled?: boolean;
    form: FormInstance;
    hideDays?: boolean;
    saveFormHandler: (changedFormData: FormRequest | null, currentFormData: FormRequest) => void;
    timesOnly?: boolean;
};

export const RequestCreationTimes = ({
    day,
    dateField,
    departmentShiftIds = [],
    disabled,
    form,
    hideDays = false,
    saveFormHandler,
    timesOnly,
}: Props) => {
    const { t } = useLocalization();
    const shiftsURL = '/data-management/work-shifts';
    const [shifts, isLoadingShifts] = useLoadEntity<WorkShift>('workShifts', shiftsURL);
    const [departmentShifts, setDepartmentShifts] = useState<WorkShift[]>([]);

    const isWeekendDay = (date?: Date) => {
        if (!date) {
            return false;
        }

        const day = date.getDay();
        return day === 6 || day === 0; // 6 = Saturday, 0 = Sunday
    };

    const updateDepartmentShifts = () => !disabled && setDepartmentShifts(shifts);

    useEffect(() => {
        updateDepartmentShifts();
    }, [departmentShiftIds, isLoadingShifts]);

    return (
        <div>
            {day && !hideDays && (
                <p className="day-label subsection-subtitle">{moment(day).format('dddd, MMM D, YYYY')}</p>
            )}

            <div className={`${isWeekendDay(day) ? 'weekend' : ''} day-times-container`}>
                <Form.List name={[dateField.name, 'times']}>
                    {(fields, { add, remove }) => (
                        <>
                            <table cellSpacing={0} width="100%">
                                <tbody>
                                    {fields.map((timeField) => (
                                        <RequestCreationTime
                                            dateField={dateField}
                                            disabled={disabled}
                                            form={form}
                                            key={timeField.key}
                                            remove={remove}
                                            saveFormHandler={saveFormHandler}
                                            shifts={departmentShifts}
                                            timeField={timeField}
                                            timesOnly={timesOnly}
                                        />
                                    ))}
                                </tbody>
                            </table>

                            {!timesOnly && (
                                <Button
                                    className="add-shift-button"
                                    disabled={disabled}
                                    onClick={() => add({ workersNumber: 1, comment: null })}
                                    icon={<PlusOutlined />}
                                    type="link"
                                >
                                    {t('Add shift')}
                                </Button>
                            )}
                        </>
                    )}
                </Form.List>
            </div>
        </div>
    );
};
