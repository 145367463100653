import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';
import { Order, Request } from 'types/models';
import { RequestTransition } from 'types/staffing';

export const useRequestBackend = () => {
    const handleError = useErrorHandler();

    const cancelOrder = async ({ id }: Order) => {
        try {
            await Backend.delete(`/staffing/orders/${id}/cancel`);

            return true;
        } catch (error) {
            handleError(error);

            return false;
        }
    };

    const cancelRequest = async ({ id }: Request) => {
        try {
            const { data } = await Backend.put(`/staffing/requests/${id}/applyTransition/${RequestTransition.CANCEL}`);

            return data;
        } catch (error) {
            handleError(error);

            return false;
        }
    };

    const deleteOrder = async (order: Order) => {
        try {
            await Backend.delete(`staffing/orders/${order.id}`);

            return true;
        } catch (error) {
            handleError(error);

            return false;
        }
    };

    const deleteRequest = async (request: Request) => {
        try {
            await Backend.delete(`staffing/requests/${request.id}`);

            return true;
        } catch (error) {
            handleError(error);

            return false;
        }
    };

    return {
        cancelOrder,
        cancelRequest,
        deleteOrder,
        deleteRequest
    };
};
