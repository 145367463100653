import { useLocalization } from 'lib/Localization';
import { Select } from 'shared/AntDesignUtils/Select/Select';
import { GenericObject } from 'shared/Contracts';
import { Provider } from 'types/models';

type Props = GenericObject & {
    disabled?: boolean;
    disableOption?: (provider: Provider) => boolean;
    onDeselect?: (value: number) => void;
    onSelect?: (value: number) => void;
    providers: Provider[];
    value: number[];
}

export const ProviderSelect = (
    {
        disabled,
        disableOption,
        onDeselect,
        onSelect,
        providers,
        value,
        ...otherProps
    }: Props
) => {
    const { t } = useLocalization();

    return (
        <Select
            autoClearSearchValue={false}
            closeOptionOnSelect={false}
            defaultValue={value.map((id: number) => ({
                key: id,
                label: providers.find((provider) => provider.id === id)?.company_name
            }))}
            disabled={disabled}
            mode="multiple"
            optionFilterProp="label"
            options={providers.map((provider) => ({
                disabled: disableOption?.(provider) || false,
                label: provider.company_name,
                value: provider.id
            }))}
            onDeselect={(id: number) => !disabled && onDeselect?.(id)}
            onSelect={(id: number) => !disabled && onSelect?.(id)}
            style={{ width: '100%' }}
            value={value.map((id: number) => ({
                key: id,
                label: providers.find((provider) => provider.id === id)?.company_name
            }))}
            filterOption
            showSearch
            {...otherProps}
        />
    );
};
