import { PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Drawer } from 'antd';
import { useCollections } from 'lib/Contexts/Collections';
import { useErrorHandler } from 'lib/ErrorHandling';
import { sortColumn } from 'lib/Helpers/TableHelper';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { useCallback, useEffect, useState } from 'react';
import { Category, Country, Profession } from 'types/models';
import { ProfessionForm } from './Profession/ProfessionForm';
import { ProfessionsTable } from './ProfessionsTable';

/**
 * Stateful container component responsible for professions data management.
 *
 */
export const Professions = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [professions, setProfessions] = useState([]);
    const [categories, setCategories] = useState([]);
    const [collections] = useCollections();

    /**
     * Fetch data form backend.
     *
     * @return {Promise<void>}
     */
    const loadProfessionsData = useCallback(async () => {
        try {
            setIsDataLoading(true);
            const response = await Backend.get(`/data-management/professions?relations=country,category`);
            setProfessions(response.data.professions);
            setCategories(response.data.collections.categories);
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataLoading(false);
        }
    }, [handleError]);

    /**
     * After the component renders
     */
    useEffect(() => {
        loadProfessionsData();
    }, [loadProfessionsData]);

    const countryOptions = collections.countries.map((country: Country) => ({
        value: country.id,
        label: t(country.name),
        text: t(country.name),
    }));

    const categoryOptions = categories.map((category: Category) => ({
        value: category.id,
        label: t(category.name),
        text: t(category.name),
    }));

    const headerButtons = [
        <Button key="1" type="primary" icon={<PlusOutlined />} onClick={() => setIsFormOpen(true)}>
            {t('Add Profession')}
        </Button>,
    ];

    /**
     * Format professions.
     *
     * @param professions
     * @return {Array}
     */
    const formatProfessions = (professions: Profession[]) => {
        professions.sort((a, b) => sortColumn(a, b, 'name'));
        return professions.map((profession) => ({
            ...profession,
            key: profession.id,
        }));
    };

    const formattedProfessions = formatProfessions(professions);

    return (
        <PageHeader title={t('Professions')} backIcon={false} ghost={false} extra={[headerButtons]}>
            <ProfessionsTable
                professions={formattedProfessions}
                countryOptions={countryOptions}
                categoryOptions={categoryOptions}
                loadProfessionsData={loadProfessionsData}
                isDataLoading={isDataLoading}
            />

            <Drawer title={t('Add profession')} width={500} onClose={() => setIsFormOpen(false)} open={isFormOpen}>
                <ProfessionForm
                    countryOptions={countryOptions}
                    categoryOptions={categoryOptions}
                    loadProfessionData={loadProfessionsData}
                    setIsFormOpen={setIsFormOpen}
                />
            </Drawer>
        </PageHeader>
    );
};
