import { EditOutlined } from '@ant-design/icons';
import { Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { useTable } from 'lib/hooks/useTable';
import { useLocalization } from 'lib/Localization';
import { useEffect } from 'react';
import { NotificationTemplate } from 'types/models';

type Props = {
    notificationTemplates: NotificationTemplate[];
    openTemplateHandler: (template: NotificationTemplate) => void;
    languageFilterOptions: ColumnFilterItem[];
    isDataLoading: boolean;
};

/**
 * Table to display notification templates
 *
 * @param notificationTemplates
 * @param openTemplateHandler
 * @param languageFilterOptions
 * @param isDataLoading
 * @returns {JSX.Element}
 * @constructor
 */
export const NotificationTemplatesTable = ({
    notificationTemplates,
    openTemplateHandler,
    languageFilterOptions,
    isDataLoading,
}: Props) => {
    const { t } = useLocalization();
    const { tableParams, handleTableChange, setTotal } = useTable();

    /**
     * Render a cell with action button.
     *
     * @param id {string}
     * @return {*}
     */
    const renderActionCell = (record: NotificationTemplate) => (
        <Space>
            <EditOutlined onClick={ () => openTemplateHandler(record) } title={ `edit_${ record.id }` } />
        </Space>
    );

    const columns: ColumnsType<NotificationTemplate> = [
        {
            title: t('Type'),
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => sortColumn(a, b, 'type'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('type'),
        },
        {
            title: t('Language'),
            dataIndex: 'language_id',
            key: 'language_id',
            filters: languageFilterOptions,
            filterMultiple: true,
            filterSearch: true,
            onFilter: (value, record) => record.language_id === value,
            render: (language_id, record) => record.language?.name,
        },
        {
            title: t('Action'),
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => renderActionCell(record),
        },
    ];

    useEffect(() => {
        setTotal(notificationTemplates.length);
    }, [notificationTemplates]);

    return (
        <Table
            rowKey="id"
            dataSource={ notificationTemplates }
            columns={ columns }
            pagination={ tableParams.pagination }
            loading={ isDataLoading }
            onChange={ handleTableChange }
            scroll={ { scrollToFirstRowOnChange: true, y: 'calc(100vh - 2560px)' } }
            size="small"
        />
    );
};
