import 'pages/Authentication/AuthLayout.scss';

import { Card, Layout } from 'antd';
import logo from 'assets/img/tempcloud-logo.svg';
import { useAccount } from 'lib/Account';
import { Navigate } from 'react-router-dom-v5-compat';
import { ChangeLocaleHorizontalMenu } from 'shared/Localization';

type Props = {
    cardClass?: string;
    children?: JSX.Element | JSX.Element[];
}

export const AuthLayout = ({ cardClass, children }: Props) => {
    const { accountUser } = useAccount();

    // If the user is already logged in redirect him to the homepage
    if (accountUser.isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <Layout className="unauth-screen-wrapper">
            {children && (
                <>
                    <ChangeLocaleHorizontalMenu/>

                    <Card className={`${cardClass} box`}>
                        <div className="logo-wrapper text-center">
                            <img src={logo} alt="" />
                        </div>

                        {children}
                    </Card>
                </>
            )}
        </Layout>
    );
}
