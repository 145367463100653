import { notification, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCollections } from 'lib/Contexts/Collections';
import { useErrorHandler } from 'lib/ErrorHandling';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { useState } from 'react';
import { Permission, Role } from 'types/models';

export const RolesPermissionsTable = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [collections, setCollections] = useCollections();
    const { roles, permissions } = collections;

    const [isDataSaving, setIsDataSaving] = useState<{ [index: number]: boolean }>({});

    const permissionOptions = permissions.map((permission) => ({
        value: permission.id,
        label: permission.name,
    }));

    /**
     * Save role's permissions.
     *
     * @param roleId
     * @param connectedRoleIds
     */
    const saveHandler = async ({ roleId, permissionsIds }: { roleId: number; permissionsIds: number[] }) => {
        try {
            setIsDataSaving({ [roleId]: true });
            const response = await Backend.put(`data-management/roles/${roleId}/syncPermissions`, permissionsIds);
            if (response.status === Backend.responseStatus.HTTP_OK) {
                notification.success({
                    message: t(`Permissions successfully saved`),
                });
                setCollections({
                    ...collections,
                    roles: response.data,
                });
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataSaving({ [roleId]: false });
        }
    };

    /**
     * Render an in-line view for connected roles.
     *
     * @return {*}
     * @param permissions
     * @param roleId
     */
    const renderPermissionsCell = (permissions: Permission[], roleId: number) => {
        const permissionsIds = permissions.map((permission) => permission.id);

        return (
            <Select
                style={{ width: '100%', maxWidth: '100%' }}
                mode="multiple"
                placeholder={t('Set permissions')}
                className="multi-select"
                onChange={(permissionsIds) =>
                    saveHandler({
                        roleId,
                        permissionsIds,
                    })
                }
                value={permissionsIds}
                options={permissionOptions}
                loading={isDataSaving[roleId]}
                optionFilterProp="label"
            />
        );
    };

    /**
     * Filter table by permissions.
     *
     * @param value
     * @param record
     *
     * @returns {boolean}
     */
    const filterByPermissions = (value: any, record: Role) => {
        for (const permission of record.permissions) {
            if (permission.id === value) {
                return true;
            }
        }
        return false;
    };

    const columns: ColumnsType<Role> = [
        {
            title: t('Role'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => sortColumn(a, b, 'name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('name'),
        },
        {
            title: t('Permissions'),
            dataIndex: 'permissions',
            key: 'permissions',
            render: (permissions, record) => renderPermissionsCell(permissions, record.id),
            filters: permissions.map((permission) => ({
                value: permission.id,
                text: permission.name,
            })),
            filterMultiple: true,
            filterSearch: true,
            onFilter: filterByPermissions,
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={columns}
            dataSource={roles}
            pagination={false}
            scroll={{ scrollToFirstRowOnChange: true, y: 'calc(100vh - 220px)' }}
            size="small"
        />
    );
};
