import { EditOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { convertDurationToMinutes } from 'lib/Helpers/DateTimeHelper';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { useTable } from 'lib/hooks/useTable';
import { useLocalization } from 'lib/Localization';
import { parseDateTime } from 'lib/Validation/helpers';
import { useEffect } from 'react';
import { DeletePopconfirm } from 'shared/AntDesignUtils/DeletePopconfirm';
import { WorkShift } from 'types/models';

type Props = {
    shifts: WorkShift[];
    deleteShiftHandler: (id: number) => Promise<void>;
    openShiftHandler: (shift: WorkShift) => void;
    isDataLoading: boolean;
    isDataDeleting: boolean;
};

export const WorkShiftsTable = ({
    shifts,
    deleteShiftHandler,
    openShiftHandler,
    isDataLoading,
    isDataDeleting,
}: Props) => {
    const { t } = useLocalization();
    const { tableParams, handleTableChange, setTotal } = useTable();


    const renderNameCell = (shift: WorkShift) => (
        <Button type="link" onClick={ () => openShiftHandler(shift) }>
            { shift.name }
        </Button>
    );

    const renderTimeAfterStartTime = (time: string, shift: WorkShift) => {
        if (!time) {
            return '-';
        }

        const $time = parseDateTime(time);
        const $startTime = parseDateTime(shift.start_time);

        if ($time.isBefore($startTime)) {
            return `${ time } (${ t('next day') })`;
        }

        return time;
    };

    const renderActionCell = (shift: WorkShift) => (
        <Space>
            <EditOutlined onClick={ () => openShiftHandler(shift) } />
            <DeletePopconfirm
                title={ t('Are you sure you want to delete this shift?') }
                id={ shift.id }
                isDataDeleting={ isDataDeleting }
                deleteHandler={ deleteShiftHandler }
            />
        </Space>
    );

    useEffect(() => {
        setTotal(shifts.length);
    }, [shifts]);

    const columns: ColumnsType<WorkShift> = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => renderNameCell(record),
            sorter: (a, b) => sortColumn(a, b, 'name'),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend',
            ...getColumnSearchProps('name'),
        },
        {
            title: t('Shift start time'),
            dataIndex: 'start_time',
            key: 'start_time',
            sorter: (a, b) => sortColumn(a, b, 'start_time'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('start_time'),
        },
        {
            title: t('Shift end time'),
            dataIndex: 'end_time',
            key: 'end_time',
            sorter: (a, b) => sortColumn(a, b, 'end_time'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('end_time'),
            render: renderTimeAfterStartTime,
        },
        {
            title: t('Shift duration'),
            dataIndex: 'duration',
            key: 'duration',
            sorter: (a, b) => convertDurationToMinutes(a.duration) - convertDurationToMinutes(b.duration),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('duration'),
        },
        {
            title: t('Break time'),
            dataIndex: 'break_duration',
            key: 'break_duration',
            sorter: (a, b) => sortColumn(a, b, 'break_duration'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('break_duration'),
            render: (value) => (value ? value + 'm' : '-'),
        },
        {
            title: t('Break start time'),
            dataIndex: 'break_start_time',
            key: 'break_start_time',
            sorter: (a, b) => sortColumn(a, b, 'break_start_time'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('break_start_time'),
            render: renderTimeAfterStartTime,
        },
        {
            title: t('Action'),
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => renderActionCell(record),
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={ columns }
            dataSource={ shifts }
            pagination={ tableParams.pagination }
            loading={ isDataLoading }
            onChange={ handleTableChange }
            scroll={ { scrollToFirstRowOnChange: true, y: 'calc(100vh - 260px)' } }
            size="small"
        />
    );
};
