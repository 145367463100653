import 'shared/Requests/Request/RequestLocation.scss';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select as AntSelect, Tooltip } from 'antd';
import { useRecentItems } from 'lib/hooks';
import { useLocalization } from 'lib/Localization';
import { useEffect } from 'react';
import { Select } from 'shared/AntDesignUtils/Select/Select';
import { Address } from 'types/models';
import { RecentItem } from 'types/RecentItem';

const { Option } = AntSelect;

type Props = {
    addresses: Address[];
    recentAddresses: number[];
    loadingLocations: boolean;
    requestAddressId?: number;
    setRequestAddressId: (addressId: number) => void;
    onCreateAddressHandler: () => void;
};

export const RequestLocation = ({
    addresses,
    recentAddresses,
    loadingLocations,
    requestAddressId,
    setRequestAddressId,
    onCreateAddressHandler,
}: Props) => {
    const { t } = useLocalization();
    const { getSelectOptionsFor } = useRecentItems();
    const [form] = Form.useForm();

    const locationsChangeHandler = (addressId: number) => {
        setRequestAddressId(addressId);
        form.setFieldsValue({ address_id: addressId });
    };

    const getLocationOptions = () =>
        getSelectOptionsFor('locations', recentAddresses, addresses, ({ id, name, address, city }: RecentItem) => ({
            label: `${name}, ${address}, ${city}`,
            value: id,
        }));

    useEffect(() => {
        if (!requestAddressId) {
            return;
        }

        locationsChangeHandler(requestAddressId);
    }, [requestAddressId]);

    return (
        <div className="request-location-creation request-location">
            <Row>
                <Col span={17}>
                    <Form form={form}>
                        <Form.Item
                            name="address_id"
                            rules={[{ required: true, message: t('Please select location') }]}
                        >
                            <Select
                                value={requestAddressId}
                                filterOption={(input, option) =>
                                    (option!.label as unknown as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                loading={loadingLocations}
                                onChange={locationsChangeHandler}
                                optionFilterProp="label"
                                options={getLocationOptions()}
                                placeholder={
                                    loadingLocations ? t('Loading locations...') : t('Choose location')
                                }
                                showSearch
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={4}>
                    <Tooltip placement="top" title={t('Please click here to configure your addresses')}>
                        <Button
                            icon={<PlusOutlined />}
                            onClick={() => onCreateAddressHandler()}
                            className="creation-buttons"
                        >
                            {t('Add location')}
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
        </div>
    );
};
