import { EditOutlined } from '@ant-design/icons';
import { Button, notification, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useErrorHandler } from 'lib/ErrorHandling';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { useTable } from 'lib/hooks/useTable';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { useEffect, useState } from 'react';
import { DeletePopconfirm } from 'shared/AntDesignUtils/DeletePopconfirm';
import { Category } from 'types/models';

type Props = {
    categories: Category[];
    openCategoryHandler: (category: Category) => void;
    loadCategoriesData: () => Promise<void>;
    isDataLoading: boolean;
};

/**
 * Table to display categories
 *
 * @param categories
 * @param openCategoryHandler
 * @param loadCategoriesData
 * @param isDataLoading
 * @returns {JSX.Element}
 */
export const CategoriesTable = ({ categories, openCategoryHandler, loadCategoriesData, isDataLoading }: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const { tableParams, handleTableChange, setTotal } = useTable();

    const [isDataDeleting, setIsDataDeleting] = useState(false);

    /**
     * Delete a category by id.
     *
     * @param id {int}
     * @return {Promise<void>}
     */
    const deleteCategoryHandler = async (id: number) => {
        try {
            setIsDataDeleting(true);
            const response = await Backend.delete(`/data-management/categories/${ id }`);
            if (response.status === Backend.responseStatus.HTTP_NO_CONTENT) {
                notification.success({
                    message: t('Category successfully removed'),
                });
                loadCategoriesData();
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataDeleting(false);
        }
    };

    /**
     * Render cell for profession name.
     *
     * @param record { object }
     * @return {*}
     */
    const renderNameCell = (record: Category) => (
        <Button type="link" onClick={ () => openCategoryHandler(record) }>
            { t(record.name) }
        </Button>
    );

    /**
     * Render a cell with action buttons.
     *
     * @param category {object}
     * @return {*}
     */
    const renderActionCell = (category: Category) => (
        <Space>
            <EditOutlined onClick={ () => openCategoryHandler(category) } />
            <DeletePopconfirm
                title={ t('Are you sure you want to delete this category?') }
                id={ category.id }
                isDataDeleting={ isDataDeleting }
                deleteHandler={ deleteCategoryHandler }
            />
        </Space>
    );

    useEffect(() => {
        setTotal(categories.length);
    }, [categories]);

    const columns: ColumnsType<Category> = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => sortColumn(a, b, 'name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('name'),
            onFilter: (value: any, record: any): boolean =>
                record['name']
                    ? t(record['name'].toString()).toLowerCase().includes(value.toString().toLowerCase())
                    : false,
            render: (name, record) => renderNameCell(record),
        },
        {
            title: t('Actions'),
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => renderActionCell(record),
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={ columns }
            dataSource={ categories }
            pagination={ tableParams.pagination }
            loading={ isDataLoading }
            onChange={ handleTableChange }
            scroll={ { scrollToFirstRowOnChange: true, y: 'calc(100vh - 260px)' } }
            size="small"
        />
    );
};
