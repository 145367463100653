import { PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Drawer } from 'antd';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { useCallback, useEffect, useState } from 'react';
import { Department, WorkShift } from 'types/models';
import { DepartmentForm } from './DepartmentForm';
import { DepartmentsTable } from './DepartmentsTable';

export const Departments = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isDataSaving, setIsDataSaving] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState<Department>();
    const [isDepartmentFormOpen, setIsDepartmentFormOpen] = useState(false);
    const [shifts, setShifts] = useState<WorkShift[]>([]);
    const departmentsURL = '/data-management/departments';

    /**
     * Fetch data form backend.
     *
     * @return void
     */
    const loadDepartmentsData = useCallback(async () => {
        try {
            setIsDataLoading(true);

            const response = await Backend.get(`${departmentsURL}?relations=workShifts&collect=workShifts`);

            setDepartments(response.data.departments);
            setShifts(response.data.collections.workShifts);
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataLoading(false);
        }
    }, [handleError]);

    /**
     * After the component renders
     */
    useEffect(() => {
        loadDepartmentsData();
    }, [loadDepartmentsData]);

    /**
     * Open the department form to create/update a department.
     *
     * @param department {object}
     * @return void
     */
    const openDepartmentHandler = (department?: Department) => {
        setDepartment(department);
        setIsDepartmentFormOpen(true);
    };

    const savedDepartmentHandler = () => {
        setIsDepartmentFormOpen(false);

        loadDepartmentsData();
    };

    const headerButtons = [
        <Button key="1" type="primary" icon={<PlusOutlined />} onClick={() => openDepartmentHandler()}>
            {t('Add Department')}
        </Button>,
    ];

    const shiftFilterOptions = shifts.map(({ id, name }) => ({
        value: id,
        text: name,
    }));

    return (
        <PageHeader
            title={t('Departments')}
            backIcon={false}
            ghost={false}
            extra={headerButtons}
        >
            <DepartmentsTable
                departmentsURL={departmentsURL}
                departments={departments}
                openDepartmentHandler={openDepartmentHandler}
                isDataLoading={isDataLoading}
                loadDepartmentsData={loadDepartmentsData}
                shiftFilterOptions={shiftFilterOptions}
            />

            <Drawer
                title={department?.id ? t('Edit Department') : t('Add Department')}
                width={500}
                onClose={() => setIsDepartmentFormOpen(false)}
                open={isDepartmentFormOpen}
            >
                <DepartmentForm
                    department={department}
                    shifts={shifts}
                    onSavedDepartment={savedDepartmentHandler}
                    setIsDataSaving={setIsDataSaving}
                />
            </Drawer>
        </PageHeader>
    );
};
