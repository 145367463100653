import { DeleteTwoTone, LoadingOutlined } from '@ant-design/icons';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';
import { useState } from 'react';
import { Document } from 'types/models';

type Props = {
    disabled?: boolean;
    document: Document;
    onRemoved?: () => void;
    readonly?: boolean;
}

export const DocumentFile = ({ disabled, document, onRemoved, readonly }: Props) => {
    const handleError = useErrorHandler();

    const [deleting, setDeleting] = useState(false);

    const remove = async (document: Document) => {
        if (disabled) {
            return;
        }

        try {
            setDeleting(true);

            await Backend.delete(`/data-management/documents/${document.id}`);

            onRemoved?.();
        } catch (e) {
            handleError(e);
        }

        setDeleting(false);
    };

    if (deleting) {
        return (
            <>
                <s>{document.name}</s>

                <LoadingOutlined className="pull-right" />
            </>
        );
    }

    return (
        <>
            <a href={document.url} target="_blank" rel="noreferrer">
                {document.name}
            </a>

            {!readonly && (
                <DeleteTwoTone
                    twoToneColor="#FF4235"
                    className="pull-right"
                    onClick={(e: any) => {
                        e.stopPropagation();
                        e.preventDefault();

                        remove(document);
                    }}
                />
            )}
        </>
    );
};
