import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, notification, Popconfirm, Space, Typography } from 'antd';
import { useErrorHandler } from 'lib/ErrorHandling';
import { useRequestActions } from 'lib/hooks/useRequestActions';
import { useRequestBackend } from 'lib/hooks/useRequestBackend';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { downloadExcel, useRequestTab } from 'lib/Request';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { FileArchive } from 'shared/icons/FileArchive';
import { Job, Request } from 'types/models';
import 'pages/Client/Requests/Request/RequestActionButtons.scss';
import { FormattedJob, RequestState, RequestTransition } from 'types/staffing';
import { JobState } from 'types/staffing/JobStateMachine';

type Props = {
    editRequest: boolean;
    handleUpdateJobs: () => Promise<Request | false>;
    handleUpdateRequest: () => Promise<boolean>;
    jobs: Job[];
    jobsToCancel: number[];
    historical?: boolean;
    possibleTransitions: RequestTransition[];
    request: Request;
    setJobsToCancel: (jobs: number[]) => void;
    setJobsToUpdate: (jobs: FormattedJob[]) => void;
    setRequest: (request: Request) => void;
    startEditing: () => void;
    stopEditing: (reset?: boolean) => void;
};

export const RequestActionButtons = ({
    editRequest,
    historical,
    jobsToCancel,
    startEditing,
    stopEditing,
    setJobsToCancel,
    setJobsToUpdate,
    handleUpdateJobs,
    handleUpdateRequest,
    setRequest,
    request,
    possibleTransitions,
}: Props) => {
    const localeContext = useLocalization();
    const { t } = localeContext;
    const handleError = useErrorHandler();
    const { getTabInfo } = useRequestTab();
    const { canDeleteRequest, movablePastJobs, moveJobsToHistory } = useRequestActions(request, setRequest);
    const { cancelRequest, deleteRequest } = useRequestBackend();
    const navigate = useNavigate();

    const [cancelRequestLoading, setCancelRequestLoading] = useState(false);
    const [tabInfo, setTabInfo] = useState(getTabInfo(request));
    const [hasNoneCanceledJobs, setHasNoneCanceledJobs] = useState(true);

    useEffect(() => {
        setTabInfo(getTabInfo(request));
        setHasNoneCanceledJobs(request.jobs.some(({ state }) => state !== JobState.CANCELED));
    }, [request]);

    const cancelRequestHandler = async () => {
        try {
            setCancelRequestLoading(true);

            const data = await cancelRequest(request);

            if (data) {
                setRequest(data.request);
                stopEditing();
                notification.success({
                    message: t('Request successfully canceled'),
                });
            }
        } catch (error) {
            handleError(error);
        } finally {
            setCancelRequestLoading(false);
        }
    };

    const cancelShiftsHandler = async () => {
        if (!jobsToCancel.length) {
            return;
        }

        try {
            const response = await Backend.put(`/staffing/requests/${request.id}/cancel-job-shifts`, {
                job_ids: jobsToCancel,
            });

            if (response.status === Backend.responseStatus.HTTP_OK) {
                setRequest(response.data.request);

                notification.success({
                    message: t('Shift successfully canceled |||| Shifts successfully canceled', {
                        smart_count: jobsToCancel.length,
                    }),
                });

                cancelEdit();
            }
        } catch (e) {
            handleError(e);
        } finally {
            stopEditing();
        }
    };

    const handleDeleteRequest = async () => {
        if (!(await deleteRequest(request))) {
            return;
        }

        notification.success({
            message: t('Request successfully deleted'),
        });

        navigate(-1);
    }

    const updateRequest = async () => {
        if (!(await handleUpdateJobs()) || !(await handleUpdateRequest())) {
            return;
        }

        await cancelShiftsHandler();
        stopEditing(false);
    };

    const cancelEdit = () => {
        setJobsToCancel([]);
        stopEditing();
        setJobsToUpdate([]);
    };

    const getEditActions = (canCancelRequest = true) => (
        <Space>
            {editRequest ? (
                <>
                    {canCancelRequest && (
                        <Popconfirm
                            className="cancel-request-popconfirm"
                            key="cancel-request-popconfirm"
                            icon={false}
                            placement="bottomRight"
                            title={() => {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        <p>{t('Are you sure you want to cancel this request?')}</p>
                                        <Typography.Text type="secondary">
                                            *{t(request.transitionMetadata[RequestTransition.CANCEL][request.state])}
                                        </Typography.Text>
                                    </div>
                                );
                            }}
                            onConfirm={cancelRequestHandler}
                            okText={t('Yes, cancel anyway')}
                            cancelText={t('No')}
                        >
                            <Button loading={cancelRequestLoading}>{t('Cancel entire request')}</Button>
                        </Popconfirm>
                    )}
                    <Divider type="vertical" />
                    <Button onClick={cancelEdit}>{t('Abort editing')}</Button>

                    <Button type="primary" onClick={updateRequest}>
                        {t('Update request')}
                    </Button>
                </>
            ) : (
                <>
                    <Button type="primary" onClick={() => startEditing()}>
                        {t('Edit request')}
                    </Button>
                </>
            )}
        </Space>
    );

    const actionButtons: { [requestTransition in RequestTransition]?: ReactNode } = {
        [RequestTransition.CANCEL]: getEditActions(),
    };

    const movableJobs = request.state === RequestState.REJECTED ?
        request.jobs.filter(({ is_client_archived }) => !is_client_archived) :
        movablePastJobs;

    return (
        <Space className="request-action-buttons">
            {!historical && !!movableJobs.length && (!!tabInfo.active.length || !!tabInfo.pending.length) && (
                <Popconfirm
                    icon={false}
                    cancelButtonProps={{ size: 'middle' }}
                    cancelText={t('No')}
                    okButtonProps={{ size: 'middle' }}
                    okText={t('Yes')}
                    onConfirm={() => moveJobsToHistory(
                        request.state === RequestState.REJECTED ?
                            request.jobs.map(({ id }) => id) :
                            []
                    )}
                    placement="bottomRight"
                    title={
                        <>
                            <p>{t('Are you sure you want to move this request to History?')}</p>
                            <p style={{ color: 'darkgrey' }}>{t('Only finished shifts will be affected.')}</p>
                        </>
                    }
                >
                    <Button icon={<FileArchive style={{ marginRight: '10px' }} />}>{t('Move to History')}</Button>
                </Popconfirm>
            )}

            {canDeleteRequest(request) && (
                <Popconfirm
                    icon={false}
                    cancelButtonProps={{ size: 'middle' }}
                    cancelText={t('No')}
                    okButtonProps={{ danger: true, size: 'middle' }}
                    okText={t('Yes')}
                    onConfirm={handleDeleteRequest}
                    placement="bottomRight"
                    title={
                        <>
                            <p>{t('Are you sure you want to delete this request?')}</p>
                        </>
                    }
                >
                    <Button icon={<DeleteOutlined style={{ marginRight: '10px' }} />} danger>{t('Delete')}</Button>
                </Popconfirm>
            )}

            {!editRequest && (
                <Button
                    type="default"
                    icon={<DownloadOutlined />}
                    onClick={() =>
                        downloadExcel(
                            {
                                ...request.order,
                                created_by: request.created_by,
                                client: request.client,
                            },
                            localeContext,
                            request
                        )
                    }
                >
                    {t('Download excel')}
                </Button>
            )}
            
            {![RequestState.CANCELED, RequestState.EXPIRED, RequestState.REJECTED].includes(request.state) &&
                !historical && (
                    <>
                        {possibleTransitions.map((transition) => actionButtons[transition])}
                        {!possibleTransitions.length &&
                            !tabInfo.history.length &&
                            !tabInfo.canceled.length &&
                            hasNoneCanceledJobs &&
                            getEditActions(false)}
                    </>
                )}
        </Space>
    );
};
