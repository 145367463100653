import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Tag } from 'antd';
import { useAccount } from 'lib/Account';
import { uniqueArrayItems } from 'lib/Helpers/General';
import { userIsClient, userIsProvider } from 'lib/Helpers/UserHelper';
import { useRequestActions } from 'lib/hooks/useRequestActions';
import { useLocalization } from 'lib/Localization';
import { SelectOrCreateDepartment } from 'pages/Client/Configuration/Departments/SelectOrCreateDepartment';
import { RequestCreationAddressFormDrawer } from 'pages/Client/Requests/Create';
import { OrderFormRequest } from 'pages/Client/Requests/Create/RequestCreation';
import { ProviderSelect } from 'pages/Provider/ProviderSelect';
import { useState } from 'react';
import { Documents } from 'shared/Documents';
import { UsersIcon } from 'shared/icons/UsersIcon';
import { QuotaModal } from 'shared/Requests/Request/QuotaModal/QuotaModal';
import { RequestLocation } from 'shared/Requests/Request/RequestLocation';
import { Address, Department, Document, Provider, Request } from 'types/models';
import { FormattedJob } from 'types/staffing';

type Props = {
    addresses: any[];
    editRequest: boolean;
    handleUpdateJobs: (jobs: FormattedJob[]) => Promise<Request | false>;
    loadLocations: () => Promise<void>;
    loadingLocations: boolean;
    providers: Provider[];
    request: Request;
    requestAddressId: number;
    setRequestAddressId: (id: number) => void;
    updateRequest: (partialRequest: Partial<Request>) => void;
};

export const RequestHeader = ({
    addresses,
    editRequest,
    handleUpdateJobs,
    loadLocations,
    loadingLocations,
    providers,
    request,
    requestAddressId,
    setRequestAddressId,
    updateRequest
}: Props) => {
    const { accountUser: user } = useAccount();
    const isProvider = userIsProvider(user);
    const isClient = userIsClient(user);
    const { t } = useLocalization();
    const { getProviderWorkerQuota } = useRequestActions();
    const [form] = Form.useForm();

    const [isAddressFormOpen, setIsAddressFormOpen] = useState(false);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [showAllProviders, setShowAllProviders] = useState(false);
    const [openQuotaModal, setOpenQuotaModal] = useState<boolean>(false);

    const getProviderList = () => {
        const providerRequests = showAllProviders ? request.provider_requests : request.provider_requests.slice(0, 40);

        return providerRequests.map(({ provider }) => (
            <Tag style={{ fontWeight: 'bold' }}>
                <Space>
                    {provider.company_name}
                    <UsersIcon />
                    {getProviderWorkerQuota(request as unknown as OrderFormRequest, provider.id)}
                </Space>
            </Tag>
        ));
    };

    const closeLocationForms = () => {
        setIsAddressFormOpen(false);
    };

    const onOpenAddressForm = () => {
        closeLocationForms();
        setIsAddressFormOpen(true);
    };

    const onAddressFormSaved = async (address?: Address) => {
        await loadLocations();
        closeLocationForms();

        if (address) {
            setRequestAddressId(address?.id);
        }
    };

    const onChangeHandler = (id: number) => {
        updateRequest({
            department_id: id,
        });

        form.setFieldValue('department_id', id);
    }

    const onDepartmentFormSaved = (department: Department) =>  {
        setDepartments([
            ...departments,
            department,
        ]);

        onChangeHandler(department.id);
    }

    const onDocumentRemoved = (_: Document, documents: Document[]) => updateRequest({ documents });

    const onDocumentSaved = (_: Document[], documents: Document[]) => updateRequest({ documents });

    return (
        <>
            <Form form={form}>
                <div className="request-header-info">
                    <table className="request-header-table">
                        {request.order.name && !request.order.auto_created && (
                            <tr>
                                <td>
                                    {request.order.external_id ? t('Order Name / External ID:') : t('Order Name:')}
                                </td>
                                <td>
                                    {request.order.name}
                                    {request.order.external_id ? ` / ${request.order.external_id}` : ''}
                                </td>
                            </tr>
                        )}
                        {isClient && (
                            <tr>
                                <td>{t('Providers')}:</td>
                                <td>
                                    <p className="request-header-table-providers">
                                        {!editRequest && (
                                            <>
                                                {getProviderList()}

                                                {request.provider_requests.length > 40 && (
                                                    <Button
                                                        className="request-header-table-providers-button"
                                                        type="link"
                                                        onClick={() => setShowAllProviders(!showAllProviders)}
                                                    >
                                                        {t(showAllProviders ? 'see less' : 'see all')}
                                                    </Button>
                                                )}
                                            </>
                                        )}

                                        {editRequest && (
                                            <Space>
                                                <ProviderSelect
                                                    disableOption={({ id }) => !!request.provider_requests.find(({ provider_id }) => id === provider_id)}
                                                    onDeselect={(id) => {
                                                        const providerIds = [...(request.providers || [])];
                                                        const index = providerIds.findIndex((providerId) => providerId === id);

                                                        if (index === -1) {
                                                            return;
                                                        }

                                                        providerIds.splice(index, 1);

                                                        updateRequest({ providers: providerIds });
                                                    }}
                                                    onSelect={(id) => updateRequest({
                                                        providers: (request.providers || []).includes(id) ?
                                                            request.providers :
                                                            [...(request.providers || []), id]
                                                    })}
                                                    providers={providers}
                                                    value={uniqueArrayItems([
                                                        ...(request.providers || []),
                                                        ...request.provider_requests.map(({ provider_id }) => provider_id),
                                                    ])}
                                                />

                                                <Button
                                                    className={editRequest ? 'creation-buttons' : ''}
                                                    icon={<EditOutlined />}
                                                    onClick={() => setOpenQuotaModal(true)}
                                                >
                                                    {t('Edit quota')}
                                                </Button>
                                            </Space>
                                        )}
                                    </p>
                                </td>
                            </tr>
                        )}
                        {isProvider && (
                            <tr>
                                <td>{t('Client:')}</td>
                                <td>{request.client.company_name}</td>
                            </tr>
                        )}
                        <tr>
                            <td>{t('Location:')}</td>
                            <td>
                                {!editRequest && request.address?.name}

                                {editRequest && (
                                    <>
                                        <RequestLocation
                                            onCreateAddressHandler={onOpenAddressForm}
                                            addresses={addresses}
                                            recentAddresses={[]}
                                            requestAddressId={request.address_id}
                                            loadingLocations={loadingLocations}
                                            setRequestAddressId={setRequestAddressId}
                                        />

                                        <RequestCreationAddressFormDrawer
                                            departments={departments}
                                            onSaveAddressHandler={onAddressFormSaved}
                                            onFormClose={closeLocationForms}
                                            isAddressFormOpen={isAddressFormOpen}
                                        />
                                    </>
                                )}
                            </td>
                        </tr>
                        {!editRequest && (
                            <tr>
                                <td>{t('Address:')}</td>
                                <td>
                                    {request.address?.address}
                                    {(request.address?.zip_code || request.address?.city) && ', '}
                                    {request.address?.zip_code} {request.address?.city}
                                </td>
                            </tr>
                        )}
                        {(editRequest || request.department) && (
                            <tr>
                                <td>{t('Department:')}</td>
                                <td>
                                    {!editRequest && (
                                        <>
                                            {request.department?.name}
                                        </>
                                    )}

                                    {editRequest && (
                                        <SelectOrCreateDepartment
                                            departmentId={request.department_id}
                                            departments={departments}
                                            setDepartments={setDepartments}
                                            onDepartmentCreated={onDepartmentFormSaved}
                                            addresses={addresses}
                                            addressId={requestAddressId}
                                            onChange={onChangeHandler}
                                        />
                                    )}
                                </td>
                            </tr>
                        )}
                        {(editRequest || !!request.notes) && (
                            <tr>
                                <td>{t('Notes:')}</td>
                                <td>
                                    {
                                        editRequest ?
                                            <Input.TextArea
                                                onChange={({ target }: any) => updateRequest({ notes: target.value })}
                                                value={request.notes}
                                            /> :
                                            request.notes
                                    }
                                </td>
                            </tr>
                        )}
                        {(editRequest || !!request.documents?.length) && (
                            <tr>
                                <td>{t('Documents')}:</td>
                                <td>
                                    {!editRequest && (
                                        <Space wrap>
                                            {request.documents?.map(({ name, url }) => (
                                                <a href={url} target="_blank" key={url}>
                                                    <Space style={{ marginRight: '15px' }}>
                                                        {name}

                                                        <DownloadOutlined />
                                                    </Space>
                                                </a>
                                            ))}
                                        </Space>
                                    )}

                                    {editRequest && (
                                        <Documents
                                            btnClass="creation-buttons"
                                            btnStyle={{ marginLeft: 0 }}
                                            documents={request.documents}
                                            getRelationId={async () => request.id}
                                            onRemoved={onDocumentRemoved}
                                            onSaved={onDocumentSaved}
                                            relation="requests"
                                        />
                                    )}
                                </td>
                            </tr>
                        )}
                    </table>
                </div>
            </Form>

            {openQuotaModal && (
                <QuotaModal
                    open={openQuotaModal}
                    providers={providers}
                    request={request}
                    handleUpdateJobs={handleUpdateJobs}
                    setOpenModal={setOpenQuotaModal}
                    setRequest={updateRequest}
                />
            )}
        </>
    );
};
