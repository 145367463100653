import { cloneElement, useState } from 'react';

import 'shared/AntDesignUtils/FloatingLabel/FloatingLabel.scss';
import { GenericObject } from 'shared/Contracts';
import { isArray } from 'lodash';

type Props = GenericObject & {
    children: JSX.Element;
    label: string;
    placeholder?: string;
    required?: boolean;
};

export const FloatingLabel = ({ label, placeholder, children, required = false, ...otherProps }: Props) => {
    const [focus, setFocus] = useState(false);

    const { value } = otherProps;
    const isOccupied = focus || (value !== undefined && (!isArray(value) || value.length !== 0));
    const labelClass = ['label'];

    if (!isOccupied) {
        labelClass.push('placeholder');
    }

    if (focus) {
        labelClass.push('focused');
    }

    if (children.props.prefix) {
        labelClass.push('prefix');
    }

    const requiredMark = required ? <span className="text-danger">*</span> : null;

    if (!placeholder) placeholder = children.props.placeholder || label;

    return (
        <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
            {cloneElement(children, { ...otherProps, placeholder: '' })}

            <label className={labelClass.join(' ')}>
                {isOccupied ? label : placeholder} {requiredMark}
            </label>
        </div>
    );
};
