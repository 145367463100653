import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Row, Tooltip } from 'antd';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { DepartmentForm } from 'pages/Client/Configuration/Departments/DepartmentForm';
import { DepartmentSelect } from 'pages/Client/Configuration/Departments/DepartmentSelect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GenericObject } from 'shared/Contracts';
import { Address, Department } from 'types/models';

const shiftsURL = '/data-management/work-shifts';

type Props = GenericObject & {
    addressId?: number;
    addresses?: Address[];
    departmentId?: number;
    departments?: Department[];
    disabled?: boolean;
    onChange?: (id: number) => void;
    onDepartmentCreated?: (department: Department) => void;
    setAddresses?: (addresses: Address[]) => void;
    setDepartments?: (departments: Department[]) => void;
    setIsSaving?: (isSaving: boolean) => void;
}

export const SelectOrCreateDepartment = (
    {
    addressId,
    addresses,
    departmentId,
    departments,
    disabled,
    onChange,
    onDepartmentCreated,
    setAddresses,
    setDepartments,
    setIsSaving,
    ...otherProps
    }: Props
) => {
    const handleError = useErrorHandler();
    const { t } = useLocalization();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [localDepartments, setLocalDepartments] = useState<Department[]>(departments || []);
    const [shifts, setShifts] = useState([]);

    const filteredDepartments = useMemo<Department[]>(
        () => {
            if (!addressId || !addresses) {
                return (departments || []);
            }

            const address = addresses.find(({ id }) => id === addressId);

            const filtered = (localDepartments || []).filter(({ id }) => address?.department_ids?.includes(id));

            return filtered.length > 0 ? filtered : (localDepartments || []);
        },
        [addressId, addresses, localDepartments]
    );

    const loadShifts = useCallback(async () => {
        try {
            const response = await Backend.get(shiftsURL);

            setShifts(response.data.workShifts);
        } catch (e) {
            handleError(e);
        }
    }, [handleError]);

    const onSavedDepartmentHandler = (department: Department) => {
        setIsFormOpen(false);
        setLocalDepartments([
            ...localDepartments,
            department
        ]);

        onDepartmentCreated?.(department);
        onChange?.(department.id);
    };

    useEffect(() => {
        loadShifts();
    }, [loadShifts]);

    useEffect(() => {
        if (localDepartments.length) {
            return;
        }

        setLocalDepartments(departments || []);
    }, [departments]);

    useEffect(() => {
        setDepartments?.(localDepartments);
    }, [localDepartments, setDepartments]);

    return (
        <>
            <Row>
                <Col xs={17}>
                    <Form.Item name="department_id">
                        <DepartmentSelect
                            defaultValue={departmentId}
                            departments={filteredDepartments}
                            disabled={disabled}
                            onChange={(value: any) => onChange?.(value)}
                            setDepartments={setLocalDepartments}
                        />
                    </Form.Item>
                </Col>
                <Col xs={4}>
                    <Tooltip title={t('Please click here to configure your departments')}>
                        <Button
                            disabled={disabled}
                            key="1"
                            icon={<PlusOutlined />}
                            onClick={() => setIsFormOpen(true)}
                            className="creation-buttons"
                        >
                            {t('Add department')}
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
            <Drawer
                title={t('Add Department')}
                width={500}
                onClose={() => setIsFormOpen(false)}
                open={isFormOpen}
            >
                <DepartmentForm
                    addressId={addressId}
                    onSavedDepartment={onSavedDepartmentHandler}
                    shifts={shifts}
                    {...otherProps}
                />
            </Drawer>
        </>
    );
};
