import 'shared/Organizations/Organization/OrganizationDetails.scss';

import { useLocalization } from 'lib/Localization';
import { Organization } from 'types/models';

type Props = {
    organization: Organization;
};

/**
 * Component to display organization details.
 *
 * @param organization
 * @returns {JSX.Element}
 * @constructor
 */
export const OrganizationDetails = ({ organization }: Props) => {
    const { t } = useLocalization();

    return (
        <div className="organization-details">
            <table id="details-table">
                <tbody>
                    <tr>
                        <td>{ t('Name') }</td>
                        <td>{ organization.company_name }</td>
                    </tr>
                    <tr>
                        <td>{ t('Phone') }</td>
                        <td>{ organization.phone_number }</td>
                    </tr>
                    <tr>
                        <td>{ t('Address 1') }</td>
                        <td>{ organization.address_line_1 }</td>
                    </tr>
                    <tr>
                        <td>{ t('Address 2') }</td>
                        <td>{ organization.address_line_2 || '-' }</td>
                    </tr>
                    <tr>
                        <td>{ t('Zip') }</td>
                        <td>{ organization.zip_code }</td>
                    </tr>
                    <tr>
                        <td>{ t('City') }</td>
                        <td>{ organization.city }</td>
                    </tr>
                    <tr>
                        <td>{ t('Country') }</td>
                        <td>{ organization.country.name }</td>
                    </tr>
                    <tr>
                        <td>{ t('Website') }</td>
                        <td>
                            { organization.website ? (
                                <a href={ organization.website } target="_blank" rel="noreferrer">
                                    { organization.website }
                                </a>
                            ) : '-' }
                        </td>
                    </tr>
                    <tr>
                        <td>{ t('Description') }</td>
                        <td>{ organization.description }</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
