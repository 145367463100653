import { Layout } from 'antd';
import { Request, Requests } from 'pages/Provider/Requests';
import { Workers } from 'pages/Provider/Workers';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { LoaderSpin } from 'shared/AntDesignUtils/Loaders';
import { Professions } from 'shared/Configuration/Professions';
import { Organization } from 'shared/Organizations/Organization';
import { Settings } from 'shared/Settings';
import { OrganizationType } from 'types';
import { Clients } from './Clients';

type Props = {
    setSidebarRequestNumber: Function;
};

/**
 * Render content of the provider sub application.
 *
 * @return {*}
 */
export const Provider = ({ setSidebarRequestNumber }: Props) => {
    return (
        <Layout.Content className="content-body mdc-elevation--z2">
            <Suspense fallback={<LoaderSpin />}>
                <Routes>
                    <Route path="requests" element={<Requests setSidebarRequestNumber={setSidebarRequestNumber} />} />
                    <Route path="requests/:id" element={<Request />} />
                    <Route path="clients" element={<Clients />} />
                    <Route path="workers" element={<Workers />} />
                    <Route path="configuration/organization"
                           element={<Organization organizationType={OrganizationType.PROVIDER} />} />
                    <Route path="configuration/professions" element={<Professions />} />
                    <Route path="settings" element={<Settings />} />

                    {/*Fallback empty page - has to be last in routes list*/}
                    <Route path="/" element={<Requests setSidebarRequestNumber={setSidebarRequestNumber} />} />
                </Routes>
            </Suspense>
        </Layout.Content>
    );
};
