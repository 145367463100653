import { useErrorHandler } from 'lib/ErrorHandling';
import { useLocalStorageState } from 'lib/Helpers/LocalStorageHelper';
import { useRecentItems } from 'lib/hooks';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { useEffect, useState } from 'react';
import { Select } from 'shared/AntDesignUtils/Select/Select';
import { GenericObject } from 'shared/Contracts';
import { Department } from 'types/models';

const departmentsURL = '/data-management/departments';

type Props = GenericObject & {
    departments: Department[];
    disabled?: boolean;
    setDepartments: (departments: Department[]) => void;
}

export const DepartmentSelect = ({ departments, disabled, setDepartments, ...otherProps }: Props) => {
    const handleError = useErrorHandler();
    const { t } = useLocalization();
    const { getSelectOptionsFor } = useRecentItems();

    const [loading, setLoading] = useState(false);
    const [recentDepartments] = useLocalStorageState('recentDepartments', []);

    const getDepartmentOptions = () => getSelectOptionsFor('departments', recentDepartments, departments);

    const loadDepartments = async () => {
        try {
            setLoading(true);

            const { data } = await Backend.get(`${departmentsURL}?collect=workShiftIds`);

            setDepartments(
                (data.departments || [])
                    .sort((d1: Department, d2: Department) => d1.name.localeCompare(d2.name))
                    .map((dept: Department) => {
                        dept.work_shift_ids = data.collections?.workShiftIds?.[dept.id] || [];

                        return dept;
                    })
            );
        } catch (e) {
            handleError(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (departments?.length) {
            return;
        }

        loadDepartments();
    }, [departments]);

    return (
        <Select
            disabled={disabled}
            filterOption={(input, option) =>
                (option!.label as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
            }
            optionFilterProp="label"
            options={getDepartmentOptions()}
            placeholder={
                loading ? t('Loading departments...') : t('Choose department')
            }
            {...otherProps}
            allowClear
            showSearch
        />
    );
};
