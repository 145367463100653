import './Select.scss';
import { useRef } from 'react';
import { Select as AntSelect } from 'antd';
import { DefaultOptionType, LabeledValue, RefSelectProps, SelectProps } from 'antd/lib/select';

type Props = SelectProps & {
    children?: React.ReactNode;
    closeOptionOnSelect?: boolean;
    onSelect?: (value: any, option: DefaultOptionType) => any;
};

export const Select = ({ children, closeOptionOnSelect = true, onSelect, ...props }: Props) => {
    const selectRef = useRef<RefSelectProps>(null);

    const handleSelect = (value: string | number | LabeledValue, option: DefaultOptionType) => {
        onSelect?.(value, option);

        if (closeOptionOnSelect) {
            selectRef?.current?.blur();
        }
    };

    return (
        <AntSelect ref={selectRef} onSelect={handleSelect} {...props}>
            {children}
        </AntSelect>
    );
};
