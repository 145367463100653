import { useEffect, useState } from 'react';
import { GenericObject } from 'shared/Contracts';
import { useLocation } from 'react-router-dom-v5-compat';

export type SearchFunctionParam = {
    param: string | null,
    results: GenericObject,
}

type SearchActions = {
    [index: string]: (arg: SearchFunctionParam) => Promise<any>|any;
}

export const useURLParams = <T>(actions?: SearchActions, done?: (results: T) => void | Promise<void>) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useState(new URLSearchParams(location.search));

    const actionKeys = Object.keys(actions ?? {});
    let currentIndex = -1;

    const executeKey = async (params: URLSearchParams, results: GenericObject = {}): Promise<any> => {
        currentIndex++;

        if (actionKeys.length === currentIndex) {
            currentIndex = -1; // reset index

            done?.(results as T);

            return results;
        }

        const key = actionKeys[currentIndex];

        if (!params.has(key)) {
            return await executeKey(params, results);
        }

        results[key] = await actions?.[key]({
            param: params.get(key),
            results,
        });

        return await executeKey(params, results);
    };

    const extractParams = () => {
        const params = new URLSearchParams(location.search);

        if (location.hash) {
            params.append(location.hash.substring(1), 'true');
        }

        setSearchParams(params);

        return params;
    };

    useEffect(() => {
        // executing already
        if (currentIndex > -1) {
            return;
        }

        const params = extractParams();

        if (!actionKeys.length) {
            done?.({} as T);

            return;
        }

        if (!actionKeys.some((key) => params.has(key))) {
            done?.({} as T);

            return;
        }

        executeKey(params);
    }, [location.pathname, location.search, location.hash]);

    return searchParams;
};
