import { useCallback, useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';
import { Button, Drawer } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { PlusOutlined } from '@ant-design/icons';
import { sortColumn } from 'lib/Helpers/TableHelper';
import { CategoriesTable } from './CategoriesTable';
import { CategoryForm } from './CategoryForm';
import { Category } from 'types/models';

/**
 * Stateful container component responsible for categories data management.
 *
 */
export const Categories = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState<Category>();

    /**
     * Fetch data form backend.
     *
     * @return {Promise<void>}
     */
    const loadCategoriesData = useCallback(async () => {
        try {
            setIsDataLoading(true);
            const response = await Backend.get('/data-management/categories');
            setCategories(response.data);
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataLoading(false);
        }
    }, [handleError]);

    /**
     * After the component renders
     */
    useEffect(() => {
        loadCategoriesData();
    }, [loadCategoriesData]);

    /**
     * Open the category form to create/update a category.
     *
     * @param category {object}
     * @return void
     */
    const openCategoryHandler = (category?: Category) => {
        setCategory(category);
        setIsFormOpen(true);
    };

    const headerButtons = [
        <Button key="1" type="primary" icon={<PlusOutlined />} onClick={() => openCategoryHandler()}>
            {t('Add Category')}
        </Button>,
    ];

    /**
     * Format categories.
     *
     * @param categories
     * @return {Array}
     */
    const formatCategories = (categories: Category[]) => {
        categories.sort((a, b) => sortColumn(a, b, 'name'));
        return categories.map((category) => ({
            key: category.id,
            ...category,
        }));
    };

    const formattedCategories = formatCategories(categories);

    return (
        <PageHeader title={t('Categories')} backIcon={false} ghost={false} extra={[headerButtons]}>
            <CategoriesTable
                categories={[...formattedCategories,...formattedCategories,...formattedCategories,...formattedCategories,...formattedCategories,...formattedCategories,]}
                openCategoryHandler={openCategoryHandler}
                loadCategoriesData={loadCategoriesData}
                isDataLoading={isDataLoading}
            />
            <Drawer
                title={category?.id ? t('Edit category') : t('Add category')}
                width={500}
                onClose={() => setIsFormOpen(false)}
                open={isFormOpen}
            >
                <CategoryForm
                    category={category}
                    loadCategoriesData={loadCategoriesData}
                    setIsFormOpen={setIsFormOpen}
                />
            </Drawer>
        </PageHeader>
    );
};
