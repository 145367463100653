import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { List } from 'antd';
import { useLocalization } from 'lib/Localization';
import { useImperativeHandle } from 'react';
import { GenericObject } from 'shared/Contracts';

type Props = {
    password: string;
    customRef?: any;
}

export const PasswordConditions = ({ password = '', customRef }: Props) => {
    const { t } = useLocalization();

    const conditions = [
        {
            title: t('Password must contain at least 8 characters'),
            isValid(password: string): boolean {
                return password.length >= 8;
            }
        },
        {
            title: t('Password must contain at least 1 uppercase letter'),
            isValid(password: string): boolean {
                return /[A-Z]/.test(password);
            }
        },
        {
            title: t('Password must contain at least 1 lowercase letter'),
            isValid(password: string): boolean {
                return /[a-z]/.test(password);
            }
        },
        {
            title: t('Password must contain at least 1 number'),
            isValid(password: string): boolean {
                return /[0-9]/.test(password);
            }
        },
        {
            title: t('Password must contain at least 1 symbol'),
            isValid(password: string): boolean {
                return /[^A-Z0-9]/i.test(password);
            }
        }
    ];

    const ok = (<CheckCircleOutlined style={{ marginRight: '5px', fontSize: '17px' }} />);
    const error = (<CloseCircleOutlined style={{ marginRight: '5px', fontSize: '17px' }} />);

    const validate = (password: string) => !conditions.find(({ isValid }) => !isValid(password));

    useImperativeHandle(customRef, () => ({ validate }), [validate]);

    const getItem = ({ title, isValid }: GenericObject) =>  (
        <List.Item style={ { color: isValid(password) ? 'green' : 'red', padding: '8px 0' } }>
            { isValid(password) ? ok : error } { title }
        </List.Item>
    );

    if (!password.length || validate(password)) {
        return null;
    }

    return (
        <List
            header={ <div className="text-bold">{ t('Password conditions') }:</div> }
            dataSource={ conditions }
            renderItem={ getItem }
            size="small"
        />
    );
};
