import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { useLocalization } from 'lib/Localization';
import { OneOrMore } from 'shared/OneOrMore/OneOrMore';
import { Agent, Organization, Profession } from 'types/models';
import { OrganizationTab } from './ConnectedOrganizations/ConnectedOrganizations';
import { useTable } from 'lib/hooks/useTable';
import { useEffect } from 'react';

type Props = {
    isClient: boolean;
    isDataLoading: boolean;
    organizations: Organization[];
    showOrganizationDetailsHandler: (organizationId: number, tab: OrganizationTab) => void;
};

/**
 * Table to display organizations of provider/client.
 *
 * @param organizations
 * @param showOrganizationDetailsHandler
 * @param isDataLoading
 * @returns {JSX.Element}
 * @constructor
 */
export const OrganizationsTable = ({
    isClient,
    isDataLoading,
    organizations,
    showOrganizationDetailsHandler,
}: Props) => {
    const { t } = useLocalization();
    const { tableParams, handleTableChange, setTotal } = useTable();

    /**
     * Render cell for organization name.
     *
     * @param record { object }
     * @return {*}
     */
    const renderNameCell = (record: Organization) => (
        <Button type="link" onClick={ () => showOrganizationDetailsHandler(record.id, 'general') }>
            { record.company_name }
        </Button>
    );

    useEffect(() => {
        setTotal(organizations.length);
    }, [organizations]);

    const columns: ColumnsType<Organization> = [
        {
            title: t('Name'),
            dataIndex: 'company_name',
            key: 'company_name',
            sorter: (a, b) => sortColumn(a, b, 'company_name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('company_name'),
            render: (_, record) => renderNameCell(record),
        },
    ];

    if (isClient) {
        columns.push(
            {
                title: t('Agents'),
                dataIndex: 'agents',
                render: (agents, record) => (
                    <OneOrMore
                        items={ agents.map(({ first_name, last_name }: Agent) => `${ first_name } ${ last_name }`) }
                        max={ 2 }
                        onMoreClick={ () => showOrganizationDetailsHandler(record.id, 'members') }
                    />
                ),
            },
            {
                title: t('Professions'),
                dataIndex: 'professions',
                render: (professions, record) =>
                    <OneOrMore
                        items={ professions.map(({ name }: Profession) => name) }
                        max={ 2 }
                        onMoreClick={ () => showOrganizationDetailsHandler(record.id, 'professions') }
                    />,
            }
        );
    } else {
        columns.push({
            title: t('Contacts'),
            dataIndex: 'contacts',
            render: (contacts, record) => (
                <OneOrMore
                    items={ contacts.map(({ first_name, last_name }: Agent) => `${ first_name } ${ last_name }`) }
                    max={ 2 }
                    onMoreClick={ () => showOrganizationDetailsHandler(record.id, 'members') }
                />
            ),
        });
    }

    columns.push(
        {
            title: t('City'),
            dataIndex: 'city',
            key: 'city',
            sorter: (a, b) => sortColumn(a, b, 'city'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('city'),
        },
        {
            title: t('Phone'),
            dataIndex: 'phone_number',
            key: 'phone_number',
            sorter: (a, b) => sortColumn(a, b, 'phone_number'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('phone_number'),
        }
    );

    return (
        <Table
            rowKey="id"
            columns={ columns }
            dataSource={ organizations }
            pagination={ tableParams.pagination }
            loading={ isDataLoading }
            onChange={ handleTableChange }
            scroll={ { scrollToFirstRowOnChange: true, y: 'calc(100vh - 320px)' } }
            size="small"
        />
    );
};
