import { Button, notification, Popconfirm, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useErrorHandler } from 'lib/ErrorHandling';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import moment from 'moment';
import { OrganizationType } from 'types';
import { Invitation, RemappedInvitation } from 'types/models';
import { InvitationActions } from 'types/models/Invitation';

import 'shared/Organizations/Organization/Invitation/MyInvitationsTable.scss';
import { useTable } from 'lib/hooks/useTable';
import { useEffect } from 'react';

type Props = {
    invitations: Invitation[];
    isInvitationsLoading: boolean;
    organizationType: OrganizationType;
    loadInvitations: Function;
};

/**
 * Table to display invitations sent to other organizations.
 *
 * @param invitations
 * @param isInvitationsLoading
 * @param organizationType
 * @param loadInvitations
 * @returns {JSX.Element}
 * @constructor
 */
export const MyInvitationsTable = ({ invitations, isInvitationsLoading, organizationType, loadInvitations }: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const { tableParams, handleTableChange, setTotal } = useTable();

    const remappedInvitations = invitations.map((invitationItem: Invitation) => {
        return {
            id: invitationItem.id,
            invitationToken: invitationItem.invitation_token,
            invitationType: invitationItem.invitation_type,
            name: invitationItem.user?.first_name.concat(' ', invitationItem.user?.last_name),
            firstName: invitationItem.data?.first_name,
            lastName: invitationItem.data?.last_name,
            email: invitationItem.email,
            company: invitationItem.data?.company_name,
            category: (invitationItem.data?.company_categories || ['-']).join(', '),
            status: invitationItem.status,
            status_message: '',
            send_date: invitationItem.created_at,
            sent_by: invitationItem.data?.company_name,
            accountType: invitationItem.account_type,
            message: invitationItem.message,
        };
    });

    const handleInvitationStatusRequest = async (token: string, action: InvitationActions) => {
        try {
            const invitationResponse = await Backend.get('/account/invitation/' + action + '/' + token);

            if (invitationResponse.data === false) {
                throw new Error('Could not set invitation status');
            }

            notification.success({
                message: t('Invitation status successfully set to ' + action),
            });
        } catch (error) {
            handleError(error);
        } finally {
            loadInvitations();
        }
    };

    useEffect(() => {
        setTotal(invitations.length);
    }, [invitations]);

    const columns: ColumnsType<RemappedInvitation> = [
        {
            title: t('From'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => sortColumn(a, b, 'name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('name'),
            width: 200,
        },
        {
            title: t('Categories'),
            dataIndex: 'category',
            key: 'category',
            sorter: (a, b) => sortColumn(a, b, 'category'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('category'),
            width: 200,
        },
        {
            title: t('Send date'),
            dataIndex: 'send_date',
            key: 'send_date',
            sorter: (a, b) => sortColumn(a, b, 'send_date'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('send_date'),
            render: (value) => moment(value).format('DD.MM.YYYY'),
            width: 150,
        },
        {
            title: t('Message'),
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: t('Actions'),
            key: 'actions',
            width: 200,
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        className="success-button"
                        onClick={() => handleInvitationStatusRequest(_.invitationToken, InvitationActions.ACCEPTED)}
                    >
                        Accept
                    </Button>
                    <Popconfirm
                        title={t('Are you sure you want to decline the invitation?')}
                        onConfirm={() => handleInvitationStatusRequest(_.invitationToken, InvitationActions.DECLINED)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button className="danger-button">{t('Decline')}</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey="id"
            className="my-invitations-table"
            columns={columns}
            dataSource={remappedInvitations}
            pagination={tableParams.pagination}
            loading={isInvitationsLoading}
            onChange={handleTableChange}
            scroll={{ scrollToFirstRowOnChange: true, y: 'calc(100vh - 320px)' }}
            size="small"
        />
    );
};
