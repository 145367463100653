import type { SelectProps } from 'antd';
import { Select } from 'antd';
import { useState } from 'react';
import { GenericObject } from 'shared/Contracts';
import { Profession, Worker } from 'types/models';

type ProfessionMultiSelectProps = GenericObject & {
    defaultSelections: Profession[];
    handleProfessionChange: (worker: Worker, selectedProfessions: Profession[]) => void;
    optionValue?: string;
    translatedProfessions: Profession[];
    worker: Worker;
};

const ProfessionMultiSelect = ({
    defaultSelections,
    handleProfessionChange,
    optionValue = 'id',
    translatedProfessions,
    worker,
    ...otherProps
}: ProfessionMultiSelectProps) => {
    const [allowClear, setAllowClear] = useState(false);
    const [open, setOpen] = useState(false);

    const options: SelectProps['options'] = translatedProfessions?.map((profession: Profession) => ({
            key: profession.id,
            label: profession.name,
            value: profession[optionValue],
        })) ?? [];

    const existingProfessions = defaultSelections?.map((profession: Profession) => profession.name);

    const toggleOpen = () => {
        setOpen((prevState) => !prevState);
    };

    return (
        <Select
            allowClear={allowClear}
            mode="multiple"
            style={ {
                width: '301px',
                maxWidth: '100%',
            }}
            defaultValue={existingProfessions}
            onBlur={() => {
                setAllowClear(false);
                setOpen(false);
            }}
            onChange={(professions: string[]) => {
                const selectedProfessions = translatedProfessions.filter((profession: Profession) => {
                    const translations = profession.translations?.map(({ name }) => name) || [];

                    return (
                        translations.some((name) => professions.includes(name)) ||
                        professions.includes(profession.name)
                    );
                });

                handleProfessionChange(worker, selectedProfessions);
            }}
            onClick={(e: any) => {
                e.stopPropagation();

                // leave open if option is selected
                if (e.target.classList.contains('ant-select-item') || e.target.closest('.ant-select-item')) {
                    return;
                }

                toggleOpen();
            }}
            onFocus={() => setAllowClear(true)}
            open={open}
            options={options}
            {...otherProps}
        />
    );
};

export { ProfessionMultiSelect };
