import './Avatar.scss';
import { PlusOutlined } from '@ant-design/icons';

import { Avatar as AntAvatar, Button, FormInstance, notification, Space, Upload, UploadProps } from 'antd';
import { useLocalization } from 'lib/Localization';
import { useMemo, useState } from 'react';
import { GenericObject } from 'shared/Contracts';

const BYTE_RATE = 1000000;
const DEFAULT_FILE_SIZE = 2 * BYTE_RATE;

const getBase64 = (img: File, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

const stringToHslColor = (str: string, s: number = 50, l: number = 50) => {
    let hash = 0;
    const strLength = str?.length ?? 0;

    for (let i = 0; i < strLength; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;

    return `hsl(${ h }, ${ s }%, ${ l }%)`;
}

type Props = GenericObject & {
    disabled?: boolean;
    form?: FormInstance;
    initials?: string;
    label?: string;
    maxFileSize?: number;
    name?: string;
    onChange?: (img?: File) => void;
    readonly?: boolean;
    size?: number;
    src?: string;
};

export const Avatar = ({
    disabled,
    form,
    initials,
    label,
    maxFileSize,
    name,
    onChange,
    readonly,
    size = 30,
    src = '',
    ...otherProps
}: Props) => {
    const { t } = useLocalization();

    const [imageUrl, setImageUrl] = useState<string>(src);

    const handleChange: UploadProps['onChange'] = ({ file }) => {
        const maxSize = maxFileSize || DEFAULT_FILE_SIZE;

        if ((file?.size || 0) > maxSize) {
            notification.error({
                message: t('Image cannot be more than %{size}', { size: Math.round(maxSize / BYTE_RATE) + ' MB' })
            });

            return;
        }

        getBase64(file as unknown as File, (url) => setImageUrl(url));

        onChange?.(file as unknown as File);
        form?.setFieldValue(name || 'avatar', file);
    };

    const avatarImage = useMemo(() => (
        <AntAvatar
            alt="avatar"
            gap={ 4 }
            size={ size }
            src={ imageUrl ? <img src={ imageUrl } alt="" /> : null }
            style={ initials && !imageUrl ? { backgroundColor: stringToHslColor(initials) } : {} }
        >
            { initials }
        </AntAvatar>
    ), [imageUrl, initials, size]);

    return (
        <Space { ...otherProps }>
            { !readonly && (
                <>
                    <Upload
                        accept="image/*"
                        beforeUpload={ () => false }
                        className="custom-avatar-uploader"
                        disabled={ disabled }
                        listType="picture-circle"
                        name="avatar"
                        onChange={ handleChange }
                        showUploadList={ false }
                    >
                        { !!imageUrl && avatarImage }

                        { !imageUrl && (
                            <div id="button-wrapper" style={ { height: `${ size }px`, width: `${ size }px` } }>
                                <button disabled={ disabled } type="button">
                                    <PlusOutlined />
                                    <div style={ { marginTop: 8 } }>{ label || t('Avatar') }</div>
                                </button>
                            </div>
                        ) }
                    </Upload>

                    { !!imageUrl && (
                        <Button
                            type="primary"
                            onClick={ () => {
                                setImageUrl('');
                                onChange?.();
                                form?.setFieldValue(name || 'avatar', undefined);
                            } }>
                            { t('Change') }
                        </Button>
                    ) }
                </>
            ) }

            { readonly && avatarImage }
        </Space>
    );
};
