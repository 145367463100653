import { EditOutlined } from '@ant-design/icons';
import { Button, notification, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { useErrorHandler } from 'lib/ErrorHandling';
import { localizeProfessions } from 'lib/Helpers/ProfessionHelper';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { useTable } from 'lib/hooks/useTable';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { DeletePopconfirm } from 'shared/AntDesignUtils/DeletePopconfirm';
import { Profession } from 'types/models';

const professionPath = '/admin/configuration/profession';

type Props = {
    professions: Profession[];
    countryOptions: ColumnFilterItem[];
    categoryOptions: ColumnFilterItem[];
    loadProfessionsData: () => Promise<void>;
    isDataLoading: boolean;
};

/**
 * Table to display professions
 *
 * @param professions
 * @param countryOptions
 * @param categoryOptions
 * @param loadProfessionsData
 * @param isDataLoading
 * @returns {JSX.Element}
 */
export const ProfessionsTable = ({
    professions,
    countryOptions,
    categoryOptions,
    loadProfessionsData,
    isDataLoading,
}: Props) => {
    const { t, locale } = useLocalization();
    const handleError = useErrorHandler();
    const { tableParams, handleTableChange, setTotal } = useTable();

    const [isDataDeleting, setIsDataDeleting] = useState(false);

    /**
     * Delete a profession by id.
     *
     * @param id {int}
     * @return {Promise<void>}
     */
    const deleteProfessionHandler = async (id: number) => {
        try {
            setIsDataDeleting(true);
            const response = await Backend.delete(`/data-management/professions/${ id }`);
            if (response.status === Backend.responseStatus.HTTP_NO_CONTENT) {
                notification.success({
                    message: t('Profession successfully removed'),
                });
                loadProfessionsData();
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataDeleting(false);
        }
    };

    /**
     * Render cell for profession name.
     *
     * @param record { object }
     * @return {*}
     */
    const renderNameCell = (record: Profession) => {
        return <Link to={ `${ professionPath }/${ record.id }` }>{ record.name }</Link>;
    };

    /**
     * Render cell for profession's country name.
     *
     * @param record { object }
     * @returns {*}
     */
    const renderCountryCell = (record: Profession) => (record.country?.name ? t(record.country?.name) : '');

    /**
     * Render cell for profession's category name.
     *
     * @param record { object }
     * @returns {*}
     */
    const renderCategoryCell = (record: Profession) => (record.category?.name ? t(record.category?.name) : '');

    /**
     * Render a cell with action buttons.
     *
     * @param key {string}
     * @return {*}
     */
    const renderActionCell = (profession: Profession) => (
        <Space>
            <Link to={ `${ professionPath }/${ profession.id }` }>
                <Button icon={ <EditOutlined /> } />
            </Link>
            <DeletePopconfirm
                title={ t('Are you sure you want to delete this profession?') }
                id={ profession.id }
                isDataDeleting={ isDataDeleting }
                deleteHandler={ deleteProfessionHandler }
            />
        </Space>
    );

    useEffect(() => {
        setTotal(professions.length);
    }, [professions]);

    const columns: ColumnsType<Profession> = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => sortColumn(a, b, 'name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('name'),
            render: (name, record) => renderNameCell(record),
        },
        {
            title: t('Description'),
            dataIndex: 'description',
            key: 'description',
            ...getColumnSearchProps('description'),
        },
        {
            title: t('Category'),
            dataIndex: 'category_id',
            key: 'category_id',
            filters: categoryOptions,
            filterMultiple: true,
            filterSearch: true,
            onFilter: (value, record) => record.category_id === value,
            sorter: (a, b) => sortColumn(a.category, b.category, 'name'),
            sortDirections: ['ascend', 'descend'],
            render: (name, record) => renderCategoryCell(record),
        },
        {
            title: t('Country'),
            dataIndex: 'country_id',
            key: 'country_id',
            filters: countryOptions,
            filterMultiple: true,
            filterSearch: true,
            onFilter: (value, record) => record.country_id === value,
            sorter: (a, b) => sortColumn(a.country, b.country, 'name'),
            sortDirections: ['ascend', 'descend'],
            render: (name, record) => renderCountryCell(record),
        },
        {
            title: t('Actions'),
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => renderActionCell(record),
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={ columns }
            dataSource={ localizeProfessions(professions, locale) }
            pagination={ tableParams.pagination }
            loading={ isDataLoading }
            onChange={ handleTableChange }
            scroll={ { scrollToFirstRowOnChange: true, y: 'calc(100vh - 260px)' } }
            size="small"
        />
    );
};
