import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, notification, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { useTable } from 'lib/hooks/useTable';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { ProfessionMultiSelect } from 'pages/Provider/Workers/ProfessionMultiSelect';
import { useEffect, useMemo, useState } from 'react';
import { Avatar } from 'shared/AntDesignUtils/Avatar/Avatar';
import { DeletePopconfirm } from 'shared/AntDesignUtils/DeletePopconfirm';
import { Profession, Worker } from 'types/models';

type Props = {
    workersUrl: string;
    workers: Worker[];
    openHandler: (worker?: Worker) => void;
    isDataLoading: boolean;
    handleProfessionChange: (worker: Worker, selectedProfessions: Profession[]) => Promise<void>;
    removeWorker: (worker: Worker) => void;
    showDetails: (worker: Worker) => void;
    translatedProfessions: Profession[];
};

export const WorkersTable = ({
    workersUrl,
    workers,
    openHandler,
    isDataLoading,
    handleProfessionChange,
    removeWorker,
    showDetails,
    translatedProfessions,
}: Props) => {
    const { t } = useLocalization();
    const { tableParams, handleTableChange, setTotal } = useTable();

    const [key, setKey] = useState(0);
    const [isDataDeleting, setIsDataDeleting] = useState(false);

    const sortedWorkers = useMemo(
        () => workers.sort((workerA, workerB) => (workerA.id < workerB.id ? 1 : workerB.id < workerA.id ? -1 : 0)),
        [workers]
    );

    const renderEmailCell = (email: string) => (
        <a href={ `mailto:${ email }` } target="blank">
            { email }
        </a>
    );

    const renderProfessionCell = (professions: Profession[], worker: Worker) => (
        <ProfessionMultiSelect
            className="no-min-height"
            translatedProfessions={ translatedProfessions }
            defaultSelections={ professions }
            handleProfessionChange={ handleProfessionChange }
            optionValue="name"
            size="small"
            suffixIcon={ <PlusOutlined /> }
            worker={ worker }
        />
    );

    const renderActionCell = (record: Worker) => (
        <Space>
            <EditOutlined onClick={ () => openHandler(record) } />
            <DeletePopconfirm
                title={ t('Are you sure you want to delete this worker?') }
                id={ record.id }
                isDataDeleting={ isDataDeleting }
                deleteHandler={ () => deleteWorkerHandler(record) }
            />
        </Space>
    );

    const renderNameCell = (value: string, record: Worker) => (
        <Button type="link" onClick={ () => showDetails(record) }>
            { value }
        </Button>
    );

    const deleteWorkerHandler = async (worker: Worker) => {
        try {
            setIsDataDeleting(true);

            const response = await Backend.delete(`${ workersUrl }/${ worker.id }`);

            if (response.status === Backend.responseStatus.HTTP_NO_CONTENT) {
                notification.success({
                    message: t('Worker successfully removed'),
                });
            }

            removeWorker(worker);
        } catch (error) {
            notification.error({
                message: t('Already assigned workers cannot be deleted.'),
            });
        } finally {
            setIsDataDeleting(false);
        }
    };

    const columns: ColumnsType<Worker> = [
        {
            dataIndex: 'avatar',
            render: (url, worker) => <Avatar initials={ worker.first_name[0] + worker.last_name[0] } size={ 25 } src={ url } readonly />,
            width: 40,
        },
        {
            title: t('First name'),
            dataIndex: 'first_name',
            sorter: (a, b) => sortColumn(a, b, 'first_name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('first_name'),
            render: renderNameCell,
        },
        {
            title: t('Last name'),
            dataIndex: 'last_name',
            sorter: (a, b) => sortColumn(a, b, 'last_name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('last_name'),
            render: renderNameCell,
        },
        {
            title: t('Profession'),
            dataIndex: 'professions',
            key: 'professions',
            ...getColumnSearchProps('professions'),
            render: renderProfessionCell,
        },
        {
            title: t('Email'),
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => sortColumn(a, b, 'email'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('email'),
            render: (email) => renderEmailCell(email),
        },
        {
            title: t('Phone number'),
            dataIndex: 'phone_number',
            key: 'phone_number',
            sorter: (a, b) => sortColumn(a, b, 'phone_number'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('phone_number'),
        },
        {
            title: t('Actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value, record) => renderActionCell(record),
            width: 80,
        },
    ];

    useEffect(() => {
        setTotal(sortedWorkers.length);
    }, [sortedWorkers]);

    useEffect(() => {
        // even though workers get updated and the professions field of each worker object as well,
        // the professions table column doesn't re-render, so we need to force it to re-render
        setKey(key + 1);
    }, [workers]);

    return (
        <Table
            key={ key }
            rowKey="id"
            columns={ columns }
            dataSource={ sortedWorkers }
            pagination={ tableParams.pagination }
            loading={ isDataLoading }
            onChange={ handleTableChange }
            scroll={ { scrollToFirstRowOnChange: true, y: 'calc(100vh - 260px)' } }
            size="small"
        />
    );
};
