import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { useTable } from 'lib/hooks/useTable';
import { useLocalization } from 'lib/Localization';
import _ from 'lodash';
import { useEffect } from 'react';
import { Category, Profession } from 'types/models';

type Props = {
    professions: Profession[];
};

/**
 * Table to display connected organization's professions
 *
 * @param professions

 * @returns {JSX.Element}
 */
export const OrganizationDetailsProfessionsTable = ({ professions }: Props) => {
    const { t } = useLocalization();
    const { tableParams, handleTableChange, setTotal } = useTable();

    useEffect(() => {
        setTotal(professions.length);
    }, [professions]);

    const categories = _.uniqBy(
        professions.filter((profession) => profession.category).map((profession) => profession.category),
        'id'
    ) as Category[];

    const columns: ColumnsType<Profession> = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => sortColumn(a, b, 'name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('name'),
            render: (name) => name,
        },
        {
            title: t('Category'),
            dataIndex: 'category_id',
            key: 'category_id',
            filters: categories.map((category: Category) => ({
                value: category.id,
                text: t(category.name),
            })),
            filterMultiple: true,
            filterSearch: true,
            onFilter: (value, record) => record.category_id === value,
            sorter: (a, b) => sortColumn(a.category, b.category, 'name'),
            sortDirections: ['ascend', 'descend'],
            render: (categoryId, record) => (record.category?.name ? t(record.category?.name) : ''),
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={columns}
            dataSource={professions}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            scroll={{ scrollToFirstRowOnChange: true, y: 'calc(100vh - 270px)' }}
            size="small"
        />
    );
};
