import { Button, Col, Row, Tabs, TabsProps, Tag } from 'antd';
import { useLocalization } from 'lib/Localization';
import { Avatar } from 'shared/AntDesignUtils/Avatar/Avatar';
import { Documents } from 'shared/Documents';
import { Worker } from 'types/models';

const DEFAULT_TAB = 'bio';

type Props = {
    onEdit: () => void;
    worker: Worker;
};

export const WorkerDetails = ({ onEdit, worker }: Props) => {
    const { t } = useLocalization();

    const items: TabsProps['items'] = [
        {
            key: DEFAULT_TAB,
            label: t('Bio'),
            children: (
                <>
                    <table className="label-value">
                        <tbody>
                            <tr>
                                <td className="full-opacity">
                                    <Avatar
                                        key={ worker.id }
                                        initials={ worker.first_name[0] + worker.last_name[0] }
                                        src={ worker.avatar }
                                        size={ 100 }
                                        readonly
                                    />
                                </td>
                                <td>
                                    <h2 style={ { marginBottom: 0 } }>
                                        { worker.first_name } { worker.last_name }
                                    </h2>

                                    <div style={ { marginBottom: 10 } }>
                                        <a href={ `mailto:${ worker.email }` }>
                                            { worker.email }
                                        </a>
                                    </div>

                                    <Button size="small" onClick={ onEdit }>
                                        { t('Edit') }
                                    </Button>
                                </td>
                            </tr>

                            { !!worker.phone_number && (
                                <tr>
                                    <td>
                                        { t('Phone number') }
                                    </td>
                                    <td>
                                        <a href={ `tel:${ worker.phone_number }` }>
                                            { worker.phone_number }
                                        </a>
                                    </td>
                                </tr>
                            ) }

                            { !!worker.gender && (
                                <tr>
                                    <td>
                                        { t('Gender') }
                                    </td>
                                    <td className="capitalize">
                                        { worker.gender }
                                    </td>
                                </tr>
                            ) }

                            { !!worker.cv && (
                                <tr>
                                    <td>
                                        { t('CV') }
                                    </td>
                                    <td>
                                        <a href={ worker.cv } target="_blank" rel="noreferrer">
                                            { t('Download') }
                                        </a>
                                    </td>
                                </tr>
                            ) }

                            { !!worker.external_id && (
                                <tr>
                                    <td>
                                        { t('External id') }
                                    </td>
                                    <td>
                                        { worker.external_id }
                                    </td>
                                </tr>
                            ) }

                            { !!worker.professions.length && (
                                <tr>
                                    <td>
                                        { t('Professions') }
                                    </td>
                                    <td>
                                        { worker.professions.map(({ name }) => <Tag>{ name }</Tag>) }
                                    </td>
                                </tr>
                            ) }

                            { !!worker.shirt_size && (
                                <tr>
                                    <td>
                                        { t('Shirt size') }
                                    </td>
                                    <td>
                                        { worker.shirt_size }
                                    </td>
                                </tr>
                            ) }

                            { !!worker.trousers_size && (
                                <tr>
                                    <td>
                                        { t('Trousers size') }
                                    </td>
                                    <td>
                                        { worker.trousers_size }
                                    </td>
                                </tr>
                            ) }
                        </tbody>
                    </table>

                    { !!worker.additional_information && (
                        <Row style={ { marginTop: 15 } }>
                            <Col span={ 24 }>
                                <div className="opacity-half">
                                    { t('Additional information for clients') }
                                </div>
                            </Col>

                            <Col span={ 24 }>
                                { worker.additional_information }
                            </Col>
                        </Row>
                    ) }
                </>
            )
        },
        {
            key: 'docs',
            label: t('Other documents'),
            children: (
                <>
                    { !worker.documents.length && (
                        <div>{ t('No documents') }</div>
                    ) }

                    <Documents
                        key={ worker.id }
                        documents={ worker.documents }
                        relation="workers"
                        readonly
                    />
                </>
            )
        }
    ];

    return (
        <Tabs items={ items } animated />
    );
};
