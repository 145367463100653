import { Button, Col, Form, notification, Row } from 'antd';
import { useLocalization } from 'lib/Localization';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
import { Select } from 'shared/AntDesignUtils/Select/Select';
import { useEffect, useState } from 'react';
import { useAccount } from 'lib/Account';
import { userIsClient } from 'lib/Helpers/UserHelper';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { ClientProviderSettings } from 'types/staffing/ClientProviderSettings';

export const OrganizationSettings = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const { accountUser, setAccountUser } = useAccount();
    const isClient = userIsClient(accountUser);

    const [settings, setSettings] = useState<ClientProviderSettings>({} as ClientProviderSettings);
    const [saving, setSaving] = useState(false);

    const setSettingValue = (id: string, value: any) =>
        setSettings((prevState) => ({
            ...prevState,
            [id]: value,
        }));

    const save = async () => {
        try {
            setSaving(true);

            await Backend.put(`data-management/organization-settings/${settings.id}`, {
                work_time_by_provider: settings.work_time_by_provider,
            });

            const connectedOrganizationSettings = accountUser.connectedOrganizationSettings;
            const index = connectedOrganizationSettings.findIndex(({ id }) => id === settings.id);

            if (index === -1) {
                connectedOrganizationSettings.push(settings);
            } else {
                connectedOrganizationSettings.splice(index, 1, settings);
            }

            setAccountUser({
                ...accountUser,
                connectedOrganizationSettings,
            });

            notification.success({
                message: t('Settings saved'),
            });
        } catch (error) {
            handleError(error);
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        setSettings(
            (accountUser.connectedOrganizationSettings.find(({ provider_id, client_id }) =>
                isClient ? client_id === accountUser.organization.id : provider_id === accountUser.organization.id
            ) || {}) as unknown as ClientProviderSettings
        );
    }, [accountUser, isClient]);

    return (
        <Row>
            <Col span={24}>
                <Form.Item>
                    <FloatingLabel label={t('Work time')} value={settings.work_time_by_provider} required>
                        <Select
                            onSelect={(value) => setSettingValue('work_time_by_provider', value)}
                            options={
                                [
                                    { label: t('Provider enters work times, client confirms'), value: true },
                                    { label: t('Client enters work times, provider confirms'), value: false },
                                ] as unknown as DefaultOptionType[]
                            }
                            style={{ width: '100%' }}
                            value={settings.work_time_by_provider}
                        />
                    </FloatingLabel>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Button loading={saving} type="primary" onClick={save}>
                    {t('Save')}
                </Button>
            </Col>
        </Row>
    );
};
