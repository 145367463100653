import dayjs from 'dayjs';
import { useAccount } from 'lib/Account';
import { useErrorHandler } from 'lib/ErrorHandling';
import { userIsClient } from 'lib/Helpers/UserHelper';
import { Backend } from 'lib/Http/Backend';
import { OrderFormRequest } from 'pages/Client/Requests/Create/RequestCreation';
import { useEffect, useState } from 'react';
import { formatJobs } from 'shared/Requests/helpers';
import { Job, Request } from 'types/models';
import { FormattedJob, ProviderRequestState } from 'types/staffing';
import { FormRequestTime } from 'types/staffing/FormRequest';
import { JobState } from 'types/staffing/JobStateMachine';

export const getConfirmedPositionsCount = (request: Request, isClient: boolean) =>
    request.jobs.filter(({ confirmed_start_time, state }) =>
        isClient ? state === JobState.TIME_CONFIRMED : !!confirmed_start_time
    ).length;

export const useRequestActions = (request?: Request, setRequest?: (request: Request) => void) => {
    const handleError = useErrorHandler();
    const { accountUser: user } = useAccount();

    const isClient = userIsClient(user);

    const [movablePastJobs, setMovablePastJobs] = useState<Job[]>([]);

    useEffect(() => {
        if (!request) {
            setMovablePastJobs([]);

            return;
        }

        setMovablePastJobs(getMovablePastJobs());
    }, [request]);

    const canDeleteRequest = (_request: Request) => !hasUnexpiredJobs(_request) && (_request || request)?.provider_requests?.every(
        ({ state }) => [ProviderRequestState.OPEN, ProviderRequestState.DRAFT, ProviderRequestState.ACCEPTED].includes(state as any)
    );

    const canMoveToHistory = (_request: Request) => formatJobs((_request || request)?.jobs || [], isClient)?.every((job) => canMoveJobToHistory(job));

    const canMoveJobToHistory = (job: FormattedJob) =>
        ((isClient && !job.is_client_archived) || (!isClient && !job.is_provider_archived)) &&
        jobIsPassed(job);

    const getDateTimes = ({ dates, differentTimes }: OrderFormRequest, index = 0) => {
        if (!(dates || []).length) {
            return [];
        }

        let key = 0;

        if (differentTimes) {
            key = dates?.[index] ? index : 0;
        }

        return dates?.[key].times;
    };

    const getMovablePastJobs = (fromRequest?: Request) =>
        formatJobs((fromRequest || request)?.jobs || [], isClient)
            .filter((job) => jobIsPassed(job))
            .filter((job) => (isClient && !job.is_client_archived) || (!isClient && !job.is_provider_archived));

    const getProviderWorkerQuota = (request: OrderFormRequest, providerId: number) => {
        const activeJobs = (request.jobs || []).filter(({ state }) => state !== JobState.CANCELED);
        const quotaIsNotEmpty = activeJobs.some((job) => !!job.providers?.length);

        if (activeJobs.length) {
            if (quotaIsNotEmpty) {
                return activeJobs.reduce((prev, { providers, state }) => {
                    if (state !== JobState.CANCELED && (!providers?.length || providers?.includes(providerId))) {
                        return prev + 1;
                    }

                    return prev;
                }, 0);
            } else {
                return activeJobs.length;
            }
        }

        return getTotalWorkers(request) * (request.selectedDates?.length || 1);
    };

    const getTotalDateTimesWorkers = (dateTimes: FormRequestTime[]) =>
        dateTimes.reduce((total, { workersNumber }: any) => total + parseInt(workersNumber), 0);

    const getTotalWorkers = (request: OrderFormRequest, index?: number) => {
        const dateTimes = getDateTimes(request, index) || [];

        return getTotalDateTimesWorkers(dateTimes);
    };

    const hasExpiredJobs = (inRequest?: Request) => (inRequest || request)?.jobs
        .some((job) => dayjs(`${job.date} ${job.end_time}`).isBefore(new Date()));

    const hasUnexpiredJobs = (inRequest?: Request) => (inRequest || request)?.jobs
        .some((job) => dayjs(`${job.date} ${job.end_time}`).isAfter(new Date()));

    const jobIsPassed = (job: FormattedJob) => {
        const startTime = dayjs(`${job.date} ${job.start_time}`);
        const endTime = dayjs(`${job.date} ${job.end_time}`);

        return (endTime.isBefore(startTime) ? endTime.add(1, 'days') : endTime).isBefore(new Date());
    };

    const moveJobsToHistory = async (jobIds: number[] = []) => {
        const ids = jobIds.length ? jobIds : movablePastJobs.map(({ id }) => id);

        if (!ids.length) {
            return;
        }

        try {
            const { data } = await Backend.put(`/staffing/jobs/archive`, { ids });

            if (request && setRequest) {
                setRequest({
                    ...request,
                    jobs: request.jobs.map((job) => {
                        if (ids.includes(job.id)) {
                            job = {
                                ...job,
                                ...data,
                            };
                        }

                        return job;
                    }),
                });
            }

            return { jobIds: ids, data };
        } catch (e) {
            handleError(e);
        }
    };

    return {
        movablePastJobs,
        canDeleteRequest,
        canMoveToHistory,
        canMoveJobToHistory,
        getDateTimes,
        getMovablePastJobs,
        getProviderWorkerQuota,
        getTotalDateTimesWorkers,
        jobIsPassed,
        hasExpiredJobs,
        hasUnexpiredJobs,
        moveJobsToHistory,
    };
};
