import { EditOutlined } from '@ant-design/icons';
import { Button, notification, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { useErrorHandler } from 'lib/ErrorHandling';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { useTable } from 'lib/hooks/useTable';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { useEffect, useState } from 'react';
import { DeletePopconfirm } from 'shared/AntDesignUtils/DeletePopconfirm';
import { Address } from 'types/models/Address';

type Props = {
    addressesURL: string;
    addresses: Address[];
    openAddressHandler: (address: Address) => void;
    departmentFilterOptions?: ColumnFilterItem[];
    loading: boolean;
    loadAddressesData: () => Promise<void>;
};

/**
 * Table to display client's addresses.
 *
 * @param addressesURL
 * @param addresses
 * @param openAddressHandler
 * @param departmentFilterOptions
 * @param loading
 * @param loadAddressesData
 * @constructor
 */
export const AddressTable = ({
    addressesURL,
    addresses,
    openAddressHandler,
    departmentFilterOptions,
    loading,
    loadAddressesData,
}: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const { tableParams, handleTableChange, setTotal } = useTable();

    const [isDataDeleting, setIsDataDeleting] = useState(false);

    /**
     * Render cell for address name.
     *
     * @param address {object}
     * @return {*}
     */
    const renderNameCell = (record: Address) => (
        <Button type="link" onClick={ () => openAddressHandler(record) }>
            { record.name }
        </Button>
    );

    /**
     * Render cell for address' street.
     *
     * @param address {object}
     * @return {*}
     */
    const renderAddressCell = (record: Address) => (
        <Button type="link" onClick={ () => openAddressHandler(record) }>
            { record.address }
        </Button>
    );

    /**
     * Render cell for address' street.
     *
     * @param address {object}
     * @return {*}
     */
    const renderDepartmentsCell = (record: Address) =>
        record.departments?.map((department) => <Tag key={ department.id }>{ department.name }</Tag>);

    /**
     * Render a cell with action buttons.
     *
     * @param address {object}
     * @return {*}
     */
    const renderActionCell = (address: Address) => (
        <Space>
            <EditOutlined onClick={ () => openAddressHandler(address) } />
            <DeletePopconfirm
                title={ t('Are you sure you want to delete this address?') }
                id={ address.id }
                isDataDeleting={ isDataDeleting }
                deleteHandler={ deleteAddressHandler }
            />
        </Space>
    );

    /**
     * Format addresses to add a countryName property.
     *
     * @param addresses { array }
     * @returns {array}
     */
    const formatAddresses = (addresses: Address[]) => {
        const formattedAddresses = addresses.map((address) => {
            return {
                key: address.id,
                ...address,
                countryName: address.country?.name,
            };
        });
        return formattedAddresses;
    };

    /**
     * Filter table by department.
     *
     * @param value
     * @param record
     *
     * @returns {boolean}
     */
    const filterDepartments = (value: number, record: Address) => {
        for (const department of record.departments || []) {
            if (department.id === value) {
                return true;
            }
        }
        return false;
    };

    /**
     * Delete a address by id.
     *
     * @param id {int}
     * @return void
     */
    const deleteAddressHandler = async (id: number) => {
        try {
            setIsDataDeleting(true);
            const response = await Backend.delete(`${ addressesURL }/${ id }`);
            if (response.status === Backend.responseStatus.HTTP_NO_CONTENT) {
                notification.success({
                    message: t('Location successfully removed'),
                });
                loadAddressesData();
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataDeleting(false);
        }
    };

    useEffect(() => {
        setTotal(addresses.length);
    }, [addresses]);

    const columns: ColumnsType<Address> = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => renderNameCell(record),
            sorter: (a, b) => sortColumn(a, b, 'name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('name'),
        },
        {
            title: t('Address'),
            dataIndex: 'address',
            key: 'address',
            render: (name, record) => renderAddressCell(record),
            sorter: (a, b) => sortColumn(a, b, 'address'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('address'),
        },
        {
            title: t('Zip Code'),
            dataIndex: 'zip_code',
            key: 'zip_code',
            sorter: (a, b) => sortColumn(a, b, 'zip_code'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('zip_code'),
        },
        {
            title: t('City'),
            dataIndex: 'city',
            key: 'city',
            sorter: (a, b) => sortColumn(a, b, 'city'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('city'),
        },
        {
            title: t('Country'),
            dataIndex: 'countryName',
            key: 'countryName',
            sorter: (a, b) => sortColumn(a, b, 'countryName'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('countryName'),
        },
        {
            title: t('Departments'),
            dataIndex: 'departments',
            key: 'departments',
            render: (name, record) => renderDepartmentsCell(record),
            filters: departmentFilterOptions,
            filterMultiple: true,
            filterSearch: true,
            onFilter: (value, record) => filterDepartments(value as number, record),
        },
        {
            title: t('Actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value, record) => renderActionCell(record),
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={ columns }
            dataSource={ formatAddresses(addresses) }
            pagination={ tableParams.pagination }
            loading={ loading }
            onChange={ handleTableChange }
            scroll={ { scrollToFirstRowOnChange: true, y: 'calc(100vh - 260px)' } }
            size="small"
        />
    );
};
