import { Col, Divider, Form, Modal, Radio, RadioChangeEvent, Row, Typography } from 'antd';
import { RequestCreationTimes } from 'pages/Client/Requests/Create/RequestCreationTimes';
import { useLocalization } from 'lib/Localization';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { TimesValue } from 'lib/Request/utils';
import { FormRequest } from 'types/staffing';
import { FormInstance } from 'antd/lib/form';

const { Title } = Typography;

type Props = {
    departmentShiftIds?: number[];
    differentTimes: number;
    disabled?: boolean;
    form: FormInstance;
    hideDays?: boolean;
    noDivider?: boolean;
    selectedDates: Date[];
    setDifferentTimes: (time: number) => void;
    saveFormHandler: (changedFormData: FormRequest | null, currentFormData: FormRequest) => void;
    timesOnly?: boolean;
    updateJobList: (dates: Date[], sameOrDifferentTimes?: number, selectingDates?: boolean) => void;
};

export const RequestCreationTimesContainer = ({
    departmentShiftIds,
    differentTimes,
    disabled,
    form,
    hideDays = false,
    noDivider,
    selectedDates,
    setDifferentTimes,
    saveFormHandler,
    timesOnly,
    updateJobList,
}: Props) => {
    const { t } = useLocalization();

    const differentTimesChangeHandler = (event: RadioChangeEvent) => {
        if (disabled) {
            return;
        }

        const { value } = event.target;

        const performChange = () => {
            setDifferentTimes(value);
            saveFormHandler(null, { ...form.getFieldsValue(), differentTimes: value });
            updateJobList(selectedDates, value);
        };

        if (differentTimes === TimesValue.DIFFERENT && +value === TimesValue.SAME) {
            // switching from "different times" to "same time"
            Modal.confirm({
                title: t('Same time for all dates'),
                icon: <ExclamationCircleOutlined />,
                content: (
                    <>
                        <p>
                            {t(
                                'By switching to same time for all dates option ' +
                                    'your time selections on different dates will be reset.'
                            )}
                        </p>
                        <p>
                            <strong>{t('Are you sure you want to switch?')}</strong>
                        </p>
                    </>
                ),
                okText: t('Switch'),
                cancelText: t('Cancel'),
                centered: true,
                onOk: performChange,
            });

            return;
        }

        performChange();
    };

    if (!selectedDates.length) {
        return null;
    }

    return (
        <>
            <div className="different-times-container">
                <Row>
                    <Col xs={6} sm={9} md={9} lg={9} xl={9} xxl={9}>
                        <Title className="subsection-title" level={5}>
                            {t('Shifts needed')}
                        </Title>
                    </Col>
                    <Col xs={18} sm={15} md={15} lg={15} xl={15} xxl={15} className="pull-text-right">
                        <Radio.Group
                            onChange={differentTimesChangeHandler}
                            value={differentTimes}
                            size="large"
                            disabled={disabled || selectedDates.length <= 1}
                            style={selectedDates.length > 1 ? { display: 'block' } : { display: 'none' }}
                        >
                            <Radio disabled={disabled} value={TimesValue.SAME}>
                                {t('Same time for every day')}
                            </Radio>
                            <Radio disabled={disabled} value={TimesValue.DIFFERENT}>
                                {t('Different times each day')}
                            </Radio>
                        </Radio.Group>
                    </Col>
                </Row>
            </div>

            {!noDivider && <Divider />}

            <div id="dates-container">
                <Form.List name="dates">
                    {(fields) => (
                        <>
                            {fields.map((dateField, index) => (
                                <RequestCreationTimes
                                    day={selectedDates[index]}
                                    dateField={dateField}
                                    disabled={disabled}
                                    departmentShiftIds={departmentShiftIds}
                                    key={dateField.key}
                                    form={form}
                                    hideDays={hideDays}
                                    saveFormHandler={saveFormHandler}
                                    timesOnly={timesOnly}
                                />
                            ))}
                        </>
                    )}
                </Form.List>
            </div>
        </>
    );
};
