import { EditOutlined } from '@ant-design/icons';
import { Button, notification, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { useErrorHandler } from 'lib/ErrorHandling';
import { getColumnSearchProps, sortColumn } from 'lib/Helpers/TableHelper';
import { useTable } from 'lib/hooks/useTable';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { useEffect, useState } from 'react';
import { DeletePopconfirm } from 'shared/AntDesignUtils/DeletePopconfirm';
import { Department, WorkShift } from 'types/models';

type Props = {
    departments: Department[];
    openDepartmentHandler: (department: Department) => void;
    isDataLoading: boolean;
    departmentsURL: string;
    loadDepartmentsData: () => Promise<void>;
    shiftFilterOptions?: ColumnFilterItem[];
};

/**
 * Table to display departments of a client.
 *
 * @param departments
 * @param openDepartmentHandler
 * @param isDataLoading
 * @param departmentsURL
 * @param loadDepartmentsData
 * @constructor
 */
export const DepartmentsTable = ({
    departments,
    openDepartmentHandler,
    isDataLoading,
    departmentsURL,
    loadDepartmentsData,
    shiftFilterOptions,
}: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const { tableParams, handleTableChange, setTotal } = useTable();

    const [isDataDeleting, setIsDataDeleting] = useState(false);

    /**
     * Render cell for department name.
     *
     * @param department {object}
     * @return {*}
     */
    const renderNameCell = (department: Department) => (
        <Button type="link" onClick={() => openDepartmentHandler(department)}>
            {department.name}
        </Button>
    );

    /**
     * Delete a department by id.
     *
     * @param id {int}
     * @return void
     */
    const deleteDepartmentHandler = async (id: number) => {
        try {
            setIsDataDeleting(true);
            const response = await Backend.delete(`${departmentsURL}/${id}`);
            if (response.status === Backend.responseStatus.HTTP_NO_CONTENT) {
                notification.success({
                    message: t('Department successfully removed'),
                });
                loadDepartmentsData();
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataDeleting(false);
        }
    };

    /**
     * Render a cell with action buttons.
     *
     * @param department {object}
     * @return {*}
     */
    const renderActionCell = (department: Department) => (
        <Space>
            <EditOutlined onClick={() => openDepartmentHandler(department)} />
            <DeletePopconfirm
                title={t('Are you sure you want to delete this department?')}
                id={department.id}
                isDataDeleting={isDataDeleting}
                deleteHandler={deleteDepartmentHandler}
            />
        </Space>
    );

    const renderShiftsCell = (department: Department) =>
        department.work_shifts?.map(({ id, name }: WorkShift) => <Tag key={id}>{name}</Tag>);

    const filterShifts = (value: number, department: Department) => {
        for (const shift of department.work_shifts || []) {
            if (shift.id === value) {
                return true;
            }
        }

        return false;
    };
    const getFormattedSource = () =>
        departments.map((department: Department) => {
            return {
                key: department.id,
                ...department,
            };
        });

    useEffect(() => {
        setTotal(departments.length);
    }, [departments]);

    const columns: ColumnsType<Department> = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => renderNameCell(record),
            sorter: (a, b) => sortColumn(a, b, 'name'),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend',
            ...getColumnSearchProps('name'),
            width: '25%',
        },
        {
            title: t('Description'),
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => sortColumn(a, b, 'description'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('description'),
            width: '25%',
        },
        {
            title: t('Shifts'),
            dataIndex: 'shifts',
            key: 'shifts',
            render: (shifts, record) => renderShiftsCell(record),
            filters: shiftFilterOptions,
            filterMultiple: true,
            filterSearch: true,
            onFilter: (value, record) => filterShifts(value as number, record),
        },
        {
            title: t('Action'),
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => renderActionCell(record),
            width: '100px',
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={columns}
            dataSource={getFormattedSource()}
            pagination={tableParams.pagination}
            loading={isDataLoading}
            onChange={handleTableChange}
            scroll={{ scrollToFirstRowOnChange: true, y: 'calc(100vh - 260px)' }}
            size="small"
        />
    );
};
