import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';
import { useCallback, useState } from 'react';
import { Worker } from 'types/models';

export const useWorkers = () => {
    const handleError = useErrorHandler();
    const [loadingWorkers, setLoadingWorkers] = useState(false);
    const [workers, setWorkers] = useState<Worker[]>([]);

    const loadWorkers = useCallback(async () => {
        setLoadingWorkers(true);

        try {
            const response = await Backend.get('/data-management/workers?relations=professions,documents');

            setWorkers(response.data.workers);

            return response.data.workers;
        } catch (error) {
            handleError(error);
        } finally {
            setLoadingWorkers(false);
        }

        return [];
    }, [handleError]);

    return {
        loadingWorkers,
        loadWorkers,
        setWorkers,
        workers,
    };
};
