import { Notification } from 'types/models';
import { useLocalization } from 'lib/Localization';
import { OpenNotifIcon } from 'shared/icons';

type Props = {
    images: Record<string, any>;
    notification: Notification;
};

export const NotificationItem = ({ images, notification }: Props) => {
    const { t } = useLocalization();

    return (
        <div className="notification-item">
            <img src={notification.card_icon} alt="" />

            <div className="content">
                {!notification.read_at && (
                    <span className="dot pull-right" style={{ backgroundColor: '#1677ff' }}></span>
                )}

                {notification.header && (
                    <div className="header" dangerouslySetInnerHTML={{ __html: t(notification.header, notification) }}></div>
                )}

                {notification.positions && (
                    <div className="filled-positions">
                        <img src={images.positions} alt="" />
                        <span dangerouslySetInnerHTML={{ __html: t(notification.positions, {
                                    smart_count: notification.filled_jobs_count,
                                    arrow: '&rArr;',
                                    ...notification,
                                }) }}></span>
                    </div>
                )}

                {notification.order_name && (
                    <div className="order">
                        <img src={images.profession} alt="" /> {notification.order_name}
                        <div className="profession">{notification.profession}</div>
                    </div>
                )}

                {!notification.order_name && (
                    <div className="profession">
                        <img src={images.profession} alt="" /> {notification.profession}
                    </div>
                )}

                {((notification.jobs_count || -1) > -1 || notification.start_date) && (
                    <div className="shifts-and-dates">
                        <img src={images.shifts} alt=""/>
                        {!!notification.jobs_count && (
                            <>
                                {t('1 shift |||| %{smart_count} shifts', { smart_count: notification.jobs_count })}
                                &nbsp; &rArr; &nbsp;
                            </>
                        )}
                        {notification.start_date} - {notification.end_date}
                    </div>
                )}

                {typeof notification.from === 'object' && typeof notification.to === 'object' && (
                    <div className="status-change">
                        <span className="dot" style={{ backgroundColor: notification.from.color }}></span>
                        <span className="text">{notification.from.label}</span>
                        <span className="arrow">&rArr;</span>
                        <span className="dot" style={{ backgroundColor: notification.to.color }}></span>
                        <span className="text">{notification.to.label}</span>
                    </div>
                )}

                {notification.event === 'sent_invite' && notification.message && (
                    <div className="message ellipsis-3-lines">{notification.message}</div>
                )}

                {['sent_invite', 'accepted_invite'].includes(notification.event as string) && (
                    <>
                        <div className="company">
                            <img src={images.positions} alt="" />
                            {notification.company_name}
                        </div>

                        <div className="category">
                            <img src={images.categories} alt="" />
                            {notification.company_categories?.join(', ')}
                        </div>
                    </>
                )}

                <div className="date">
                    {notification.date}

                    <OpenNotifIcon className="pull-right" />
                </div>
            </div>
        </div>
    );
};
