import './styles.scss';
import { Button } from 'antd';
import { useAccount } from 'lib/Account';
import { useLocalization } from 'lib/Localization';
import { OrderFormRequest } from 'pages/Client/Requests/Create/RequestCreation';
import { RequestCreationInfoSummary } from 'pages/Client/Requests/Create/RequestCreationInfoSummary';
import { useEffect, useState } from 'react';
import { CustomModal } from 'shared/AntDesignUtils/CustomModal/CustomModal';
import { GenericObject } from 'shared/Contracts';
import { formatJobs } from 'shared/Requests/helpers';
import { Job, Provider, Request } from 'types/models';
import { FormattedJob } from 'types/staffing';

type Props = GenericObject & {
    handleUpdateJobs: (jobs: FormattedJob[]) => Promise<Request | false>;
    open: boolean;
    providers: Provider[];
    request: Request;
    setOpenModal: (open: boolean) => void;
    setRequest: (request: Request) => void;
};

export const QuotaModal = ({
        handleUpdateJobs,
        open,
        providers,
        request,
        setOpenModal,
        setRequest,
        ...otherProps
    }: Props) => {
    const { t } = useLocalization();
    const { accountUser: user } = useAccount();

    const [initialJobs, setInitialJobs] = useState<Job[]>([]);
    const [newRequestProviders, setNewRequestProviders] = useState<number[]>([]);

    const onCancelHandler = () => {
        setOpenModal(false);
        setRequest({
            ...request,
            jobs: initialJobs,
        });
    };

    useEffect(() => {
        if (initialJobs.length) {
            return;
        }

        setInitialJobs(request.jobs);
    }, []);

    const footerButtons = [
        <Button key="close" onClick={onCancelHandler}>
            {t('Cancel')}
        </Button>,
        <Button
            key="save"
            type="primary"
            onClick={async () => {
                setOpenModal(false);

                const updatedRequest = await handleUpdateJobs(formatJobs(request.jobs, true));

                if (updatedRequest) {
                    setRequest({
                        ...updatedRequest,
                        providers: newRequestProviders,
                    });
                }
            }}
        >
            {t('Save changes')}
        </Button>
    ];

    return (
        <CustomModal
            className="quota-modal"
            footer={footerButtons}
            onCancel={onCancelHandler}
            open={open}
            title={t('Provider Quota')}
            width={700}
            {...otherProps}
        >
            <RequestCreationInfoSummary
                formRequests={[{
                    ...request,
                    providers: request.providers ?? request.provider_requests.map(({ provider }) => provider.id),
                } as OrderFormRequest]}
                providers={providers}
                setFormRequest={(request) => setRequest(request as unknown as Request)}
                setNewRequestProviders={setNewRequestProviders}
                newQuotaOnly
                showProviderQuota
                simple
            />
        </CustomModal>
    );
};
